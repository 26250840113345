import React,{useState,useEffect} from 'react'
import SearchIcon from '@material-ui/icons/Search'
import SearchMenu from './SearchMenu'
import {Searchcontainer} from './SearchBar.elements'
import { useStateValue } from '../StateProvider';
function SearchBar(props) {
    const [anchorEl, setAnchorEl] = useState(false);
    const [oem,setOem]=useState(false)
    const [{tag}]=useStateValue()
    
    useEffect(()=>{
        const proof=["OEM","Add"]
        setOem(proof.includes(tag))
    },[tag])
    
      
    

    const handleClick = (event) => {
          setAnchorEl(!anchorEl)
        }    
    
    return (
       
                <Searchcontainer oem={oem} {...props}>
                    <div onClick={handleClick} className="searchBar__left">
                        <span className="searchBar__tag">{tag}</span>
                        <span className="searchBar__arrow">⇩</span>
                    </div>
                {anchorEl&&
                <SearchMenu 
                setAnchorEl={setAnchorEl}
                {...props}/>}
                <input type="text" className="searchBar__input"/>
                <SearchIcon className="searchBar__right"/>                   
           </Searchcontainer>
       
    )
}

export default SearchBar