 import styled,{css} from 'styled-components'

export const CardContainer=styled.div`

${props=>props.device.mobile&&css`
background: white;
    display:grid;
    grid-template-areas:"img txt";
    grid-template-columns:1fr 2fr;
    border-bottom: var(--primary) 10px solid;
    border-radius: 6px;
    overflow:hidden;
    z-index:2;
    :hover{
        img{
            opacity:1;
        }
    
    }
    .img{
        grid-area:img;
    }
    .txt{
        grid-area:txt;
    }
    
    img{
        border:0;
        margin:0;
        grid-area:img;
        height:120px;
        object-fit:cover;
        opacity:0.8;
        }
     }
    h3{       
        margin:15% auto;
        border-radius:6px;
        background-color:var(--primary);
        color:white;
        text-align:center;
        font-weight:900;
        font-size:14px;
        line-height:18px;
        padding:6px;
        width:80%;
        }


`}
${props=>props.device.tablet&&css`
background: white;
    display:grid;
    grid-template-areas:"img txt";
    grid-template-columns:1fr 2fr;
    border-bottom: var(--primary) 10px solid;
    border-radius: 10px;
    overflow:hidden;
    z-index:2;
    width:250px;
    :hover{
        img{
            opacity:1;
        }
    
    }
    .img{
        grid-area:img;
    }
    .txt{
        grid-area:txt;
    }
    
    img{
        border:0;
        margin:0;
        grid-area:img;
        height:120px;
        object-fit:cover;
        opacity:0.8;
        }
     }
    h3{       
        margin:15% auto;
        border-radius:6px;
        background-color:var(--primary);
        color:white;
        text-align:center;
        font-weight:900;
        font-size:14px;
        line-height:18px;
        padding:6px;
        width:60%;
        }

`}
${props=>props.device.desktop&&css`
    background: white;
    display:grid;
    grid-template-areas:"img txt";
    grid-template-columns:1fr 2fr;
    border-bottom: var(--primary) 10px solid;
    border-radius: 15px;
    overflow:hidden;
    z-index:2;
    :hover{
        img{
            opacity:1;
        }
    
    }
    .img{
        grid-area:img;
    }
    .txt{
        grid-area:txt;
    }
    
    img{
        border:0;
        margin:0;
        grid-area:img;
        height:140px;
        object-fit:cover;
        opacity:0.8;
        }
     }
    h3{       
        margin:15% auto;
        border-radius:6px;
        background-color:var(--primary);
        color:white;
        text-align:center;
        font-weight:900;
        font-size:18px;
        line-height:20px;
        padding:8px;
        width:80%;
        }

`}
${props=>props.device.laptop&&css`
    background: white;
    display:grid;
    grid-template-areas:"img txt";
    grid-template-columns:1fr 2fr;
    border-bottom: var(--primary) 10px solid;
    border-radius: 15px;
    overflow:hidden;
    z-index:2;
    :hover{
        img{
            opacity:1;
        }
    
    }
    .img{
        grid-area:img;
    }
    .txt{
        grid-area:txt;
    }
    
    img{
        border:0;
        margin:0;
        grid-area:img;
        height:150px;
        object-fit:cover;
        opacity:0.8;
        }
     }
    h3{       
        margin:15% auto;
        border-radius:6px;
        background-color:var(--primary);
        color:white;
        text-align:center;
        font-weight:900;
        font-size:20px;
        line-height:24px;
        padding:10px;
        width:80%;
        }

`}


`