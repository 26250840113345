import React,{useEffect,useState} from 'react'
import Product_Card from './Product_Card'
import {useAdminStateValue} from '../Admin'
import FirebaseUpdate from './FirebaseUpdate'
import {useStyles} from './UpdateStyles'

function Upload(props) {
const [{oldPDS,oldSDS},dispatch]=useAdminStateValue()
const [uploadDb,setUploadDb]=useState(false)
const classes=useStyles()
const {update}=props

useEffect(()=>{
    if (uploadDb){
    if (oldPDS.startsWith('https://firebasestorage')){
    
        if (!(typeof(update.pds)==="string")) {
    dispatch({type:'UPDATE_COLLECT_DELETE_PDS_INDB',
    deleteFile:oldPDS})
        }
    }
    if (oldSDS.startsWith('https://firebasestorage')){
        
        if (!(typeof(update.sds)==="string")) {
    dispatch({type:'UPDATE_COLLECT_DELETE_SDS_INDB',
    deleteFile:oldSDS})
        }
    }}
},[uploadDb])

useEffect(()=>{
    dispatch({type:'UPDATE_SHOW_DASH',
switchOff:false})
},[])
const handleClick=()=>{
    
dispatch({type:'UPDATE_SHOW_DASH',
switchOff:true})
props.setUploading(!props.uploading)
}
const handleUpload=()=>{
setUploadDb(true)
}

    return (
       <>
       {!uploadDb?<div className={classes.root} style={{position:"relative"}}>
           <Product_Card uploading={props.uploading}
            images={update.images}
            product_name={update.product_name}
            description={update.description}
            packing={update.packing}
            code={update.code}
            pds={update.pds}
            sds={update.sds}/>
           <button
           name="back"
           style={{position:"absolute",bottom:"20px",left:"20px",width:"60px"}}
            onClick={handleClick}>
                <span role="button" aria-label="back-button">🔙</span>
            </button>
           <button
           name="upload"
           style={{position:"absolute",top:"160px",left:"20px",width:"70px",height:"87px",fontSize:"20px"}}
            onClick={handleUpload}>
                <span role="button" aria-label="upload-button">☁ confirm upload</span>
            </button>
       </div>:
       <FirebaseUpdate update={props.update}/>}
        </>
        
    )
}

export default Upload
