import React, {createContext,useContext,useReducer} from 'react'


export const AdminStateContext=createContext();


export const AdminStateProvider = ({reducer,initialState,children}) => (
    <AdminStateContext.Provider value={useReducer(reducer,initialState)}>
        {children}
    </AdminStateContext.Provider>
)

export const useAdminStateValue = ()=> useContext(AdminStateContext)