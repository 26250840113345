import React from 'react'
import { useAdminStateValue } from '../Admin'
import Checkbox from '@material-ui/core/Checkbox'
import {ThumbContainer} from './ViewThumb.elements'
import DeleteIcon from '@material-ui/icons/Delete';
const ViewThumb = (props) => {
    const    [{update:{pds,sds,images}},dispatch]=useAdminStateValue()
    
    const fileSource={pds:props.source==="pds",sds:props.source==="sds",images:props.source==="images"}
    const files=fileSource.images?images:fileSource.pds?pds:sds
    
    const handleDelete=(e)=>{ 
            if (fileSource.images) {
                
                const deleteFile=e.target.name
                
                
                const selectedFiles=files.filter(file=>{
                    if (typeof(file)==="string"&&file.startsWith('https://firebasestorage')) {
                     
                    return (!(file===deleteFile))
                    } else 
                    {return(!(file.name===deleteFile)) }
                    
                }
                    )

                dispatch({
                    type:'UPDATE_DELETE_IMAGES_FILES',
                    selectedFiles
                })
                console.log("file is in DB?:",deleteFile.startsWith('https://firebasestorage'))
                if (deleteFile.startsWith('https://firebasestorage')) {dispatch({type:'UPDATE_COLLECT_DELETE_IMAGES_INDB',deleteFile})}
                props.setUpdate({...props.update,["images"]:selectedFiles})
                if (selectedFiles.length===0) {
                    props.setUpdateFile({...props.gotUpdateFile,["images"]:false})
                    props.setUpdate({...props.update,["images"]:"na"})
                    
                }

            } else {
            if (fileSource.sds) {
                dispatch({
                    type:'UPDATE_DELETE_SDS_FILE',
                    
                })
                props.setUpdate({...props.update,["sds"]:"na"})
                props.setUpdateFile({...props.gotUpdateFile,["sds"]:false})
            } else {
            if (fileSource.pds) {
                dispatch({
                    type:'UPDATE_DELETE_PDS_FILE',
                    
                }) 
                props.setUpdateFile({...props.gotUpdateFile,["pds"]:false})
                props.setUpdate({...props.update,["pds"]:"na"})
                }
            }    
            }
           
        }
        let thumbJSX={}
        
        const handleClick=(e,imageIndex)=>{
            if (!props.clickable) return
            props.setSelect(imageIndex)
        }
        
        if (fileSource.images) {
        
        thumbJSX=files.map((file,index)=>{
        const isSelected=props.clickable&&(props.selected===index)
        let url=""
        let urlRef=""
        let inDb=false
        if (typeof(file)==='string'&&file.startsWith('https://firebasestorage')) { 
            url=file
            urlRef=new URL(url)
            inDb=true
        } else {
           url=URL.createObjectURL(file)  
        }
        
        
        return (
            
        <li key={"update_li"+index} onClick={(e)=>handleClick(e,index)}>
            
            <ThumbContainer clickable={props.clickable} isSelected={isSelected}>
            <img key={inDb?urlRef.hostname:file.name+index} src ={url} alt={inDb?urlRef.href:file.name} 
            />
            
           {!props.uploading&&<div className="checkdiv">
                <Checkbox
                color="secondary"
                icon={<DeleteIcon/>}
                key={"check"+index}
                onChange={handleDelete}
                name={inDb?file:file.name}
                checked={false}
                
          />
          {inDb?urlRef.href:file.name}
            </div>}
            
          
            </ThumbContainer>
            </li>
            )
        } )} else {
            let urlPDSSDS=""
            let urlRef=""
            let inDb=false
        if (typeof(files)==='string'&&files.startsWith('https://firebasestorage')) { 
            urlPDSSDS=files
            urlRef=new URL(urlPDSSDS)
            inDb=true
        } else { urlPDSSDS=URL.createObjectURL(files)}
           
            thumbJSX=
           <li>
            <ThumbContainer>
            <iframe key={inDb?urlRef.hostname:files.name+100} src ={urlPDSSDS} 
            style={{width:"120px"}}
            
            />
            {!props.uploading&&<div className="checkdiv">
                <Checkbox
                color="secondary"
                icon={<DeleteIcon/>}
                key={inDb?urlRef.hostname+10:files.name+10}
                onChange={handleDelete}
                name={files.name}
                checked={false}
                
                
          />
          {inDb?urlRef.href:files.name}
            </div>}
                
            </ThumbContainer>
            </li>
            }
        
        


    return (
        <ul>
            {thumbJSX}
        </ul>
    )
}

export default ViewThumb
