import React from 'react'
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormGroup from '@material-ui/core/FormGroup'
import Checkbox from '@material-ui/core/Checkbox';
import { useAdminStateValue } from '../Admin'

const CheckPackage = (props) => {
  const [,dispatch]=useAdminStateValue()  
  
  

  const handleChange = (event) => {
    const newPacking={...props.update.packing,[event.target.name]: event.target.checked}
    props.setUpdate({ ...props.update, packing: newPacking });
    dispatch({type:'UPDATE_PACKING',packing:newPacking})
    
  }
  const {flexi,tote,drum,pail,jug5qt,jug1gal,qtbottle,keg,cartridge}=props.update.packing
  

    return (
        <>
            <FormGroup row>
            <FormControlLabel 
        control={<Checkbox checked={flexi} onChange={handleChange} name="flexi" color="primary" />}
        label="Flexi" 
      />
            <FormControlLabel 
        control={<Checkbox color="primary" checked={tote} onChange={handleChange} name="tote" />}
        label="Tote"
      />
            <FormControlLabel 
        control={<Checkbox color="primary" checked={drum} onChange={handleChange} name="drum" />}
        label="Drum"
      />
            <FormControlLabel 
        control={<Checkbox color="primary" checked={keg} onChange={handleChange} name="keg" />}
        label="Keg"
      />
            <FormControlLabel 
        control={<Checkbox color="primary" checked={pail} onChange={handleChange} name="pail" />}
        label="Pail"
      />
            <FormControlLabel 
        control={<Checkbox color="primary" checked={jug5qt} onChange={handleChange} name="jug5qt" />}
        label="Jug 5qt"
      />
            <FormControlLabel 
        control={<Checkbox color="primary" checked={jug1gal} onChange={handleChange} name="jug1gal" />}
        label="Jug 1Gal"
      />
            <FormControlLabel 
        control={<Checkbox color="primary" checked={qtbottle} onChange={handleChange} name="qtbottle" />}
        label="Bottle 1 qt"
      />
            <FormControlLabel 
        control={<Checkbox color="primary" checked={cartridge} onChange={handleChange} name="cartridge" />}
        label="Cartridge"
      />
            </FormGroup>
        </>
    )
}

export default CheckPackage
