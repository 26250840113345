import styled,{css} from 'styled-components'

export const NavContainer = styled.nav`
${props=>props.device.mobile&&css`
display:flex;
flex-direction:row;
justify-content:space-between;
align-items:baseline;

background-color:white;
border-bottom:1px solid;
border-bottom-color: rgba(80, 50, 20,.2);
flex-wrap:nowrap;
height:62px;
width:100%;
.navbar__logo{
    margin-left:5%;
    align-self:center;
    }
.navbar__menuicon{  
    margin-left:33%;
   
}
.navbar__langicon{
    margin-right:3%;
}
`}
${props=>props.device.tablet&&css`
display:flex;
justify-content:space-between;
align-items:center;
background-color:white;
border-bottom:1px solid;
border-bottom-color: rgba(0, 0, 0,.2);
flex-wrap:nowrap;
height:80px;
width:100%;
.navbar__logo{
    margin-left:6%;
    
    
}
.navbar__menuicon{
    align-self:flex-end;
    margin-left:33%;
    
}
.navbar__langicon{
        margin-right:3%;
    }
`}
${props=>props.device.desktop&&css`
display:flex;
justify-content:space-between;
align-items:center;
background-color:white;
border-bottom:1px solid;
border-bottom-color: rgba(0, 0, 0,.2);
flex-wrap:nowrap;
height:100px;
width:100%;
.navbar__logo{
    margin-left:5%;
}
.navbar__desktop_laptop{
   
    
    flex:1;
}
.navbar__langicon{
    margin-right:5%;
}
`}

${props=>props.device.laptop&&css`
display:flex;
justify-content:space-between;
align-items:center;
background-color:white;
border-bottom:1px solid;
border-bottom-color: rgba(0, 0, 0,.2);
flex-wrap:nowrap;
height:100px;
width:100%;
.navbar__logo{
    
    margin-left:5%;
}
.navbar__desktop_laptop{
    max-width:53vw;
    
   flex:1;
}
.navbar__langicon{
    margin-right:5%;
}
`}

`