import React,{useState,useEffect} from 'react'
import AddCND from './AddCND'
import GetCategory from './GetCategory'
import {initialRecord} from '../Admin'
import AddPackage from './AddPackage'
import AddFiles from './AddFiles'
import AddingProduct from './AddingProduct'
import Button from '@material-ui/core/Button'
import CloudUploadIcon from '@material-ui/icons/CloudUpload'
import { createStyles, makeStyles} from '@material-ui/core/styles'

import FirebaseAdd from './FirebaseAdd'
const useStyles = makeStyles((theme) =>
  createStyles({
    button: {
      margin: theme.spacing(4),
      
      
    },
    root:{
        marginTop:'3%',
        display:'flex',
        alignItems:'center',
        justifyContent:'space-between',
    }
  }),
);


const AddProduct = ({tag}) => {
    const classes = useStyles()
    const [addProduct,setAddProduct]=useState(false)
    const [conade,setConade]=useState({code:"",family:"na",product_name:"",description:"na"})
    const [productFiles,setProductFiles]=useState({pds:"",sds:"",images:[]})
    const [selected,setSelected]=useState(0)
    const [category,setGetCategory]=useState(null)
    const [packing,setPacking]=useState(initialRecord.packing)
    const [upload,setUpload]=useState(false)
    const validTypes=['pdf','PDF']
    const validImageTypes=['jpg','png','jpeg','webp','jfif','JPG','PNG','JPEG'] 
    
    const handleAddView=()=>{
        setAddProduct(true)
    }
    const  handleCategory=(event,newValue)=>{
        setGetCategory(newValue)
    }
    
    const onChange=(event)=>{
        setConade({...conade,[event.target.name]:event.target.value})
    }
    useEffect(()=>{
        setConade({code:"",family:"na",product_name:"",description:"na"})
        setProductFiles({pds:"",sds:"",images:[]})
        setPacking(initialRecord.packing)
    },[category])
    
    const handleClick=()=>{
      setUpload(true)
    }
    return (
       <div style={{marginBottom:"8%"}}>
       <GetCategory tag={tag} value={category} handleChange={handleCategory} handleAddView={handleAddView}/>
       {!upload?category&&<form noValidate autoComplete="off">
        <AddCND handleChange={onChange} conade={conade} />
        <AddFiles productFiles={productFiles} setProductFiles={setProductFiles} validTypes={validTypes} validImageTypes={validImageTypes} selected={selected} setSelected={setSelected}/>
        <AddPackage packing={packing} setPacking={setPacking} />
           </form>:
        <FirebaseAdd collection={category.collection} conade={conade} productFiles={productFiles} packing={packing}/>}
       {addProduct&&<div className={classes.root}>
           <div><Button
        variant="contained"
        color="primary"
        className={classes.button}
        startIcon={<CloudUploadIcon />}
        onClick={handleClick}
      >
        Upload
      </Button></div>
           <AddingProduct productFiles={productFiles} code={conade.code} product_name={conade.product_name} description={conade.description} packing={packing}/></div>}
       </div>

           
        

            
        
    )
}

export default AddProduct
