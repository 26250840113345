import React,{useState} from 'react'
import {useStateValue} from '../StateProvider'
import UseDash from './UseDash'
import DataDisplay from './DataDisplay';
import Update from './Update';
import {useAdminStateValue} from '../Admin'
import DeleteRecord from './DeleteRecord'
import { PageContainer} from './Dashboard.elements'
const Dashboard = (props) => {
const [{updateDash},]=useAdminStateValue()    
   
const [{store:{products}},]=useStateValue()


const productsE=Object.entries(products)
const [selected,setSelected]=useState(productsE[0])






    return (
        <PageContainer>
        <UseDash productEntries={productsE}
        selected={selected}
        setSelected={setSelected}
        lockSelection={updateDash}
        {...props}
        />
        {props.view&&<DataDisplay collection={selected}/>}
        {props.update&&<Update collection={selected} />}
        {props.delete&&<DeleteRecord collection={selected}/>}
        </PageContainer>
    )
}

export default Dashboard
