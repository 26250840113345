import styled from 'styled-components'
export const MenuButton=styled.button`
background-color:var(--primary);
border:solid 3px rgba(0,0,0,0.5);
border-radius:8px;

:hover{
    background-color:var(--secondary);
    color:white;
}
`
export const Container=styled.div`

.admin_mainbuttons{
    margin-top:1%;
    display:flex;
    justify-content:space-around;
    
   
}
`