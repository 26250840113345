import React from 'react'
import { ReactComponent as Selectlangeng } from '../images/svg/en_selector.svg';
import { ReactComponent as Selectlangesp } from '../images/svg/es_selector.svg';
import {Container,CustomsizeIcon} from './Langswitch.elements'
import {useStateValue} from '../StateProvider'



function Langswitch(props) {

    const [{lang},dispatch]=useStateValue()
    const setLang=()=>{
        dispatch({type:'SET_LANG'})        
    }

    return (
        
        <Container {...props} onClick={setLang} className="langSwitch">
        
            <CustomsizeIcon {...props}  viewBox="0 0 60 16" className="langSwitch__icon">
                {lang==="eng" && (<Selectlangeng  />)}
                {lang==="esp" && (<Selectlangesp />)}
            </CustomsizeIcon>
        
        </Container>
        
    )
}
export default Langswitch