
import React,{useState,useEffect} from 'react'
import {auth} from '../firebase'

import {LoginContainer} from '../components/Login.elements'
import {useDevice} from '../hooks'
import LogoTxwos  from '../components/LogoTxwos';
import {Link,useHistory} from 'react-router-dom'
import Footer from '../components/Footer'
import {useStateValue} from '../StateProvider'
import FirebaseUi from '../components/FirebaseUi'
import EmailAuthProvider from '../components/EmailAuthProvider'
import {GoogleAuth,FacebookAuth} from '../components/AuthProvider'



function Login(props) {
const device=useDevice()
const history=useHistory()
const [selectProvider,setSelectProvider]=useState(false)
const [passwordAuth,setPasswordAuth]=useState(false)
const [googleAuth,setGoogle]=useState(false)
const [facebookAuth,setFacebook]=useState(false)
const [providerId,setProviderId]=useState("")
const [signed,setSigned]=useState(false)
const [loading,setLoading]=useState(null)
const [{user},]=useStateValue()


    useEffect(() => {
        const unregisterAuthObserver = auth.onAuthStateChanged(user => {
          setSigned(!!user);
          user&&setProviderId(user.providerData[0].providerId)
          
          })
        return () => unregisterAuthObserver(); 
      }, [user]);
      
      useEffect(()=>{
        let isSubscribed=true
        if (isSubscribed&&googleAuth) {
            GoogleAuth(device)
            
          }
        return ()=>isSubscribed=false
      },[googleAuth])
      
      useEffect(()=>{
        let isSubscribed=true
        if (isSubscribed&&facebookAuth) {
            FacebookAuth(device)
            
          }
        return ()=>isSubscribed=false
      },[facebookAuth])
     
      useEffect(()=>{
        if (providerId) {
        !device.mobile&&providerId.includes('google')&&history.goBack()
        device.mobile&&providerId.includes('google')&&window.history.go(-3)
        providerId.includes('password')&&history.goBack()
        providerId.includes('facebook')&&history.goBack()
        
        }
      },[providerId])
      
      
    return (
       <LoginContainer device={device}>
           <Link to='/' className="login__link">
                <LogoTxwos device={device} className="login__logo"/>
           </Link>
       {!selectProvider&&!signed&&!loading&&<FirebaseUi 
       device={device} 
       setSelectProvider={setSelectProvider}
       selectProvider={selectProvider}
       setPasswordAuth={setPasswordAuth}
       setGoogle={setGoogle}
       setFacebook={setFacebook}
       
       />}
       {passwordAuth&&<EmailAuthProvider setLoading={setLoading} setProviderId={setProviderId} device={device}/>}
       {loading&&<div>Loading...</div>}
       
     
      
       <Footer {...props} device={device} login={true} className="login__footer"/>
       </LoginContainer>
    )
}

export default Login
