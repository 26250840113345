import React,{useEffect,useState} from 'react'
import {useStateValue} from '../StateProvider'
import {myFunctions} from '../firebase'

const SetDatabase = () => {
const [document,setDocument]=useState(null)
const [{user},]=useStateValue()
const createDB= myFunctions.httpsCallable('createDB')
useEffect(()=>{
    let isSubscribed=true
    user&&createDB().then(result=>{
      if (isSubscribed) {
        if (result.data){
          setDocument(result.data)
          console.log("Create DB called:",result.data)
        }
      }
    }).catch((error) => {
      // Getting the Error details.
      console.log(error.code," ",error.message)
      // ...
    }) 
    
    return ()=>isSubscribed=false
    },[]) 
 


    return (
        <div>
            Done {document}
        </div>
    )
}

export default SetDatabase
