import React,{useState} from 'react';
import Socials from './socials'
import {FooterContainer} from './Footer.elements'
import ShoppingBasketIcon from '@material-ui/icons/ShoppingBasket'
import AccountBoxIcon from '@material-ui/icons/AccountBox';
import {useStateValue} from '../StateProvider'
import {Link} from "react-router-dom"
import Menu from './Menu'
import {mainMenu} from '../utilities'
import MyProfile from './MyProfile';
import MyAccount from './MyAccount';

  


function Footer(props) {
    const [{user,basket}]=useStateValue()
    
      const [anchorEl, setAnchorEl] = useState(false);
      const [showMyProfile,setShowMyProfile]=useState(false)
      const [showMyAccount,setShowMyAccount]=useState(false)
      const handleClick = (event) => {
        setAnchorEl(!anchorEl);
      };

    
    return(
      
<FooterContainer {...props}>
    
{!props.login&&!props.device.tablet&&!props.device.desktop&&!props.device.laptop&&

<div className="footer__1stline">
    
        <Socials {...props} className="footer__socials" />
    
     
            <div  className="footer__user">
      {user?<>        
                <div className="footer__account">
                  <AccountBoxIcon onClick={handleClick} className="footer__accounticon"/>
                 </div>
                 {anchorEl&&<Menu 
                  menu={mainMenu}
                  user={user}
                  setAnchorEl={setAnchorEl}
                  setShowMyProfile={setShowMyProfile}
                  setShowMyAccount={setShowMyAccount}
                   {...props} />}
                  {showMyProfile&&<MyProfile {...props}
                  user={user}
                  setAnchorEl={setAnchorEl}
                  setShowMyProfile={setShowMyProfile}
                  />}
                  {showMyAccount&&<MyAccount {...props}
                  user={user}
                  setAnchorEl={setAnchorEl}
                  setShowMyAccount={setShowMyAccount}
                  />} 
            
            </>
                
          :       
              
                <Link to='/login'>
                <span className="footer__accounttext">Log in</span>
                </Link>
}
</div>
    <Link to='/selection' className="footer__selection">
               <div className="footer__basket">
                 <ShoppingBasketIcon className="footer__basketicon"/>
               </div>
            <div className="footer__selectioncount">{basket?.length}</div>
    </Link>
</div>       
}
<span>
    TEXAS WORLDWIDE OIL SERVICES LLC &copy; Copyright 2020
</span>


</FooterContainer>

    )
}
export default Footer