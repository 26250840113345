import React,{useState,useEffect} from 'react'
import Socials from './socials'
import SearchBar from './SearchBar' 
import NavContainer from './Header.elements'
import ShoppingBasketIcon from '@material-ui/icons/ShoppingBasket'
import AccountBoxIcon from '@material-ui/icons/AccountBox';
import {useStateValue} from '../StateProvider'
import MyProfile from './MyProfile'
import MyAccount from './MyAccount'
import Menu from './Menu'
import {Link,useRouteMatch} from "react-router-dom"
import {mainMenu} from '../utilities'

function Header(props) {
  
  
  
  const[{user,basket}]=useStateValue()
  const [anchorEl, setAnchorEl] = useState(false);
  const [showMyProfile,setShowMyProfile]=useState(false)
  const [showMyAccount,setShowMyAccount]=useState(false)
  const routeMatch=useRouteMatch()
  
  
  
  const handleClick = () => {
        setAnchorEl(!anchorEl)
      }
     
      
   
   

    return (
       <NavContainer {...props} className="headernav_container">
            {!props.device.mobile&&
            <div className="header__socials">
               <Socials {...props} />
            </div>}
            <div className="header__searchbar">
              <SearchBar {...props} />
            </div>
            {!props.device.mobile&&
              <div className="header__leftside">
                          
              <div className="header__user">
                  {user?
                  <div className="header__link">              
                <div className="header_accounticon">
                    <AccountBoxIcon 
                    onClick={handleClick}/>
                </div>
                  {anchorEl&&<Menu 
                  menu={mainMenu}
                  user={user}
                  setAnchorEl={setAnchorEl}
                  setShowMyProfile={setShowMyProfile}
                  setShowMyAccount={setShowMyAccount}
                   {...props} />}
                  {showMyProfile&&<MyProfile {...props}
                  user={user}
                  setAnchorEl={setAnchorEl}
                  setShowMyProfile={setShowMyProfile}
                  />}
                  {showMyAccount&&<MyAccount {...props}
                  user={user}
                  setAnchorEl={setAnchorEl}
                  setShowMyAccount={setShowMyAccount}
                  />}
            </div>
              :
            <Link to='/login' >
            <span>Log in</span>
            </Link>}
            </div>
            
            
             <Link to='/selection' className="header__selection">
               <span className="header__selectiontext">Product <br/> Selection</span>
               <div className="header__basketicon">
                 <ShoppingBasketIcon/>
               </div>
            <span className="header__selectioncount">{basket?.length}</span>
             </Link>

                </div>}            
                
       </NavContainer>
    )
}
export default Header