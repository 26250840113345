import styled from 'styled-components'

export const Container=styled.div`
margin-top:-8%;
display:flex;
flex-direction:column;
align-items:center;
background-color:white;
z-index:-1;
min-width:300px;

h1{
text-align:center;
font-size:${p=>p.device.mobile?"18px":
p.device.tablet?"22px":
p.device.desktop?"24px":"28px"};
font-weight:700;
}
p{
    width:${p=>p.device.mobile?"365px":
p.device.tablet?"800px":
p.device.desktop?"900px":"1200px"};    
margin:5% 15%;
text-align:justify;
font-size:${p=>p.device.mobile?"12px":
p.device.tablet?"18px":
p.device.desktop?"20px":"22px"};
font-weight:500;
}

`