import React from 'react'

import {TbuttonContainer} from './Tbutton.elements'


const ThreedButton=(props)=>{
    const tag=props.tag
    return(
<TbuttonContainer {...props}>
<svg   viewBox="0 0 275 123">
  <defs>
    <clipPath id="clipPath">
      <path id="Path_107" data-name="Path 107" d="M392.26,445.43l-3.53-2.66a44.049,44.049,0,0,1,42.11-43.04l1.22-.03,3.53,2.66c-.41,0-.82.01-1.22.03C411.47,403.2,392.7,422.23,392.26,445.43Z"/>
    </clipPath>
  </defs>
  <g id="Group_50" data-name="Group 50" transform="translate(-388.11 -398.49)">
    <g id="Group_39" data-name="Group 39">
      <g id="Group_52" data-name="Group 52">
        <path id="Path_88" data-name="Path 88" d="M408.25,513.06l-3.53-2.66a41.73,41.73,0,0,1-16.6-34.36l3.53,2.66a41.69,41.69,0,0,0,11.89,30.22,40.929,40.929,0,0,0,4.71,4.14" fill="#565326"/>
      </g>
    </g>
    <g id="Group_41" data-name="Group 41">
      <path id="Path_91" data-name="Path 91" d="M435.59,402.37l-3.53-2.66,227.11-1.22,3.54,2.67Z" fill="#68642e"/>
    </g>
    <g id="Group_43" data-name="Group 43">
      <path id="Path_93" data-name="Path 93" d="M391.64,478.7l-3.53-2.66.62-33.27,3.53,2.66Z" fill="#535025"/>
    </g>
    <g id="Group_46" data-name="Group 46" transform="translate(1.744 2.521)">
      <path id="Path_100" data-name="Path 100" d="M433.624,403.337l1.219-.029,222.295-1.159-1.376,71.925c-.423,21.7-18.413,39.511-40.385,40.258l-1.219.029L432.7,515.31a40,40,0,0,1-28.657-11.587,38.453,38.453,0,0,1-11.423-28.259l.619-31.869C393.652,421.9,411.662,404.084,433.624,403.337Z" transform="translate(0 0)" fill="#d59d00"/>
    </g>
    <g id="Group_48" data-name="Group 48">
      <g id="Group_47" data-name="Group 47" clipPath="url(#clipPath)">
        <path id="Path_101" data-name="Path 101" d="M392.26,445.43l-3.53-2.66a44,44,0,0,1,16.31-33.27l3.53,2.66a44.029,44.029,0,0,0-16.31,33.27" fill="#565326"/>
        <path id="Path_102" data-name="Path 102" d="M408.57,412.16l-3.53-2.66a44.724,44.724,0,0,1,6.71-4.51l3.53,2.66a43.978,43.978,0,0,0-6.71,4.51" fill="#5a5628"/>
        <path id="Path_103" data-name="Path 103" d="M415.28,407.65l-3.53-2.66a43.032,43.032,0,0,1,6.23-2.79l3.53,2.66a42.918,42.918,0,0,0-6.23,2.79" fill="#5d5a29"/>
        <path id="Path_104" data-name="Path 104" d="M421.52,404.85l-3.53-2.66a43.153,43.153,0,0,1,6.4-1.74l3.53,2.66a43.714,43.714,0,0,0-6.4,1.74" fill="#605d2b"/>
        <path id="Path_105" data-name="Path 105" d="M427.91,403.12l-3.53-2.66a42.864,42.864,0,0,1,6.45-.72c.25-.01.5-.02.75-.02l3.53,2.66c-.25,0-.5.01-.75.02a41.746,41.746,0,0,0-6.45.72" fill="#64602c"/>
        <path id="Path_106" data-name="Path 106" d="M435.11,402.38l-3.53-2.66c.16,0,.32,0,.48-.01l3.53,2.66c-.16,0-.32.01-.48.01" fill="#67632e"/>
      </g>
    </g>
    <g id="Group_49" data-name="Group 49">
      <path id="Path_108" data-name="Path 108" d="M435.59,402.37l227.12-1.22-1.42,76.11c-.43,23.18-19.17,42.23-42.05,43.03l-1.27.03-184.59.99a41.026,41.026,0,0,1-29.83-12.39,41.674,41.674,0,0,1-11.89-30.22l.62-33.27a44.049,44.049,0,0,1,42.11-43.04C434.78,402.38,435.18,402.38,435.59,402.37Zm224.71,74.9,1.4-75.11-226.12,1.21c-.42.01-.83.01-1.24.03-22.35.78-40.66,19.38-41.08,42.04l-.63,33.28a40.661,40.661,0,0,0,11.62,29.51,40.111,40.111,0,0,0,29.15,12.1l184.58-.99c.42-.01.83-.01,1.24-.03,22.35-.79,40.65-19.39,41.08-42.04" fill="#918c41"/>
    </g>
    <text id="Some_Text" data-name="Some Text" transform="translate(520.11 473.49)" fill="rgba(7,1,1,0.96)" fontSize="40" fontFamily="RobotoCondensed-Bold, Roboto" fontWeight="700"><tspan x="-90" y="0">{tag}</tspan></text>
  </g>
</svg>
</TbuttonContainer>



    )
}
export default ThreedButton