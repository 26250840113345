import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { lighten, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import Badge from '@material-ui/core/Badge'
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import {db,timestamp} from '../firebase'
import Paper from '@material-ui/core/Paper';

import IconButton from '@material-ui/core/IconButton';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import { Search } from "@material-ui/icons";

import SupervisedUserCircleIcon from '@material-ui/icons/SupervisedUserCircle';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';


import Select from '@material-ui/core/Select';

import FilterListIcon from '@material-ui/icons/FilterList';

import TextField from '@material-ui/core/TextField';
import  InputAdornment  from '@material-ui/core/InputAdornment';
import {useAdminStateValue} from '../Admin'


function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  { id: 'email',  label: 'User' },
  { id: 'createdAt',  label: 'Date created' },
  { id: 'role',  label: 'Role'},
  
  
];

function Input(props) {

  const { name, label, value,error=null, onChange, ...other } = props;
  return (
      <TextField
          variant="outlined"
          label={label}
          name={name}
          value={value}
          onChange={onChange}
          {...other}
          {...(error && {error:true,helperText:error})}
      />
  )
}
function FilterInput (props) {
  
  const classes=useToolbarStyles()
  const {role,handleFilterSearch}=props
  const roles=[{name:"Manager",role:"manager"},{name:"Region",role:"region"},{name:"Sales Rep",role:"salesrep"},{name:"Client",role:"client"},{name:"All",role:""}]
  
  return ( <FormControl className={classes.formControl}>
    <InputLabel id="select-role-input">role</InputLabel>
    <Select
      labelId="select-role-input"
      id="filter-select"
      value={role}
      onChange={handleFilterSearch}
    >
      {roles.map((ro,index)=>{
        return<MenuItem key={"roleinput"+index} value={ro.role}>{ro.name}</MenuItem> 
      })}
     
    </Select>
  </FormControl>

  )
}

function EnhancedTableHead(props) {
  const { classes, order, orderBy, onRequestSort} = props;
  const createSortHandler = (property) => (event) => {
    if (property==="role") return
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align='left'
            padding='none'
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  
  onRequestSort: PropTypes.func.isRequired,
  
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  
};

const useToolbarStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
  },
  highlight:
    theme.palette.type === 'light'
      ? {
          color: theme.palette.primary.main,
          backgroundColor: lighten(theme.palette.primary.light, 0.85),
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.primary.dark,
        },
  title: {
    flex: '1 1 100%',
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));


  
const EnhancedTableToolbar = (props) => {
  const { numSelected,onSetRoleClick,onFilterSearch,role} = props
  const classes = useToolbarStyles();
  
  const [filter,setFilter]=React.useState(false)
  const onFilterClick=()=>setFilter(!filter)
  
   
  return (
    <Toolbar
      className={clsx(classes.root, {
        [classes.highlight]: numSelected > 0,
      })}
    >
      {numSelected > 0 ? (<div style={{display:"flex",alignItems:"center",cursor:"pointer"}} onClick={onSetRoleClick}>
          <IconButton aria-label="setrole" >
          <Badge badgeContent={numSelected} color="secondary" showZero>     
               <SupervisedUserCircleIcon/>
          </Badge>
          </IconButton>
         
           <Typography className={classes.title} color="inherit" variant="subtitle1" component="div">
          Upload Changes
        </Typography>
      </div>
       
      ) : (
        <div style={{display:"flex",alignItems:"center"}}>
          
          <Typography className={classes.title} variant="h6" id="tableTitle" component="div">
            Set user role
          </Typography>
          
          <IconButton aria-label="setrole" onClick={onFilterClick}>
            <FilterListIcon/>
          </IconButton>
         
          {filter&&<FilterInput handleFilterSearch={onFilterSearch} role={role} />
           
          }
        </div>
      )}

      
    </Toolbar>
  );
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onSetRoleClick:PropTypes.func.isRequired,
};

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(3),
    
    paddingLeft:theme.spacing(2),
  },
  table: {
    minWidth: 450,
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  searchInput: {
    width: '75%'
},

}));

export default function SetUserRolesDisplay(props) {
  const {users}=props
  const [,dispatch]=useAdminStateValue()
  
  const classes = useStyles();
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('createdAt');
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [filterFn, setFilterFn] = React.useState({ fn: items => { return items; } })
  const [role, setRole] = React.useState('')
  
  const handleSearch = e => {
    let target = e.target.value.trim();
    setFilterFn({
        fn: items => {
            if (target === "")
                return items;
            else
                return items.filter(x => x.email.toLowerCase().includes(target))
        }
    })
}
  const handleFilterSearch = (e) => {
    console.log("handleFilterSearch",e.target.value)
     setRole(e.target.value)
    let target = e.target;
    setFilterFn({
        fn: items => {
            if (target.value === "")
                return items;
            else
               
                return items.filter(x => x.role.toLowerCase().includes(target.value))
        }
    })
}
  const recordsAfterPaginatonAndSorting=()=>{
    return stableSort(filterFn.fn(users), getComparator(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
  }
  
  
  const handleSetRole=() => {
    selected.forEach(user=>{
      db.collection('users').doc(user.id).update({role:user.role,roleSetAt:timestamp}).then(()=>{
        console.log("updating ",user.email," role to:",user.role)
        
        setSelected(selected.filter(u=>u.id!==user.id))
        
        dispatch({type:'SET_USERS',
        users:users.filter(u=>u.id!==user.id)})
        
      }).catch(e=>console.error(e))
    })}
  
          const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  

  const handleClick = (event, item) => {
      const role=event.target.value
      const selectedIndex = selected.map(n=>n.id).indexOf(item.id);
      let newSelected = [];
      const isInSelected=selectedIndex!==-1
      if (isInSelected) {
        const changedOriginalRole=role!==item.role
        const changedRole=selected[selectedIndex].role!==role
        if (changedOriginalRole){
          if (changedRole){
          const changeRoleSelected=selected.filter(n=>n.id!==item.id)
          newSelected=[...changeRoleSelected,{...item,role}]}
        } else {
          newSelected=selected.filter(n=>n.id!==item.id)
        }
      } else {
        newSelected=[...selected,{...item,role}]
      }
      
      
      
      setSelected(newSelected);
    }; 
    
  
    

  const handleChangePage = (event, newPage) => {
    
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const TablePaginationFn = () => (<TablePagination
    
    key="pagination"
    component="div"
    page={page}
    rowsPerPageOptions={[5, 10, 25]}
    rowsPerPage={rowsPerPage}
    count={users.length}
    onChangePage={handleChangePage}
    onChangeRowsPerPage={handleChangeRowsPerPage}
/>)
  

  const isSelected = (item) => {
      
    return selected.some(n=>n.id===item.id)};

  const emptyRows = rowsPerPage - Math.min(rowsPerPage, users.length - page * rowsPerPage);
  
  
  return (<>
    <div className={classes.root}>
      <Paper className={classes.paper}>
      <Toolbar>
                    <Input
                        label="Search users"
                        className={classes.searchInput}
                        InputProps={{
                            startAdornment: (<InputAdornment position="start">
                                <Search />
                            </InputAdornment>)
                        }}
                        onChange={handleSearch}
                    />
                </Toolbar>
        
        <EnhancedTableToolbar numSelected={selected.length} onSetRoleClick={handleSetRole} onFilterSearch={handleFilterSearch} role={role} />
        <TableContainer>
          <Table
            className={classes.table}
            aria-labelledby="tableTitle"
            size='small'
            aria-label="enhanced table"
          >
            <EnhancedTableHead
              classes={classes}
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              
              
             
              onRequestSort={handleRequestSort}
              rowCount={users.length}
            />
            <TableBody>
              {recordsAfterPaginatonAndSorting()
                .map((row, index) => {
                  const isItemSelected = isSelected(row);
                  const labelId = `enhanced-table-checkbox-${index}`;

                  return (
                    <TableRow
                    hover
                    key={row.id}
                    aria-checked={isItemSelected}
                    selected={isItemSelected}
                    tabIndex={-1}
                    role="checkbox"
                    >
                      
                      <TableCell component="th" id={labelId} scope="row" padding="none">
                        {row.email}
                      </TableCell>
                                           
                      <TableCell align="left">{row.createdAt.toDate().toDateString()+" "+row.createdAt.toDate().toLocaleTimeString()}</TableCell>
                     <TableCell align="left">
                     <FormControl component="fieldset"
                     
                     >
      <FormLabel component="legend">Set Role</FormLabel>
      <RadioGroup row aria-label="role" name="role" defaultValue={row.role} onChange={(event)=>handleClick(event,row)}>
        <FormControlLabel
          value="manager"
          control={<Radio color="primary" />}
          label="Manager"
          labelPlacement="start"
        />
        <FormControlLabel
          value="region"
          control={<Radio color="primary" />}
          label="Region"
          labelPlacement="start"
        />
        <FormControlLabel
          value="salesrep"
          control={<Radio color="primary" />}
          label="Sales rep"
          labelPlacement="start"
        />
        <FormControlLabel
          value="client"
          control={<Radio color="primary" />}
          label="Client"
          labelPlacement="start"
        />
        
      </RadioGroup>
    </FormControl>
                     </TableCell>
                    </TableRow>
                  );
                })}
              {emptyRows > 0 && (
                <TableRow style={{ height: 33* emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
       
        
        </TableContainer>
        <Paper className={classes.paper}>
          {TablePaginationFn()}
        </Paper>
       
        
        
       </Paper>
      
    </div>
    </>
  );
}