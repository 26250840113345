import React from 'react'
import {useDevice} from "../hooks"
import Header from '../components/Header'
import Navbar from '../components/Navbar'
import Banner from '../components/Banner'
import Footer from '../components/Footer'
import {services} from '../utilities'
import GridContainer,{Canvas} from '../components/service.elements'
import ProductCard from '../components/MainCard'
function Service(props) {
    const device=useDevice()
    
    return (
        
        <>
            <Header device={device} />
            <Navbar  device={device}/>
            <Banner/>
            <GridContainer device={device}>
               {services.map(service=>
           <ProductCard {...props} key={service.id}
           url={service.url}
           name={service.name}
           id={service.id}
           short={service.short}
           alt={service.alt}
           device={device}
           
           />
           )} 
            </GridContainer>
           
        <Footer device={device} className="service__footer"/>
        </>
    )
}

export default Service