import React from 'react'
import {SideContainer} from './Sidenav.elements'
import {useDevice} from '../hooks'
import {useStateValue} from '../StateProvider'
import {Link} from 'react-router-dom'
const Sidenav = (props) => {
    const [{user}]=useStateValue()
    const device=useDevice()
    return (
        <SideContainer device={device}>
            {user?.uid==="XC2bNm36jhQq1BwCF4sRuk8PlOr2"&&
         <Link to="/adminByOwner">
             <h3>Manage</h3>
         </Link>}
         <Link to='/about'>
             <h3>About</h3>
         </Link>
         <Link to='/products'>
             <h3>Products</h3>
         </Link>
         <Link to='/services'>
             <h3>Services</h3>
         </Link>
         <Link to='/contact'>
             <h3>Contact</h3>
         </Link>
        </SideContainer>
    )
}

export default Sidenav
