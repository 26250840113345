import styled from 'styled-components'

export const FileContainer=styled.div`
padding-left:${p=>p.Add?"none":"300px"};
width:95%;
margin-top:2%;
margin-left:3%;
margin-right:3%;
display:flex;
justify-content:space-between;
`
export const FileDrop=styled.div`
display:flex;
flex-direction:column;
`