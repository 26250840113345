import styled from 'styled-components'

export const Container=styled.div`


`
export const FileContainer=styled.div`
display:flex;
flex-direction:column;
align-items:center;
justify-content:space-evenly;
width:33vw;
height:15vh;
background-color:rgba(200,180,45,.3);

`