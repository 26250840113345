import React,{useState} from 'react'
import {Sidebar,ListContainer} from './Dashboard.elements'
import DashboardIcon from '@material-ui/icons/Dashboard';
import CollectionsBookmarkRoundedIcon from '@material-ui/icons/CollectionsBookmarkRounded';
import { makeStyles } from '@material-ui/core/styles';
import Badge from '@material-ui/core/Badge';

const useStyles = makeStyles((theme) => ({
    root: {
      '& > *': {
        margin: theme.spacing(1),
      },
    },
  }));
const UseDash = ({productEntries,selected,setSelected,lockSelection,...props}) => {

    const classes = useStyles();    

    
    const handleClick=(e,product)=>{
    if(!lockSelection)return
    e.preventDefault()
    setSelected(product)
    }


    const dashItems=productEntries.map((product,index)=>{
    const isSelected=selected[0]===product[0]
   
          return(<ListContainer isSelected={isSelected} key={'Dash_Cont_'+index} onClick={(e)=>handleClick(e,product)}>
                  <li key={'Dash_li_'+index}>{product[0]}</li>
                  <div className={classes.root}>
                      <Badge badgeContent={product[1].length} color="secondary" showZero>
                      <CollectionsBookmarkRoundedIcon key={'Dash_Icon_'+index} className="li_badge"/>
                      </Badge>
                  </div>
             </ListContainer>
             )})

    return (
        
        <Sidebar className="sidebar">
        <div className="dashboard_sidenav_header">
            <DashboardIcon/>
            <h1>{props.task}</h1>
        </div>
           <ul>{dashItems}</ul>
        </Sidebar>
        
        
    )
}

export default UseDash
