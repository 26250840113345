
const mobile={
    mobile:true,
    tablet:false,
    desktop:false,
    laptop:false,
}


const tablet={
    mobile:false,
    tablet:true,
    desktop:false,
    laptop:false
}

const desktop={
    mobile:false,
    tablet:false,
    desktop:true,
    laptop:false
}
const laptop={
    mobile:false,
    tablet:false,
    desktop:false,
    laptop:true,
}

const breakPoint={
mobile:767,
tablet:1020,
desktop:1220,
laptop:8200,
}
export {breakPoint,mobile,tablet,desktop,laptop}