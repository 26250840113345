import React,{useState,useEffect} from 'react';
// import {Link} from 'react-router-dom';
import {Container,Banner} from './ProductSelection.elements';
import {useBanner} from '../hooks'
import { services } from '../utilities';



const ProductSelection = ({collection,path,url,...props}) => {

const {showBanner,img}=useBanner(collection)






    return (
        <Container>
<Banner banner={img} img={showBanner} device={props.device}/>
    <h1 style={{color:"white", fontSize:"24px"}}>
         Please contact us for details on {services.filter(n=>n.collection===collection)[0].name}
    </h1>
           
            {/* you have chosen {collection} <br/> <br/>
           <Link to={`${url}/product1id`}>Product 1</Link>
           <Link to={`${url}/product2id`}>Product 2</Link>
           <Link to={`${url}/product3id`}>Product 3</Link> */}
            
        </Container>
    )
}

export default ProductSelection
