import React from 'react'

import { useParams,useRouteMatch} from 'react-router-dom';
import {useDevice} from '../hooks'
import Header from './Header'
import Navbar from './Navbar'
import Footer from './Footer'

import {Wrap} from './ProductDetail.elements'
import ProductSelection from './ProductSelection';
import ServiceSelection from './ServiceSelection'

const ProductDetail = (props) => {
const {id}=useParams()

const device=useDevice()
const {path,url}=useRouteMatch()
console.log(":id ",id," path:",path," url:",url)
    return (
        <Wrap>
<Header device={device}/>
<Navbar device={device}/>
{path.includes("products")&&<ProductSelection collection={id} path={path} url={url} device={device}/>}
{path.includes("services")&&<ServiceSelection collection={id} path={path} url={url} device={device}/>}
<Footer device={device} className="pdetail__footer"/>
        </Wrap>
    )
}

export default ProductDetail
