import styled from 'styled-components'


export const CardContainer=styled.div`
background-color:#fff;
border:3px solid var(--primary);
box-shadow:0 20px 20px rgba(0,0,0,0.2), 0 0 50px rgba(0,0,0,0.2);
height:600px;
width:1200px;
border-radius:30px;
overflow:hidden;
padding:10px;
display:grid;
grid-template-columns:repeat(15,1fr);
grid-template-rows:repeat(14,1fr);

column-gap:.2rem;
row-gap:.2rem;

.ThumbContainer{
grid-area:1/1/10/4;
background-color:rgba(255,255,255,1);
overflow:auto;

img{
    margin-top:3px;
    margin-bottom:3px;
    width:170px;
    height:170px;
    object-fit:contain;
}
}

.MainPicture{
grid-area:1/5/6/13;
background-color:rgba(100,100,60,.3);
align-self:center;
justify-self:center;

img{
    
    width:600px;
    height:210px;
    object-fit:contain;
   
}
.imgWrap{
    width:fit-content;
    background-color:rgba(230,150,180,.3);
}


}

.PDFInfo{
grid-area:2/14/6/16;
background-color:rgba(50,200,50,.3);
.sds,.pds{
    :hover{
        font-weight:600;
        cursor:pointer;
    }
}
}
.CodeInfo{
    grid-area:4/13/6/14;
    text-align:left;
    font-size:12px;
}
.TextInfo{


grid-area:6/4/10/16;
overflow:auto;
}

.PackInfo{
grid-area:11/1/15/16;
border-radius:10px;
background-color:rgba(150,100,200,.3);
z-index:5;
}



`
export const MagnifyLens=styled.div.attrs(p=>({
    style:{
        display:p.magnify?"block":"none",
        width:p.imgSource.width,
        top:p.imgSource.top,
        left:p.imgSource.right-50,
        height:0,
        paddingTop:"56.25%",
        backgroundColor:"rgba(220,150,75,1)",
        backgroundImage: `url(${p.url})`,
        backgroundPosition:`- ${p.lensPosition.x*3}px ${p.lensPosition.y*3}px`,
        backgroundSize:"cover",
    }

}))`

position:absolute;
background-repeat:no-repeat;

z-index:6;
`
