import React from 'react'
import {FileContainer} from './DropFamilyImage.elements'
import Button from '@material-ui/core/Button'

const GetInputFiles = (props) => {
    const {setSuccess,tag,validTypes,inputFiles,setInputFiles,cardPosition}=props
    const [highlighted,setHighlighted]=React.useState(false)
    
    const inputRef=React.useRef()
    React.useEffect(()=>{
      if (inputFiles){
        if (inputFiles.length>0) {
        setSuccess(true)
      } else {
        alert("Please use a valid file format")
      }}
    },[inputFiles])
    const styleDiv={
      position:"absolute",
      top:cardPosition.top,
      left:cardPosition.left,
      width:cardPosition.width,
      height:cardPosition.height,
      
  }
    return (
<div style={styleDiv}>
  
          <FileContainer 
          onDragEnter={(e)=>{
              e.preventDefault()
              setHighlighted(true)}}
            onDragLeave={(e)=>{
              e.preventDefault()
              setHighlighted(false)}}
            onDragOver={e=>{e.preventDefault()
              setHighlighted(true)}}
            onDrop={e=>{
                e.preventDefault()
                setHighlighted(false) 
                const files=Array.from(e.dataTransfer.files).filter(file=>validTypes.some(validType=>file.name.endsWith(validType)))
                setInputFiles(files)
               
                }}
            highlighted={highlighted}
            
           >
          <h3 ><strong> Drop file here </strong></h3>
          <Button variant="contained" 
          component="span"
          style={{color:"#000"}}
          onClick={e=>{
            e.preventDefault()
            inputRef.current.click()
          }}
          >
            {tag}
          </Button>
          
            <input
            ref={inputRef}
           style={{display:"none"}}
          className='input_myfiles'
          
          multiple
          type="file"
          onChange={e=>{
          const files=Array.from(e.target.files).filter(file=>validTypes.some(validType=>file.name.endsWith(validType)))
            setInputFiles(files)
              
              }}
        />
              
          </FileContainer>
          
</div>
    )
}

export default GetInputFiles
