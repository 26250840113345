import React,{useState,useEffect,useRef} from 'react'
import {FileContainer} from './DropFile.elements'
import {useAdminStateValue} from '../Admin'
import Button from '@material-ui/core/Button'
const DropFile = (props) => {
const [highlighted,setHighlighted]=useState(false)
const [inputFiles,setInputFiles]=useState(null)
const [{task},dispatch]=useAdminStateValue()


useEffect(()=>{
 
  if (inputFiles&&task) {
    if (inputFiles.length>0) {
    if (task.id==="DATABASE_CREATE") {
      dispatch({
        type:'DB_FILES',
        databasefiles:inputFiles
      })
      dispatch({
        type:'OPEN_DB_CREATE',
        open:true
      })

      dispatch({
        type:'TEMPLATE_FILES',
        templatefiles:[]
      })
    } else {
      if (task.id==="TEMPLATES_ADD") {
        dispatch({
          type:'TEMPLATE_FILES',
          templatefiles:inputFiles
        })
        dispatch({
          type:'DB_FILES',
          databasefiles:[]
        })
        dispatch({
          type:'OPEN_TEMPLATE_ADD',
          open:true
        })
      } else {
        if (task.id==='DATABASE_UPDATE'){
          switch(props.tag) {
            case 'Get PDS':
              dispatch({
                type:'UPDATE_PDS_FILE',
                updatePDSfile:inputFiles[0]
              })
              props.setUpdateFile({...props.gotUpdateFile,gotFile:true,pds:true})
              props.setUpdate({...props.update,pds:inputFiles[0]})
              break
            case 'Get SDS':
              dispatch({
                type:'UPDATE_SDS_FILE',
                updateSDSfile:inputFiles[0]
              })
              props.setUpdateFile({...props.gotUpdateFile,gotFile:true,sds:true})
              props.setUpdate({...props.update,sds:inputFiles[0]})
              break
            case 'Get Image(s)':
              dispatch({
                type:'UPDATE_IMAGES_FILES',
                updateImagesfiles:inputFiles
              })
              props.setUpdateFile({...props.gotUpdateFile,gotFile:true,images:true})
              const images=props.update.images==="na"?inputFiles:[...props.update.images,...inputFiles]
              props.setUpdate({...props.update,images})
              break
              default:
                console.log("I should never be able to read this!!!!")
              break
          }
           }}}}
    else 
    {
      alert("Please use a valid file format")
    }
      }
},[inputFiles])

const inputRef=useRef()

    return (
      
        <FileContainer {...props}
        onDragEnter={(e)=>{
          e.preventDefault()
          setHighlighted(true)}}
        onDragLeave={(e)=>{
          e.preventDefault()
          setHighlighted(false)}}
        onDragOver={e=>{e.preventDefault()
          setHighlighted(true)}}
        onDrop={e=>{
            e.preventDefault()
            setHighlighted(false) 
            const files=Array.from(e.dataTransfer.files).filter(file=>props.validTypes.some(validType=>file.name.endsWith(validType)))
            setInputFiles(files)
           
            }}
        highlighted={highlighted}
        >
        <h3><strong> Drop files here </strong></h3>
        <Button variant="contained" color="inherit"
        component="span"
        onClick={e=>{
          e.preventDefault()
          inputRef.current.click()
        }}
        >
          {props.tag}
        </Button>
        
          <input
          ref={inputRef}
         style={{display:"none"}}
        className='input_myfiles'
        
        multiple
        type="file"
        onChange={e=>{
        const files=Array.from(e.target.files).filter(file=>props.validTypes.some(validType=>file.name.endsWith(validType)))
          setInputFiles(files)
            
            }}
      />
     
        
    </FileContainer>    
              
        
    )
}

export default DropFile
