import React from 'react'
import {MyProfileContainer,Backdrop} from './MyProfile.elements'

 const MyAccount = (props) => {
    const handleClick=(e)=>{
        if( e.target.classList.contains('backdrop')){      
        props.setShowMyAccount(false)
        props.setAnchorEl(false)}
    }
    
    
    
    return (
        <Backdrop 
       onClick={handleClick}
       className="backdrop">
        <MyProfileContainer device={props.device}>
           <p>My orders</p>
           <p>Track order</p>
           <p>My browsing history</p>
           <p>My certificates of Analysis</p>
           <p>My SDS, PDS</p>
        </MyProfileContainer>
        </Backdrop>
    )
}
export default MyAccount