import styled,{css} from 'styled-components'

export const Canvas=styled.div`
${p=>(p.device.desktop||p.device.laptop)&&css`
.service__footer{
position:fixed;
bottom:0;
left:0;

}
`}

`

const GridContainer=styled.div`

${props=>props.device.mobile&&css`
max-width:98vw;
display: grid;
   grid-gap: 2%;
    grid-template-columns: repeat(auto-fit, minmax(170px, 1fr));
    
    
margin-left:5%;
margin-right:5%;
margin-bottom:30%;
`}
${props=>props.device.tablet&&css`
max-width:98vw;
display: grid;
   grid-gap: 2%;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    
    
margin-left:5%;
margin-right:5%;
margin-bottom:12%;

`}
${props=>props.device.desktop&&css`
max-width:98vw;
display: grid;
   grid-gap: 2%;
    grid-template-columns: repeat(auto-fit, minmax(220px, 1fr));
    
    
margin-left:5%;
margin-right:5%;
margin-bottom:8%;

`}
${props=>props.device.laptop&&css`
max-width:98vw;
display: grid;
   grid-gap: 2%;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    
    
margin-left:5%;
margin-right:5%;
margin-bottom:8%;

`}
`
export default GridContainer