import React,{useState,useEffect,useRef} from 'react'
import Header from '../components/Header'

import {useDevice} from '../hooks'

import Navbar from '../components/Navbar'
import Footer from '../components/Footer'

import {Container,MenuButton} from '../components/adminByOwner.elements'

import {masterDBMenu,usersMenuItems,templateMenuItems} from '../utilities'
import AdminMenu from '../components/Admin_Menu'
import ExecuteDisplay from '../components/ExecuteDisplay'
import {useAdminStateValue} from '../Admin'
function AdminByOwner(props) {
    const buttonRef1=useRef()
    const buttonRef2=useRef()
    const buttonRef3=useRef()
    const [{task},]=useAdminStateValue()
    
    
    
    const [manageDB,setManageDB]=useState(false)
    const [manageUsers,setManageUsers]=useState(false)
    const [manageTemplates,setManageTemplates]=useState(false)
    const device=useDevice()
    const [messageP,setMessageP]=useState("")
    const [displayP,setDisplayP]=useState(true)
    const [position, setPosition]=useState({top:"50%",left:"-20%"})
    
    
    useEffect(()=>{
        if (manageDB){
            buttonRef1.current&&setPosition({top:buttonRef1.current.offsetTop+35,
            left:buttonRef1.current.offsetLeft})
       } else {if (manageUsers){
        buttonRef2.current&&setPosition({top:buttonRef2.current.offsetTop+35,
            left:buttonRef2.current.offsetLeft})
       } else {if (manageTemplates){
        buttonRef3.current&&setPosition({top:buttonRef3.current.offsetTop+35,
            left:buttonRef3.current.offsetLeft})
       }}}
    }
    
    ,[manageDB,manageUsers,manageTemplates,device])
    useEffect(()=>{
        setDisplayP(!displayP)
    },[messageP])
  
     
     
    const handleMasterDB=()=>{
        setManageDB(!manageDB)
        setManageUsers(false)
        setManageTemplates(false)
    }    
    const handleUsers=()=>{
        setManageUsers(!manageUsers)
        setManageDB(false)
        setManageTemplates(false)
    }    
    const handleTemplates=()=>{
        setManageTemplates(!manageTemplates)
        setManageDB(false)
        setManageUsers(false)
    }    
    
        



    


    return (

    
            <Container>
                <Header device={device}/>
                <Navbar device={device} />
                {!task?
                <div  className="admin_mainbuttons">
                    <MenuButton ref={buttonRef1} 
                    
                    onClick={handleMasterDB}>Master Database</MenuButton>
                    {manageDB&&
                        <AdminMenu
                        dbButton
                        top={position.top}
                        left={position.left}
                        menu={masterDBMenu}
                        device={device}
                        />
                        }
                    <MenuButton ref={buttonRef2} 
                    
                    onClick={handleUsers}>Manage Users</MenuButton>
                    {manageUsers&&
                        <AdminMenu
                        top={position.top}
                        left={position.left}
                        menu={usersMenuItems}
                        device={device}
                        />
                        }
                    <MenuButton ref={buttonRef3} 
                    
                    onClick={handleTemplates}>Templates</MenuButton>
                    {manageTemplates&&
                        <AdminMenu
                        top={position.top}
                        left={position.left}
                        menu={templateMenuItems}
                        device={device}
                        />
                        }
                </div>
                :
                <ExecuteDisplay 
                 device={device}
                />}
                <Footer className="admin__footer" device={device} />
            </Container>
            
            

    )
    }
export default AdminByOwner