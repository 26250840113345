import React,{useState,useEffect} from 'react'
import {useGetTrash} from '../hooks'
import {useAdminStateValue} from '../Admin'
import RestoreDisplayV2 from './RestoreDisplayV2'
const RestoreProduct = () => {
const [fetching,setFetching]=useState(true)
const [docsInTrash,setDocsInTrash]=useState(false)
    useGetTrash()
const [{trash},]=useAdminStateValue()
useEffect(()=>{
if (trash?.length>0) {
    
    setFetching(false)
if (trash[0]!=="na") {
    setDocsInTrash(true)
}
}
},[trash])
    return (<>
        {fetching&&<div>
            Restoring products...
        </div>}
        {!fetching&&docsInTrash?
        <RestoreDisplayV2 trash={trash}/>:
        <p>There are no documents in the trash</p>}
        </>
    )
}

export default RestoreProduct
