import styled,{css} from 'styled-components'

 const MenuContainer=styled.div`
:hover >h3{
    background-color:transparent;
    transition:0.3s ease-in all;
}
h3:hover{
    background-color:var(--primary);
    transition:0.3s ease-in all;
}
${props => props.device.mobile&&css`
    background-color:white;
    background-image:-webkit-linear-gradient(135deg,var(--primary)0,white 74%);

    border: 1px solid #d3d4d5;
    width:130px;
    position:absolute;
    z-index:26;
    bottom:55px;
    border-radius:10px;
    overflow:hidden;
   
`}
${props=>props.device.tablet&&css`
    background-color:white;
    background-image:-webkit-linear-gradient(135deg,var(--primary)0,white 74%);

    border: 1px solid #d3d4d5;
    width:130px;
    position:absolute;
    z-index:26;
    border-radius:10px;
    overflow:hidden;
    


`}
${props=>props.device.desktop&&css`
    background-color:white;
    background-image:-webkit-linear-gradient(135deg,var(--primary)0,white 64%);

    border: 1px solid #d3d4d5;
    width:130px;
    position:absolute;
    z-index:26;
    border-radius:10px;
    overflow:hidden;
 
`}
${props=>props.device.laptop&&css`
    background-color:white;
    background-image:-webkit-linear-gradient(135deg,var(--primary)0,white 74%);

    border: 1px solid #d3d4d5;
    width:130px;
    position:absolute;
    z-index:26;
    border-radius:10px;
    overflow:hidden;
   
`}

 `
 export const  MenuLinkP=styled.h3`
 background-color:${props=>props.selected?'var(--primary)':'transparent'};
 margin-bottom:5%;
 margin-left:3%;
 ::after{
     content:'';
     border: 1px solid ${props=>props.selected?'white':'var(--secondary)'};
     display:block;
     margin:8px 0px 8px;
     }
 
 :hover {
     
     transition:0.2s ease-all in;
      ::after{
     content:'';
     border: 1px solid rgba(255,255,255,0.5);
     display:block;
     
     }
  
     
    }

 `
 export default MenuContainer