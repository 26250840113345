import React,{useState} from 'react'
import MenuContainer,{MenuLinkP} from './Menu.elements'

import {auth} from '../firebase'

const Menu = (props) => {
 const [menuItem,setMenuItem]=useState(props.menu[0])  


    
            

    

 
const handleSelect= (selection)=>{
setMenuItem(selection)

switch (selection) {
    case props.menu[0]:
        
        props.setShowMyProfile(true)
        props.setShowMyAccount(false)
        break
    case props.menu[1]:
        
        props.setShowMyAccount(true)
        props.setShowMyProfile(false)
        break
    case props.menu[2]:
        
        auth.signOut()
        break
    default:
            props.setAnchorEl(false)
}
}



    const menuJSX=
        props.menu.map((link,index)=>{
            const selected=menuItem===link
        return(  
         <MenuLinkP 
         selected={selected} 
         key={index}
         onClick={()=>handleSelect(link)}
            
         >
             {link}
        </MenuLinkP>
        )})
    
    return (
       
        <MenuContainer {...props}>
            
           {menuJSX}
           
        </MenuContainer>
        
    )
}

export default Menu
