import React from "react"
import SocialsContainer from './Socials.elements'
import FacebookIcon from '@material-ui/icons/Facebook';
import InstagramIcon from '@material-ui/icons/Instagram';
import LinkedInIcon from '@material-ui/icons/LinkedIn';

function Socials (props) {
   
    return (
       
           
        <SocialsContainer {...props} className="socials__container"> 
                <div className="socials__Inst">
                    <InstagramIcon className="instagram"/>
                </div>
                <div className="socials__Link">
                    <LinkedInIcon className="linkedIn" />
                </div>
                         
                <div className="socials__Face">
                    <FacebookIcon className="faceBook"/>
                </div>    
           </SocialsContainer>

       
        
       
    )
}
export default Socials