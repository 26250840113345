import styled,{css} from'styled-components'



export const Searchcontainer=styled.div`
display:flex;
align-items: center;


${props=>props.device.mobile&&css`
height:24px;
.searchBar__left{
    position:relative;
    display:flex;
    align-items:center;
    background-color:var(--secondary);
    color:white;
    cursor:pointer; 
    height:24px;
    width:26px;
    border-top-left-radius:3px;
    border-bottom-left-radius:3px;
    .searchBar__tag{
    font-size:${props=>props.oem?"8px":"10px"};
     width:32px;
     text-align:center;
    }
    .searchBar__arrow{
    font-size:12px;
    }
    
}

.searchBar__input{
    height:24px;
    font-size:12px;
    flex-grow:1;
    -webkit-appearance: none;
    -moz-appearance: none;
    border-color: rgba(0, 0, 0, 0.2);
    border-style: solid;
    border-width: 1px;
    
    &:focus {
        outline:none;
    }
    
}
.searchBar__right{
    height:24px;
    width:24px;
    background-color:var(--secondary);
    color:white;
    cursor:pointer;
    border-top-right-radius:3px;
    border-bottom-right-radius:3px;
}
`}
${props=>props.device.tablet&&css`

height:20px;
.searchBar__left{
    position:relative;
    display:flex;
    align-items:center;
    justify-content:space-around;
    background-color:var(--secondary);
    color:white;
    height:30px;
    width:38px;
    cursor: pointer;
    border-top-left-radius:6px;
    border-bottom-left-radius:6px;
    .searchBar__tag{
        margin-left:4px;
        font-size:${props=>props.oem?"12px":"14px"};
        text-align:center;
    }
    .searchBar__arrow{
   font-size:14px;
    
}
}

.searchBar__input{
    height:30px;
    font-size:18px;
    flex-grow:1;
    -webkit-appearance: none;
    -moz-appearance: none;
    border-color: rgba(0, 0, 0, 0.2);
    border-style: solid;
    border-width: 1px;
    padding: 0.2rem;
    &:focus {
        outline:none;
    }
}
.searchBar__right{
    height:30px;
    width:30px;
    background-color:var(--secondary);
    color:white;
    cursor:pointer;
    border-top-right-radius:6px;
    border-bottom-right-radius:6px; 
}
`}
${props=>props.device.desktop&&css`
height:24px;
    .searchBar__left{
        position:relative;
            height:32px;
            width:32px;
            display:flex;
            align-items:center;
            background-color:var(--secondary);
            color:white;
            border-top-left-radius:10px;
            border-bottom-left-radius:10px;
            cursor:pointer;
        .searchBar__tag{
        font-size:${props=>props.oem?"10px":"14px"};
        width:60px;
        text-align:center;
        }
        .searchBar__arrow{
           font-size:14px;
        }

    }

.searchBar__input{
    height:32px;
    font-size:24px;
    flex-grow:1;
    max-width:700px;
    border-color: rgba(0, 0, 0, 0.2);
    border-style: solid;
    border-width: 1px;
    padding: 10px;
    &:focus {
        outline:none;
    }
}
.searchBar__right{
    height:32px;
    width:28px;
    background-color:var(--secondary);
    color:white;
    cursor:pointer;
    border-top-right-radius:10px;
    border-bottom-right-radius:10px; 
}

`}
${props=>props.device.laptop&&css`
height:24px;
    .searchBar__left{
        position:relative;
            height:32px;
            width:32px;
            display:flex;
            align-items:center;
            background-color:var(--secondary);
            color:white;
            border-top-left-radius:10px;
            border-bottom-left-radius:10px;
            cursor:pointer;
        .searchBar__tag{
        font-size:${props=>props.oem?"10px":"14px"};
        width:60px;
        text-align:center;
        }
        .searchBar__arrow{
            font-size:10px;
        }

    }

.searchBar__input{
    height:32px;
    font-size:20px;
    flex-grow:1;
    max-width:700px;
    border-color: rgba(0, 0, 0, 0.2);
    border-style: solid;
    border-width: 1px;
    padding: 10px;
    &:focus {
        outline:none;
    }
}
.searchBar__right{
    height:32px;
    width:28px;
    background-color:var(--secondary);
    color:white;
    cursor:pointer;
    border-top-right-radius:10px;
    border-bottom-right-radius:10px; 
}

`}

`
