const productImages = {
  additives: require('./products/additives.jpg'),
  tanker: require('./products/tanker.jpg'),
  lubricants: require('./products/lubricants.jpg'),
  grease: require('./products/grease.jpg'),
  defArtboard: require('./products/defArtboard.jpg'),
  refrigerantsArtboard: require('./products/refrigerantsArtboard.jpg'),
  Hardware: require('./products/Hardware.jpg')
}
export default productImages
