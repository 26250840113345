import React from 'react'
import ViewAddProductCard from './ViewAddProductCard'

const AddingProduct = (props) => {
       
    
    return (
        <div >
             <ViewAddProductCard 
            productFiles={props.productFiles}
            product_name={props.product_name}
            description={props.description}
            packing={props.packing}
            code={props.code}
           />
        </div>
    )
}

export default AddingProduct
