import React,{useEffect,useState} from 'react'


const FileInfo = (props) => {
   
    const    {pds,sds,images}=props.update
   const imagesBool=props.source==="Images"
   const pdsBool=props.source==="PDS"
   const sdsBool=props.source==="SDS"
   const [isFileInDB,setIsFileInDB]=useState(false)
   
   const hasContent=(verify)=>{
    return !(verify==="na"||verify===""||(Array.isArray(verify)&&verify.length===0))
   }
   const inDB=(verify)=>typeof(verify)==='string'&&verify.startsWith('https://firebasestorage')
   
   useEffect(()=>{
       
    if (imagesBool){
        if (hasContent(images)) {
        setIsFileInDB (images.some(img=>inDB(img)))
        }
    }
    if (pdsBool) {
        if (hasContent(pds)) {
        setIsFileInDB(inDB(pds))    
    }
    } 
     if (sdsBool) {
        if (hasContent(sds)) {
        setIsFileInDB(inDB(sds))
    }
    } 
    

   },[pds,sds,images])
    
    
    
    


    
    return (
        <div key={imagesBool?"images"+300:pdsBool?"pds"+300:"sds"+300}>
        {!isFileInDB?<p>There {imagesBool?'are':"is"} no {props.source} {imagesBool?'':"file"} in DB</p>:
        <p>Caution: existing file{imagesBool&&images.length>0?'s':""} in DB!   </p> }
        {!props.gotUpdateFile.pds&&pdsBool&&!isFileInDB&&<p>Please Input a PDS file</p>}
        {!props.gotUpdateFile.pds&&pdsBool&&isFileInDB&&<p>Modify existing PDS file?</p>}
        {!props.gotUpdateFile.sds&&sdsBool&&!isFileInDB&&<p>Please Input an SDS file</p>}
        {!props.gotUpdateFile.sds&&sdsBool&&isFileInDB&&<p>Modify existing SDS file?</p>}
        {!props.gotUpdateFile.images&&imagesBool&&!isFileInDB&&<p>Please input images</p>}
        {!props.gotUpdateFile.images&&imagesBool&&isFileInDB&&<p>Modify? Input new images?</p>}
        {props.gotUpdateFile.pds&&pdsBool&&<p>Got a PDS file:</p>}
        {props.gotUpdateFile.sds&&sdsBool&&<p>Got an SDS  file:</p>}
        {props.gotUpdateFile.images&&imagesBool&&<p>Got {images.length} image{images.length>1?"s":""}:</p>}
        </div>
    )
}

export default FileInfo