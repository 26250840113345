import styled from "styled-components"


export const LoginContainer=styled.div`
display:flex;
flex-direction:column;
justify-content:center;
text-align:center;

.login__logo{
    transform:translateX(-50%);
width:${props=>props.device.mobile?"60px":
props.device.tablet?"80px":
props.device.desktop?"100px":"120px"};
margin-bottom:${props=>props.device.mobile?"20px":
props.device.tablet?"40px":
props.device.desktop?"60px":"80px"};

}
.login__footer{
    position:fixed;
bottom:0vh;
left:0;
width:100%;
}
`