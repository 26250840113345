import React from 'react'
import {ReactComponent as Mmenuopensvg} from '../images/svg/Mmenuopensvg.svg'
import {CustomsizeIcon,MenuOpenContainer} from './MmenuOpen.elements'





export default function Mmenuopen(props) {



    return (
 <MenuOpenContainer {...props} >       
    
        <CustomsizeIcon {...props} viewBox="0 0 59 41">      
            <Mmenuopensvg/>
        </CustomsizeIcon>
   
</MenuOpenContainer>               
        
    )
}
