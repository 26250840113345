import React from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  } from 'react-router-dom';
import {Service,
  AdminByOwner,
  Homeview,
  About,
  Productview,
  Login,
  Contact,
  TexasAdvantage,
  Selection} from '../pages'

  import {AdminStateProvider,
  reducer,
initialState} from '../Admin'

import ProductDetail from './ProductDetail';
import {useStateValue} from '../StateProvider'
import SelectedP from './SelectedP';

import {useStore,useDevice,useSetRole, useDb, useAuth} from '../hooks'
import Container from './App.elements'
import Header from './Header'
import Navbar from './Navbar'
import Footer from './Footer'
import VerifyManage from './VerifyManage'
import {products,services} from '../utilities'

  
function App() {
  
  
  const RouteNotFound=()=>{
    const device=useDevice();
    return    (
    <Container>
      <Header device={device} />
      <Navbar device={device} />
      <h1>Error 404, Route not found!</h1>
      <Footer className="notFound" device={device} />
    </Container>
    )
  }
 
useAuth()
useSetRole()
useStore()
useDb('Family_images',"PRODUCTS_FAMILY_IMAGES") 
    
  return (
    <div >
      <Router > 
        
        
         <Switch>
          <Route path="/adminByOwner">
            <VerifyManage>
              <AdminStateProvider initialState={initialState} reducer={reducer}>
              <AdminByOwner/>
              </AdminStateProvider>
            </VerifyManage>
          </Route>           
          <Route path="/about" component={About } />           
          <Route path="/products" exact>
            <Productview display={products} /> 
          </Route>     
          <Route path="/services" exact >
            <Productview  display={services}/> 
          </Route>
          <Route path="/products/:id" exact component={ProductDetail } />     
          <Route path="/services/:id" exact component={ProductDetail } />
          <Route path="/products/:id/:selection" component={SelectedP } />     
          <Route path="/services/:id/:selection" component={SelectedP } /> 
          <Route path="/contact" component={Contact } />            
          <Route path="/login"  component={Login } />                           
          <Route path="/selection"  component={Selection } />                           
          <Route path="/texas" component={TexasAdvantage } />     
          <Route exact path="/" component={Homeview}  />
          <RouteNotFound/>      
        </Switch>
           
      </Router>
      
      
    </div>
  );
}

export default App
