import styled from 'styled-components'

export const Container=styled.div`
margin-bottom:3%;

a{


:hover{
    & .MuiCardContent-root {transform:scale(1.05);
    background-color:var(--primary);
    color:white;
    transition:all ease-in-out 0.3s;
    }
}}

`

export const Banner=styled.div`
height:${p=>p.device.mobile?"250px":
p.device.tablet?"350px":
p.device.desktop?"400px":
"450px"};
margin-bottom:${p=>p.device.mobile?"-25%":
p.device.tablet?"-20%":
p.device.desktop?"-20%":
"-18%"};
background-image:linear-gradient(0deg,rgba(244,180,0,.65)0%,rgba(255,255,255,0.3) 74%),url(${p=>p.img});
background-size:cover;
background-origin: border-box;
background-position:${p=>p.banner.BO?"25% 75%":"cover"};
background-repeat:no-repeat;
    
    
   z-index: -2;
`