const svgPacking = {
  
  cartridge: require('./packing/cartridge_1.svg'),
  keg: require('./packing/keg.svg'),
  qtbottle: require('./packing/bottleqt.svg'),
  jug1gal: require('./packing/jug.svg'),
  jug5qt: require('./packing/jug5qt.svg'),
  pail: require('./packing/pail.svg'),
  drum: require('./packing/drum.svg'),
  tote: require('./packing/tote.svg'),
  flexi: require('./packing/flexitank.svg'),
}
export default svgPacking
