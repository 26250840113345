import styled from 'styled-components'

export const MyProfileContainer=styled.div`
position:absolute;
top:-270px;
left:300px;

width:1000px;
height:700px;

margin: 18% auto;

display:flex;
flex-direction:column;
justify-content:space-evenly;
background:white;
background:linear-gradient(to right bottom,rgba(255,255,255,.7),rgba(255,255,255,.3));
backdrop-filter:blur(.8rem);
box-shadow:3px 5px 7px rgba(0,0,0,0.1);
border:solid 3px white;
border-radius:15px;
z-index:25;


`
export const Backdrop=styled.div`
position:fixed;
top:0;
left:0;
height:100vh;
width:100vw;
z-index:22;
background-color:rgba(0,0,0,0.1);
`