import styled,{css} from 'styled-components'

export const ThumbContainer=styled.div`
display:flex;
align-items:flex-start;
:hover{
    .checkdiv{
        overflow: visible;
            }
}
.checkdiv{
height:1px;
width:1px;
overflow:hidden;
}
img{
    
    object-fit:contain;
    margin-top:3px;
    margin-bottom:3px;
    width:97px;
    height:100px;
    :hover{
        ${p=>p.clickable&&css`
        cursor:pointer;
        transform:scale(1.05);
        transition:all .3s ease-in ;
        `}
    }
}

`
