import styled from 'styled-components';




const CustomsizeIcon=styled.svg`
    background-color:white;
    height:${props=>props.device.mobile?"32px":props.device.tablet?"60px":"60px"};
    width:${props=>props.device.mobile?"36px":props.device.tablet?"70px":"65px"};
    box-shadow: 2px 2px rgba(50,50,100,.2);
    display:flex;
    align-items: center;    
    
`
export default CustomsizeIcon