import React from 'react'
import {useDevice} from "../hooks"
import Header from '../components/Header'
import Navbar from '../components/Navbar'
import Banner from '../components/Banner'
import Footer from '../components/Footer'

import GridContainer from '../components/productview.elements'
import ProductCard from '../components/MainCard'

function Productview(props) {
    const device=useDevice()
   
    
    return (
        
        <>
            <Header device={device} />
            <Navbar  device={device}/>
            <Banner/>
            <GridContainer device={device}>
               {props.display.map(product=>
           <ProductCard  key={product.collection}
           url={product.url}
           name={product.name}
           id={product.collection}
           short={product.short}
           alt={product.alt}
           device={device}
           
           />
           )} 
            </GridContainer>
           
        <Footer device={device}/>
        
        </>
        
    )
}

export default Productview
