import styled,{css} from 'styled-components'
 
 
const FlagContainer=styled.div`
${props=>props.device.mobile&&css`
    height:150px;
    width:145px;
`}
${props=>props.device.tablet&&css`
    height:160px;
    width:155px;
`}
${props=>props.device.desktop&&css`
    height:170px;
    width:165px;
`}
${props=>props.device.laptop&&css`
    height:170px;
    width:165px;
`}

`
export default FlagContainer