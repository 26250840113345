import React from 'react'
import {IntroContainer} from './Intro.elements'
import AnimatedTexasState from './AnimatedTexasState'
import Tbutton from './Tbutton'
import IntroCardHome from './IntroCard'
import {useIntervalCount} from '../hooks'
import {products} from '../utilities'
import {useStateValue} from '../StateProvider'

function Intro(props) {
    const [,dispatch]=useStateValue()
   let show=useIntervalCount({time:1000})
          show%=20
    const tag1=products[0].name
    const tag2=products[1].name
    const tag3=products[2].name    
    const handleClick1=()=>{
        dispatch({type:"SET_TAG",tag:products[0].short})
        props.history.push(`${products[0].goTo}/${products[0].collection}`)
    }
    const handleClick2=()=>{
        dispatch({type:"SET_TAG",tag:products[1].short})
        props.history.push(`${products[1].goTo}/${products[1].collection}`)
    }
    const handleClick3=()=>{
        dispatch({type:"SET_TAG",tag:products[2].short})
        props.history.push(`${products[2].goTo}/${products[2].collection}`)
    }
    
    return (
        <IntroContainer {...props}>
        <div className="intro__1c">
            <IntroCardHome {...props} className="intro__card"/>
            {!(show===0)&&<AnimatedTexasState {...props} id="intro__map" />}
            
            
        </div>
            
        {(props.device.desktop||props.device.laptop)&&
        <div className="intro__2c">
            <Tbutton onClick={handleClick1} {...props} tag={tag1} className="intro__3rdrow__button1"/>
            <Tbutton onClick={handleClick2} {...props} tag={tag2} className="intro__3rdrow__button2"/>
            <Tbutton onClick={handleClick3} {...props} tag={tag3} className="intro__3rdrow__button3"/>
        </div>}
        </IntroContainer>
        
        
    )
}

export default Intro



