import React from 'react'
import Header from '../components/Header'
import Navbar from '../components/Navbar'
import Footer from '../components/Footer'
import {useDevice} from "../hooks"
import Aboutus from '../components/Aboutus'
import Banner from '../components/Banner'


function About(props) {
    const device=useDevice()
   
return(
    <div >
        <Header device={device} />
        <Navbar  device={device}/>
        <Banner/>
        
        <Aboutus/>
        <Footer device={device} className="aboutus__footer" />
    </div>
    )
}
export default About