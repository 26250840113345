import React from 'react'
import {pngPacking} from '../images'
import Typography from '@material-ui/core/Typography'

const PackingInfo = React.memo((props) => {
    console.log("PackingInfo")
const {packing}=props
const packingArray=Object.entries(packing).filter(pack=>pack[1]===true)
const packingPNG=Object.entries(pngPacking).filter(svg=>packingArray.some(pack=>pack[0]===svg[0]))

  

const packJSX=packingPNG.map((png,index)=>{
const size=png[0]==="flexi"?{width:"140px",height:"120px"}:
png[0]==="tote"?{width:"100px",height:"100px"}:
png[0]==="drum"?{width:"60px",height:"80px"}:
png[0]==="pail"?{width:"45px",height:"45px"}:
png[0]==="jug5qt"?{width:"25px",height:"35px"}:
png[0]==="jug1gal"?{width:"20px",height:"30px"}:
png[0]==="qtbottle"?{width:"15px",height:"25px"}:
png[0]==="keg"?{width:"40px",height:"60px"}:
png[0]==="cartridge"?{width:"60px",height:"20px"}:
{width:"60px",height:"60px"}


    return (
        <div key={"pack"+index} style={{objectFit:"contain",position:"relative"}}>
<span key={"name"+index} style={{position:"absolute",top:"-25px",left:"0"}}>
    {png[0]==="flexi"?"Flexitank":
    png[0]==="tote"?"Tote":
    png[0]==="drum"?"Drum":
    png[0]==="pail"?"Pail":
    png[0]==="jug5qt"?"5Qt":
    png[0]==="jug1gal"?"1Gal":
    png[0]==="qtbottle"?"1Qt":
    png[0]==="keg"?"Keg":
    png[0]==="cartridge"?"Cartridge":
    ""
    }
    
</span>
            <img key={png[0]+index}
       src={png[1]}
       alt="pack img"
       style={size}
    />
    
    </div>
    
    )
    
})

    return (
        <div style={{display:"flex",flexDirection:"column",padding:"10px 0 10px"}}>
            <Typography align="left" variant="subtitle1"> Packing:</Typography>
             
            <div style={{display:"flex", alignItems:"flex-end",justifyContent:"space-around",objectFit:"contain"}}>
               { packJSX}
            </div>
        </div>
    )
})

export default PackingInfo
