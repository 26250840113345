import styled,{css} from 'styled-components'


export const TbuttonContainer=styled.button`


${props=>props.device.mobile&&css`
width:60px;
height:20px;
+#Some_Text{font-size:10px;
text-align:center;}
`}
${props=>props.device.tablet&&css`
width:90px;
height:30px;
+#Some_Text{font-size:12px;
text-align:center;}
`}
${props=>props.device.desktop&&css`
width:90px;
height:30px;
+#Some_Text{font-size:24px;
text-align:center;}

`}
${props=>props.device.laptop&&css`
width:120px;
height:40;
+#Some_Text{font-size:18px;
text-align:center;}

`}

`