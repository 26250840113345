import React from 'react'
import {useHistory} from 'react-router-dom'
import MenuContainer,{MenuLinkP} from './SearchMenu.elements'
import {products,services }from '../utilities'
import { useStateValue } from '../StateProvider';
import {initialState} from '../reducer'
const Menu = (props) => {
    const [{tag},dispatch]=useStateValue()
const history=useHistory()
 
 
 const searchMenu=[]
 const searchMenuShort=[]  
    products.forEach((product)=>{
        searchMenu.push(product)
        searchMenuShort.push(product.short)})
    services.forEach((service)=>{
        searchMenu.push(service)
        searchMenuShort.push(service.short)})
        const includeAll=tag===initialState.tag
        if (!includeAll) {
            searchMenuShort.push(initialState.tag)
             searchMenu.push({name:initialState.tag,short:initialState.tag,goTo:"",collection:""})
            } else {if (searchMenu.indexOf(initialState.tag)!==-1)
            {
            searchMenu.pop()
            searchMenuShort.pop()
            }}
 
const handleSelect= (selection)=>{


dispatch({
    type:"SET_TAG",
    tag:selection.short
})

props.setAnchorEl(false)
     
history.push(`${selection.goTo}/${selection.collection}`)
}


    const menuJSX=
        searchMenu.map((link,index)=>{
            
        return(  
         <MenuLinkP 
         
         key={index}
         onClick={()=>handleSelect(link)}
            
         >
             {link.name}
        </MenuLinkP>
        )})
    
    return (
       
       
            <MenuContainer {...props}>
              
               {menuJSX}
               
            </MenuContainer>
       
        
    )
}

export default Menu