import styled from 'styled-components'


export const MiddleContainer=styled.ul`

display:flex;
align-items:center;
justify-content:space-around;

.navbarmiddle_button {
    &:hover {
  
  transform:scale(1.03);
  
}
  &:active {
    
    transform: translateY(4px);
    border-radius:15px;
    
    
  }
  
  }
  .navbarmiddle_text{
    &:hover {
  
  font-weight:500;
  
}
  }
`