import styled,{css} from "styled-components"

export const Container=styled.div`
width:75%;
align-self:center;
padding:20px;
border:1px solid var(--secondary);
border-radius:15px;
${p=>p.device.mobile&&css`
.firebaseui__text-long{
    display:none;
}
`}
${p=>p.device.tablet&&css`
.firebaseui__text-long{
    display:none;
}
`}
${p=>p.device.desktop&&css`
.firebaseui__text-short{
    display:none;
}
`}
${p=>p.device.laptop&&css`
.firebaseui__text-short{
    display:none;
}
`}
`
export const ProvidersList=styled.ul`

margin:auto;
display:flex;
flex-direction:${p=>p.device.tablet||p.device.desktop||p.device.laptop?"row":"column"};
align-items:center;
justify-content:${p=>p.device.tablet||p.device.desktop||p.device.laptop?"space-around":"center"};
li{padding:20px;}
li:nth-child(3)>button>span>img{
    background-color:rgba(0,0,255,.7);
        margin-left:10%;
        height:40px;
        width:40px;
}
li:nth-child(1)>button>span>img{
    background-color:rgba(255,0,0,.7);
        margin-left:10%;
        height:40px;
        width:40px;
}
li:nth-child(2)>button>span>img{
    background-color:white;
    margin-left:10%;
        height:40px;
        width:40px;
}

`
export const ProvidersButton=styled.button`
display:flex;
align-items:center;
height:50px;
border-radius: 5px;
    background-color: var(--primary);
    border: 1px solid;
    border-color: #a88734 #9c7e31 #846a29;
span{
    margin-right:25px;
    }
:hover{
    font-weight:600;
    color:white;
    span>img{
        transition:all ease 0.3s;
        transform:scale(1.1);
        
    }
}
`