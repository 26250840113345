import React from 'react'
import {FileContainer,FileDrop} from './ShowFiles.elements'
import ViewThumbFiles from './ViewThumbFiles'
import AddGetFiles from './AddGetFiles'

const AddFiles = (props) => {
    const {productFiles,setProductFiles,validTypes,validImageTypes,selected,setSelected}=props
    return (
        <FileContainer Add>
            <FileDrop>
                <AddGetFiles productFiles={productFiles} setInputFiles={setProductFiles} bin="pds" tag="Get PDS" small validTypes={validTypes}/>
                <div style={{marginTop:"3%",marginBottom:"5%"}}>
                    {productFiles.pds!==""&&<ViewThumbFiles
                bin="pds"
                deletef={true}
                productFiles={productFiles}
                setProductFiles={setProductFiles}
                clickable={false}
                selected={selected}
                setSelected={setSelected}
                />}
                </div>
                
            </FileDrop>
            <FileDrop>
                <AddGetFiles productFiles={productFiles} setInputFiles={setProductFiles} bin="sds" tag="Get SDS" small validTypes={validTypes}/>
                <div style={{marginTop:"3%",marginBottom:"5%"}}>
                 {productFiles.sds!==""&&<ViewThumbFiles
                bin="sds"
                deletef={true}
                productFiles={productFiles}
                setProductFiles={setProductFiles}
                clickable={false}
                selected={selected}
                setSelected={setSelected}
                />}   
                </div>
                
            </FileDrop>
            <FileDrop>
                <AddGetFiles productFiles={productFiles} setInputFiles={setProductFiles} bin="images" tag="Get Image(s)" small validTypes={validImageTypes}/>
                <div style={{marginTop:"3%",marginBottom:"15%"}}>
                 {productFiles.images.length>0&&<ViewThumbFiles
                bin="images"
                deletef={true}
                productFiles={productFiles}
                setProductFiles={setProductFiles}
                clickable={false}
                selected={selected}
                setSelected={setSelected}
                />}   
                </div>
                
            </FileDrop>
        </FileContainer>
    )
}

export default AddFiles
