import React from 'react'
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormGroup from '@material-ui/core/FormGroup'
import Checkbox from '@material-ui/core/Checkbox'
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import {useStyles} from './AddProductStyles'
const AddPackage = (props) => {
   const {packing,setPacking}=props
   console.log("packing:",packing) 
    const packArray=Object.entries(packing)
    console.log("packArray:",packArray)
    const handleChange=(event)=>{
        setPacking({...packing,[event.target.name]:event.target.checked})
    }
    const classes=useStyles() 
   return (
        <div className={classes.root}>
            <AppBar position="static" >
            <Toolbar>
            <span className="addProduct_tag" >Packing:</span>
                <FormGroup row>
                {packArray.map((pack,index)=>{
                return (<FormControlLabel
                    key={"control"+index}
                    control={<Checkbox
                    key={"check"+index}
                    checked={pack[1]}
                    onChange={handleChange}
                    name={pack[0]}
                    color="primary"
                    />}label={pack[0]} className="addProduct_tag"/>
                    
                )
            })}
            </FormGroup>
            </Toolbar>
                
            </AppBar>
            
            
        </div>
    )
}

export default AddPackage
