import produce from 'immer'
export const initialState ={
    basket:[],
    store:{
        products:{
        Base_Oils:[],
        Additives:[],
        Lubricants:[],
        Greases:[],
        DEF:[],
        Refrigerants:[],
        Hardware:[]},
            },
    user:null,
    role:"",
    lang:"eng",
    tag:"All",
    
    media:{
        products:{family_images:null}
    }
    }


const reducer = (state=initialState,action) =>
produce ( state,draft=>{
        switch (action.type) {
        case 'SET_USER':
        draft.user=action.user    
        break;
        case 'SET_USER_ROLE':
        draft.role=action.role    
        break;
        case 'SET_STORE':
        draft.store=action.store    
        break;
        case 'ADD_TO_BASKET':
        draft.basket.push(action.item)  
        break;
        case 'REMOVE_FROM_BASKET':
            
            const index=draft.basket.findIndex((basketItem)=>basketItem.id===action.id)
            if (index>=0){
                draft.basket.splice(index,1)
            } else { 
                console.warn(
                    `Can't remove product (id: ${action.id} as it was not found)`
                )
            }
        break
        case 'SET_LANG':
            if(draft.lang==="eng"){
                draft.lang="esp"
            } else {
                draft.lang="eng"
            }
            break
        case 'SET_TAG':
            draft.tag=action.tag
            break
        
        case 'PRODUCTS_FAMILY_IMAGES':
            draft.media.products.family_images=action.docs
            
            break
        default:
        return
                            }})

export default reducer