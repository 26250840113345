import React from 'react'
import {Link,useRouteMatch} from 'react-router-dom'
import {CardContainer} from './MainCard.elements'

function ProductCard(props){
    const{url}=useRouteMatch()
    
    return (
        <CardContainer {...props} >
            
           
                <Link className="img" to={`${url}/${props.id}`}>
                    
                        
                        
                            <img src={props.url} alt={props.alt}/>
                        
                        
                  
                </Link> 
                <Link className="txt" to={`${url}/${props.id}`}>           
                              
                                    <h3 > { props.name }  </h3>
                                                     
                </Link>    
                  
            
               
        </CardContainer>
    )
}

export default ProductCard