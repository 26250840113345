import styled,{css} from 'styled-components'

 const MenuContainer=styled.div`


${props => props.device.mobile&&css`
    background-color:white;
    border: 3px solid #d3d4d5;
    width:150px;
    
    position:absolute;
    top:32px;
    z-index:22;
    border-radius:10px;
    overflow:hidden;
    font-size:14px;
   
`}
${props=>props.device.tablet&&css`
background-color:white;
    
    border: 3px solid #d3d4d5;
    width:150px;
    position:absolute;
    top:50px;
    z-index:22;
    border-radius:10px;
    overflow:hidden;
    font-size:14px;
    


`}
${props=>props.device.desktop&&css`
    background-color:white;
    
    border: 3px solid #d3d4d5;
    width:150px;
    
    position:absolute;
    top:40px;
    z-index:22;
    border-radius:10px;
    overflow:hidden;
    font-size:14px;
    
`}
${props=>props.device.laptop&&css`
background-color:white;
    
    border: 3px solid #d3d4d5;
    width:150px;
    
    position:absolute;
    top:40px;
    z-index:22;
    border-radius:10px;
    overflow:hidden;
    font-size:14px;
   
`}

 `
 export const  MenuLinkP=styled.p`
 margin:5px 0 5px 8px;
 white-space:nowrap;
 ::after{
     content:'';
     border: 1px solid 'var(--secondary)';
     display:block;
     
     }
 
 :hover {
     white-space:nowrap;
     cursor:pointer;
     transition:0.5s ease-all in;
      ::before{
          color:white;
     content:'◾';
     
      
     }
      ::after{
     content:'';
     border: 1px solid rgba(0,0,0,0.5);
     display:block;
     background-color:var(--secondary);
      
     }
  
     
    }

 `

 export default MenuContainer