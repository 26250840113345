import React,{useEffect,useState} from 'react'
import { makeStyles } from '@material-ui/core/styles';
import {db} from '../firebase'

import {productsDB} from '../utilities'

import DisplayFixedMedia from './DisplayFixedMedia';
const useStyles=makeStyles({
    root:{
        paddingLeft:'300px',
        width:'100%'
    },
    

})



const DisplayFamilyImages = ({selected,families}) => {
const name=productsDB[productsDB.map(n=>n.collection).indexOf(selected[0])].name    
const [images,setImages]=useState(null)


function getFamilyImages (collection,goget) {
    
    if (goget){
        
    db.collection("Family_images").doc(collection).get().then(
            doc=>setImages(doc.data())).catch(e=>console.error(e))
    } else return
    
    }
    useEffect(()=>{
        getFamilyImages(selected[0],families)
        
      
    },[selected])

    useEffect(()=>{
        if (images) {console.log(images)
        
    }
    },[images])
    
    const classes=useStyles()
    
    
    return (
        <div className={classes.root} >
            {families?images&&<DisplayFixedMedia 
            collection={selected[0]} 
            selected={selected[1]} 
            images={images}
            name={name} 
            
            />:
            <h1 style={{textAlign:"center",fontSize:"22px",fontWeight:"800",color:"var(--error)",paddingTop:"25%"}}>no Images to display</h1>}
            
        </div>
    )
}

export default DisplayFamilyImages
