import React,{useState} from 'react'
import {MyProfileContainer,Backdrop} from './MyProfile.elements'
const MyProfile = (props) => {
    
    const handleClick=(e)=>{
        if( e.target.classList.contains('backdrop')){      
        props.setShowMyProfile(false)
        props.setAnchorEl(false)}
    }
    const [permitView,setPermitView]=useState(true)
    
    
    return (
       <Backdrop 
       onClick={handleClick}
       className="backdrop">
        <MyProfileContainer device={props.device}>
            Account: {props.user.displayName} <br/>
            e-mail: {props.user.email} <br/>
            {permitView&&<>modify / add user</>}
            {!permitView&&<>send e-mail to confirm</>}
            
        {permitView&&<button
        onClick={()=>setPermitView(!permitView)} 
        className="myprofile__button">👁 </button>}
            
        {!permitView&&<button 
        className="myprofile__button">🖊 </button>}
            
                
        </MyProfileContainer>
        </Backdrop>
    )
}

export default MyProfile
