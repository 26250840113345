import React from 'react'
import {Container,ProvidersList,ProvidersButton} from './FirebaseUi.elements'
import {useHistory} from 'react-router-dom'

const FirebaseUi = (props) => {
 const history=useHistory()
    const handleClick=(e)=>{
    if (e.target!==e.currentTarget) {
    props.setPasswordAuth(e.target.id==="email")
    props.setGoogle(e.target.id==="google")
    props.setFacebook(e.target.id==="facebook")
    props.setSelectProvider(!props.selectProvider)
    }
    e.stopPropagation()
 }   
  const backClick=()=>history.goBack()  
    
  
  
  return (
        
        <Container  {...props}>
        <ProvidersList onClick={handleClick} {...props}>


            <li >
                <ProvidersButton id="email"  {...props}>
                        <span id="email" className="firebaseui__icon-wrapper">
                        <img className="firebaseui-idp-icon" alt="password" src="https://www.gstatic.com/firebasejs/ui/2.0.0/images/auth/mail.svg"/>
                        </span>
                        <span id="email" className="firebaseui__text-long">Sign in with email</span>
                        <span id="email" className="firebaseui__text-short">Email</span>
                </ProvidersButton>
            </li>
             <li id="google">
                   <ProvidersButton id="google" onClick={handleClick} {...props}>
                        <span id="google" className="firebaseui__icon-wrapper">
                        <img id="google" className="firebaseui-idp-icon" alt="gmail" src="https://www.gstatic.com/firebasejs/ui/2.0.0/images/auth/google.svg"/>
                        </span>
                        <span id="google" className="firebaseui__text-long">Sign in with Google</span>
                        <span id="google" className="firebaseui__text-short">Google</span>
                    </ProvidersButton> 
            </li>
             <li id="facebook">
                    <ProvidersButton id="facebook" onClick={handleClick} {...props}>
                        <span id="facebook" className="firebaseui__icon-wrapper">
                        <img id="facebook" className="firebaseui-idp-icon" alt="facebook" src="https://www.gstatic.com/firebasejs/ui/2.0.0/images/auth/facebook.svg"/>
                        </span>
                        <span id="facebook" className="firebaseui__text-long">Sign in with Facebook</span>
                        <span id="facebook" className="firebaseui__text-short">Facebook</span>
                    </ProvidersButton>
            </li>

        </ProvidersList>
        <br/>
        <br/>
        <br/>
        <button onClick={backClick}>🔙 </button>
        </Container>
    )
}

export default FirebaseUi
