import styled,{css} from 'styled-components'


export const GContainer=styled.button`

${props=>props.device.mobile&&css`
width:60px;
height:20px;
+#Gtag{font-size:10px;
text-align:center;}
`}
${props=>props.device.tablet&&css`
width:90px;
height:30px;
+#Gtag{font-size:12px;
text-align:center;}
`}
${props=>props.device.desktop&&css`
width:100px;
height:33px;
&:focus {
    
    outline:none;
  }

+#Gtag{font-size:26px;
fill:rgb(0,0,0,.8);
text-align:center;}

`}
${props=>props.device.laptop&&css`
width:120px;
height:40;
&:focus {
    
    outline:none;
  }
+#Gtag{font-size:18px;
text-align:center;}

`}

`

  