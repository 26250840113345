import styled from 'styled-components'

export const FileContainer=styled.div`
display:flex;
flex-direction:column;
align-items:center;
justify-content:space-evenly;
width:${p=>p.small?'15vw':p.medium?'25':'33vw'};
height:${p=>p.small?'12vh':p.medium?'18vh':'25vh'};
text-align:center;
background-color:${p=>p.highlighted?"rgba(0,255,105,0.3)":"rgba(200,180,45,.3)"};
border:${p=>p.highlighted?"2px solid green":"undefined"};


`