import React from 'react'

import {Container} from './Aboutus.elements'
import {useDevice} from '../hooks'
import {aboutUsTxt} from './useVariables'
const Aboutus=(props)=>{
const device=useDevice()

    return(
       
       
          <Container device={device}>
 
     <h1>About us</h1>
     <p>{aboutUsTxt}</p>

        </Container>  
       
       
    )
} 
export default Aboutus