import {auth,googleAuthProvider,facebookAuthProvider} from '../firebase'



export const GoogleAuth = (device) => {
   const provider=googleAuthProvider
   
   
   
    auth.getRedirectResult()
        // ...
      
      
      // Start a sign in process for an unauthenticated user.
        provider.addScope('profile')
        provider.addScope('email')
      !device.mobile&&auth.signInWithPopup(provider)
      device.mobile&&auth.signInWithRedirect(provider)
   
        
    
}
export const FacebookAuth = (device) => {
   const provider=facebookAuthProvider
   
   
   
    // Sign in using a redirect.
auth.getRedirectResult().then(function(result) {
    if (result.credential) {
      // This gives you a Google Access Token.
      var token = result.credential.accessToken;
    }
    var user = result.user;
  }).catch(e=>alert(e.message))
  // Start a sign in process for an unauthenticated user.
  
  !device.mobile&&auth.signInWithPopup(provider)
      device.mobile&&auth.signInWithRedirect(provider)
   
        
    
}

