import styled,{css} from 'styled-components'
import SideBG from '../images/sidemenu.jpg'
import Pointer from '../images/svg/oildroplet_1stmenu.svg'


export const SideContainer=styled.div`
${props=>props.device.mobile&&css`
padding:2em;
color:white;
background-image:url(${SideBG});
position:absolute;
width:45%;
max-width:400px;
height:50%;
top:102px;
left:0px;
z-index:20;
border-bottom-right-radius:15px;
h3{
font-size:16px;
font-weight:900;

margin-bottom:25px;
padding-left: 20px; 
        :hover{
    
    //background-color:var(--primary);
    transition:0.3s ease-in all;
   white-space:nowrap;  
    
    
   
    
    ::before{
        
         margin-bottom:-10px;
        color:var(--primary);
        display:block;
        transform:translate(-110%,60%);
  content: '';
  
  background-image: url(${Pointer});
  background-size: contain;
  background-repeat: no-repeat;
  height: 16px;
  width: 16px;
    }

   
    
    
    }
}
`}
${props=>props.device.tablet&&css`
width:25%;
max-width:650px;
padding:2em;
color:white;
background-image:url(${SideBG});
position:absolute;
top:150px;
left:0;
z-index:20;
font-size:24px;
font-weight:900;

h3{:hover{
    white-space:nowrap;
    background-color:var(--primary) ;
    ::before{
        color:var(--primary);
    content:'🔸' ;
    }
    }
`}
`
