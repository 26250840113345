import React from 'react'
import {ReactComponent as Mmenuclosesvg} from '../images/svg/Mmenuclosesvg.svg'
import {CustomsizeIcon,MenuCloseContainer} from './MmenuClose.elements'

export default function Mmenuclose(props) {



    return (
        
    <MenuCloseContainer {...props} >       
        
            <CustomsizeIcon {...props} viewBox="0 0 59 41">      
                <Mmenuclosesvg/>
            </CustomsizeIcon>
       
    </MenuCloseContainer>    
                
    )
}

