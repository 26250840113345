import React,{useEffect} from 'react'
import {useStorage} from '../hooks'
import {useAdminStateValue} from '../Admin'

function ProgressBar(props) {
    const [{databasefiles,templatefiles,opencomponent},dispatch]=useAdminStateValue()
   
    
    const {dbcreate,templatesadd}=opencomponent
    
    
    
    const getFileCollection=()=>{
        let filecollection={}
      if ( databasefiles.length>0){
          
          filecollection= {file:databasefiles[0],collection:"databases",folder:"Admin/Database/"}
           return filecollection 
        }
      if (templatefiles.length>0) {
          filecollection={file:templatefiles[0],collection:"templates",folder:'Admin/Templates/'}
            return filecollection
        }
    }
    
    
    const {url,progress}=useStorage(getFileCollection())
    
    useEffect (()=>{
        
        if (url) {
            
          if  (dbcreate) {
             
            dispatch({
              type:'DB_FILES',
              databasefiles:[]
          })
          
        }

            if (templatesadd) dispatch({
                type:'TEMPLATE_FILES',
                templatefiles:[]
            })
    props.setBackFromProgressBar(true)
    }
        
    },[url,dispatch,dbcreate,templatesadd])
    
   
    return (
        <div className="progressbar">
            {progress}
        </div>
    )
}

export default ProgressBar
