import firebase from "firebase/app"
import "firebase/auth"
import "firebase/firestore"
import "firebase/storage"
import "firebase/functions"
//develop in emulator ui mode until build phase
import firebaseConfig from './config'
/*let firebaseConfig={
    databaseURL: 'http://localhost:9000/?ns=txwos-59cb0',
    projectId:'txwos-59cb0',
    apiKey: process.env.REACT_APP_FB_KEY,
}*/


const app=firebase.initializeApp(firebaseConfig)

const storage = app.storage();
const db = app.firestore();
const timestamp = firebase.firestore.FieldValue.serverTimestamp()
const auth= app.auth();
const facebookAuthProvider=new firebase.auth.FacebookAuthProvider()
const myFunctions = firebase.functions()
//const myFunctions = firebase.functions().useEmulator("localhost", 5001)
const googleAuthProvider=new firebase.auth.GoogleAuthProvider()

export {
    storage,
    db,
    timestamp,
    auth,
    googleAuthProvider,
    
    facebookAuthProvider,
    myFunctions};