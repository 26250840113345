import React,{useState,useRef,useEffect} from 'react'
import {useParams} from 'react-router-dom'
import {useStateValue} from '../StateProvider'
import {useDevice} from '../hooks'
import Header from './Header'
import Navbar from './Navbar'
import Footer from './Footer'
import ViewProductCard from './ViewProductCard'

import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(({w,h}) => ({
    root: {
      flexGrow: 1,
      padding:"1%"
    },
    container: {
        marginRight:"2%",
        marginLeft:"2%"
    },
    display:{
       
display:"flex",
alignItems:"center",
justifyContent:'space-between',
padding:"1.5%"
    },
displayRight:{
    paddingLeft:"15px",
    height:h,
    width:`100vw-${w}`,
    display:"flex",
    flexDirection:"column",
    justifyContent:'space-between',
    backgroundColor:"rgba(50,170,230,.3)",
},

    uldisplay:{
        backgroundColor:"rgba(230,170,50,.3)",
        
display:"flex",
flexDirection:"column",
alignSelf:"flex-start",
alignItems:"flex-start",
justifyContent:'space-evenly',
cursor:"pointer",
padding:"10px",
maxWidth:"300px",
'& li':{
    marginTop:"10px",
    marginBottom:"10px",
},

 '& li>p':{
        
    backgroundColor:"var(--primary)",
        color:"#fff",
        borderRadius:"10px",
        padding:"5px",
        fontWeight:500,
        '&:hover':{
            color:"#000",
            backgroundColor:"#fff"
        }
    }

    },
    pagination:{
        backgroundColor:"rgba(50,50,50,.3)",
        alignSelf:"center",
        justifySelf:"flex-end",
        cursor:"pointer",
    }
    
  }))

const SelectedP = () => {
    const viewP=useRef()
    const device=useDevice()
const [pCardDims,setPCardDims]=useState(
    (device.laptop||device.desktop||device.tablet)?{h:'600px',w:'100vw-1200px'}:
    {h:"600px",w:"450px"}
    )
  
const classes=useStyles(pCardDims)    
const {id,selection}=useParams()
const [page,setPage]=useState(0)
const [{store:{products}},dispatch]=useStateValue()

const productsArray=products[id]
.filter(product=>product.family===selection)
productsArray.sort((a,b)=>{
    
    if (a.code<b.code) {return -1}
    if (a.code>b.code) {return 1}
    return 0

})
const [productSelected,setProductSelected]=useState(productsArray[0])
console.log(productsArray)
const pack=(vase)=>{
if (vase==="yes") {return true}
return false
}

const productData=()=>{
const {images,pds,sds,code,product_name,description,flexi,tote,drum,keg,pail,jug5qt,jug1gal,qtbottle,cartridge,id}=productSelected
const productFiles={images,pds,sds}
const packing={flexi:pack(flexi),tote:pack(tote),drum:pack(drum),keg:pack(keg),pail:pack(pail),jug5qt:pack(jug5qt),jug1gal:pack(jug1gal),qtbottle:pack(qtbottle),cartridge:pack(cartridge)}
const pId=id
return {code,product_name,description,productFiles,packing,pId}
}


const remainingProducts=productsArray.filter(p=>p!==productSelected).slice(page,page+4)
const paginate=(productsArray.length>5)&&(!(page+remainingProducts.length+1>=productsArray.length))
const p=productData()
useEffect(()=>{
const {width,height}=viewP.current.getBoundingClientRect()
setPCardDims({h:height,w:width}) 
},[p.pId])

const productsJSX=<div className={classes.display}>
<div ref={viewP}>
<ViewProductCard 
                            key={"product_card"+p.pId} 
                            productFiles={p.productFiles} 
                            code={p.code} 
                            product_name={p.product_name} 
                            description={p.description} 
                            packing={p.packing} 
                            id={p.pId}
                            
                            />
</div>
< div className={classes.displayRight}>
    <ul className={classes.uldisplay}>
        {remainingProducts.map((p,index)=>{return (
        <li key={p.id} onClick={()=>{setProductSelected(productsArray[productsArray.indexOf(remainingProducts[index])])}}><p>{p.product_name}</p></li>
    )}
    )}
    
    
    
    </ul>
    <div className={classes.pagination}>
        {page>0&&<span onClick={()=>setPage(page-4)}> ⬅  </span>}
        {(page||paginate)&&<span> {page+remainingProducts.length+1}/{productsArray.length} </span>}
        {paginate&&<span onClick={()=>setPage(page+4)}> ➡ </span>}
    </div>
    
</ div>
</div>


return (
        <div>
            <Header device={device} />
            <Navbar device={device} />
           
               {productsJSX}
            
           
            <Footer device={device} />
        </div>
    )
}

export default SelectedP
