import styled,{css} from 'styled-components'
import IntroBackgroundImage from '../images/base_oils.jpg'



export const IntroContainer=styled.div`
        
    ${props=>(props.device.mobile||props.device.tablet)&&css`
        height:80vh;
        background-image: 
        linear-gradient(to top, rgba(255,255,255,.7), rgba(255,255,255,0.4)),
        url(${IntroBackgroundImage});
        background-repeat: no-repeat;
        background-attachment: local, scroll;
        background-size: cover;
        display:flex;
        
             
    `}
    ${props=>props.device.desktop&&css`
        height:52vh;
        background-color:white;
        background-image: 
        url(${IntroBackgroundImage});
        background-size:contain;
        background-repeat: no-repeat;
        background-attachment: local, scroll;
        background-position:left 100% bottom 50%;
        display:flex;
       align-items:center;
       justify-content:center;
       
    `}
    ${props=>props.device.laptop&&css`
        height:60vh;
        background-color:white;
        background-image: 
        url(${IntroBackgroundImage});
        background-size:contain;
        background-repeat: no-repeat;
        background-attachment: local, scroll;
        background-position:left 100% bottom 50%;
        display:flex;
       align-items:center;
       justify-content:center;

    `}
    .intro__2c{
    display:flex;
    height:40vh;
    align-self:center;
    margin-right:6%;
    .intro__3rdrow__button1{
        align-self:flex-end;
        opacity:0.7;
        &:hover {
  border-radius:15px;
  transform:scale(1.03);
  opacity:1;
}
  &:active {
    box-shadow: 0 5px rgba(244,180,0,0.5);
    transform: translateY(4px);
    border-radius:15px;
    
    
  }
  &:focus {
    border: none;
    outline:none;
  }
    }
    .intro__3rdrow__button2{
        align-self:center;
        opacity:0.7;
        &:hover {
  border-radius:15px;
  transform:scale(1.03);
  opacity:1;
}
  &:active {
    box-shadow: 0 5px rgba(244,180,0,0.5);
    transform: translateY(4px);
    border-radius:15px;
    
    
  }
  &:focus {
    border: none;
    outline:none;
  }
    }
    .intro__3rdrow__button3{
        align-self:flex-start;
        opacity:0.7;
        &:hover {
  border-radius:15px;
  transform:scale(1.03);
  opacity:1;
}
  &:active {
    box-shadow: 0 5px rgba(244,180,0,0.5);
    transform: translateY(4px);
    border-radius:15px;
    
    
  }
  &:focus {
    border: none;
    outline:none;
  }
    }
}


.intro__1c{
    ${props=>(props.device.mobile||props.device.tablet)&&css`
       
        display:flex;
        flex-direction:column;
        align-items:center;
        margin-top:5%;
        justify-content:space-around;
        #intro__map{
            
            &:hover{
                transform:scale(1.1);
            }
        }
    `}
    
    ${props=>props.device.desktop&&css`
        width:80%;
        height:50vh;
        display:flex;
        flex-direction:column;
        align-items:center;
        margin-top:3%;
        justify-content:space-around;
        position: relative;
        #intro__map{
            position:absolute;
            top:10%;
            z-index:20;
            &:{active:outline:none;}
            &:hover{
                transform:scale(1.1);
                
            }
        }
        .intro__card{

           
        }     
    `
    }
    ${props=>props.device.laptop&&css`
    width:80%;
        height:60vh;
        display:flex;
        flex-direction:column;
        align-items:center;
        margin-top:1%;
        justify-content:center;
        position: relative;
        #intro__map{
            position:absolute;
            top:25%;
            z-index:20;
            &:hover{
                transform:scale(1.1);
            }
        }
        .intro__card{
            
           
        }  
        
        `}
}
`

