import React,{useState} from 'react'
import {productsDB} from '../utilities'
import { PageContainer} from './Dashboard.elements'
import DisplayFamilyImages from './DisplayFamilyImages'
import UseDash from './UseDash'



const FamilyImages = (props) => {

    
    const productsE=[]
productsDB.forEach((product)=>{
    if (product.collection!==""){
productsE.push([product.collection,product.orderBy])
    }
})
const [selected,setSelected]=useState(productsE[0])    
const families=selected[1].length>1
console.log(families)
return (
        <PageContainer>
            <UseDash productEntries={productsE}
        selected={selected}
        setSelected={setSelected}
        lockSelection={true}
        {...props}
        />
        <DisplayFamilyImages selected={selected} families={families}/>
        </PageContainer>
    )
}

export default FamilyImages
