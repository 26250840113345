const INTRO_HEADER="TEXAS WORLDWIDE OIL SERVICES";
const INTRO_P="We strive to create the best experience for our clients in the process of purchasing base oils, lubricants, additives, refrigerants, greases, hardware and many more, managing the supply chain all the way to the warehouse.";
const ADDRESS1="6300 N Main St., Suite 206";
const ADDRESS2="Houston, TX 77009";
const PHONE="Phone +1 (713) 309 6637";
const SEARCH_TAGS=["ALL","BO","ADD","LUB","DEF","REF","Hard","OEM"];
const PRODUCT_FAMILY=["","Base Oils","Additives","Lubricants","DEF","Refrigerants","Hardware","Third Party Labeling"];
const INFO_EMAIL="info@txwos.com";
const SALES_EMAIL="sales@txwos.com";
const aboutUsTxt=`Texas Worldwide Oil Services LLC (TxWOS) is a privately owned corporation with headquarters in Houston, Texas. 
TxWOS commercializes and distributes oil bases, additives and lubricants in South East Texas, Central America, the Caribbean and South America.
Our product line includes a full range of high-quality lubricants with applications across various industries. We commercialize and package synthetic and conventional products that include heavy duty engine oil, hydraulic oil, gear oil, transmission fluid, antifreeze, and grease, among others. We offer the full line of products in various sizes, including flexitanks, totes, drums, gallons, and quarts. 
`
const BOTXT='From the best refineries in Texas and Lousiana, we are able to pack certified base oils, group II, in drums, totes, and flexitanks.'
export {aboutUsTxt,
    INTRO_HEADER,
    INTRO_P,
    ADDRESS1,
    ADDRESS2,
    PHONE,
    SEARCH_TAGS,
    PRODUCT_FAMILY,
    INFO_EMAIL,
    SALES_EMAIL,
    BOTXT}