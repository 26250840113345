import styled,{css} from 'styled-components'


export const FooterContainer=styled.div`
border-bottom: 3px solid var(--secondary);
border-top: 3px solid var(--secondary);
background-color:var(--primary);
z-index:5;
position:fixed;
bottom:0;
left:0;
${props=>props.device.mobile&&css`
width:100%;
height:70px;
display:flex;
flex-direction:column;
justify-content:center;
align-items:space-around;

.footer__1stline{
    height:40px;
    display:flex;
    flex-direction:row;
    align-items:center;
    justify-content:space-around;
    padding:10px;
    .footer__user{
        cursor:pointer;
    }
    .footer__accounticon{
        color:white;
        position:relative;
    }
    .footer__accounttext{
        font-size:12px;
        font-weight:500;
    }
    .footer__selection{
        display:flex;
        flex-direction:row;
        align-items:center;
        justify-content:center;
        .footer__basket{
            color:white;
            margin-right: 10px;
            .footer__basketicon{
                width:18px;
            }
        }
        .footer__selectioncount{
            font-size:12px;
        }
    }
    }
span{
    border-top:1px solid grey;
    text-align:center;
    font-size:10px;
    width:100%;
    padding: 5px;    
}
`}
${props=>props.device.tablet&&css`
text-align:center;
font-size:12px;
width:100%;
height:25px;

`}
${props=>(props.device.desktop||props.device.laptop)&&css`
text-align:center;
font-size:16px;
width:100%;
height:25px;

`}

` 