import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardMedia from '@material-ui/core/CardMedia';


import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit'



const useStyles = makeStyles(() => ({
  root: {
    maxWidth: 600,
    minWidth:250,
    borderBottom: 'var(--primary) 10px solid',
    borderBottomLeftRadius:"15px",
    borderBottomRightRadius:"15px"
  },
  media: {
    height: 0,
    paddingTop: '56.25%', // 16:9
    
  },
  
    
 
 
}));

export default function DisplayFixedMediaCard(props) {
  const classes = useStyles();
  const {family,url,alt,title,subheader,onEditClick}=props

  

  return (
    <Card className={classes.root}>
      <CardHeader
        
        action={
          <IconButton  aria-label="settings" onClick={(e)=>onEditClick(e,family)}>
            <EditIcon  />
          </IconButton>
        }
        title={title}
        subheader={subheader}
      />
      
        <CardMedia
        className={classes.media}
        
        image={url}
        title={alt}
      />
      
      
      
      
      
    </Card>
  );
}