import React,{useState} from 'react'
import {CardContainer} from './ProductCard.elements'
import PackingInfo from './PackingInfo'
import TextInfo from './TextInfo'

import ViewPDS_SDS from './ViewPDS_SDS'
import ViewThumb from './ViewThumb'
import DefaultMainPic from '../images/sidemenu.jpg'
const Product_Card = (props) => {
    const [select,setSelect]=useState(0)
    const imagesbool=Array.isArray(props.images)&&props.images.length>0
    let urlArray=[]
    if (imagesbool){
    
    
    props.images.forEach(image=>{
        
        const inDB=typeof(image)==='string'&&image.startsWith('https://firebasestorage')
        inDB&&urlArray.push(image)
        !inDB&&urlArray.push(URL.createObjectURL(image))
    })}

    return (
        <CardContainer>
          
           <div className="ThumbContainer">
                    {imagesbool?<ViewThumb source="images" uploading={props.uploading} deleting={props.deleting} select={select} setSelect={setSelect} clickable={true}/>:
                    <p>No images</p>}
                </div>
                <div className="MainPicture">
    <img src={imagesbool?urlArray[select]:DefaultMainPic} alt="main picture"/>
                </div>
            <div className="CodeInfo">
                <span >code:</span><br/><span >{props.code}</span>
                </div>
                <div className="PDFInfo">
                    <ViewPDS_SDS {...props}/>
                </div>
            
            <div className="TextInfo"><TextInfo {...props}/></div>
            <div className="PackInfo" style={{alignSelf:"center"}}>
            <PackingInfo {...props}/>
            </div>
            
            
        </CardContainer>
    )
}

export default Product_Card
