import React from 'react'
import { Keyframes,animated} from 'react-spring/renderprops'

import {GContainer} from './Agb.elements'
import {useDelay} from '../hooks/Utilities'


export default  function Agb(props){
  



    
const show=useDelay({time:8000})    
        const Content1 = Keyframes.Spring(async next => {
        // None of this will cause React to render, the component renders only once :-)
        while (!show) {
          await next({
            from: { stroke:'#f9bc15' },
            stroke:'#547dbf',
          })
          await next({
            from: { stroke:'#547dbf' },
            stroke:"#ea4435",
            
          })
          await next({
            from: { stroke:"#ea4435" },
            stroke:"#33a852",
          })
          await next({
            from: { stroke:"#33a852" },
            stroke:'#f9bc15',
            
          })
            
        }
      })
      const Content2 = Keyframes.Spring(async next => {
        // None of this will cause React to render, the component renders only once :-)
        while (!show) {
          await next({
            from: { stroke:'#547dbf' },
            stroke:"#ea4435",
          })
          await next({
            from: { stroke:"#ea4435" },
            stroke:"#33a852",
            
          })
          await next({
            from: { stroke:"#33a852" },
            stroke:'#f9bc15',
          })
          await next({
            from: { stroke:'#f9bc15' },
            stroke:'#547dbf',
            
          })
            
        }
      })
      const Content3 = Keyframes.Spring(async next => {
        // None of this will cause React to render, the component renders only once :-)
        while (!show) {
          await next({
            from: { stroke:"#ea4435" },
            stroke:"#33a852",
          })
          await next({
            from: { stroke:"#33a852" },
            stroke:'#f9bc15',
            
          })
          await next({
            from: { stroke:'#f9bc15' },
            stroke:'#547dbf',
          })
          await next({
            from: { stroke:'#547dbf' },
            stroke:"#ea4435",
            
          })
            
        }
      })
      const Content4 = Keyframes.Spring(async next => {
        // None of this will cause React to render, the component renders only once :-)
        while (!show) {
          await next({
            from: { stroke:"#33a852" },
            stroke:'#f9bc15',
          })
          await next({
            from: { stroke:'#f9bc15' },
            stroke:'#547dbf',
            
          })
          await next({
            from: { stroke:'#547dbf' },
            stroke:"#ea4435",
          })
          await next({
            from: { stroke:"#ea4435" },
            stroke:"#33a852",
            
          })
            
        }
      })

    const tag=props.tag 
    return(
        <GContainer {...props}>
            
                 <svg viewBox="0 0 109 41" >
                 <defs>
                   <clipPath id="clipPath">
                     <rect id="Rectangle_6" data-name="Rectangle 6" width="109" height="41" fill="#fff" stroke="#707070" strokeWidth="1"/>
                   </clipPath>
                 </defs>
                 <g id="Component_1_1" data-name="Component 1 – 1">
                   <g id="Mask_Group_2" data-name="Mask Group 2" clipPath="url(#clipPath)">
                     <g id="buttons" transform="translate(0.96 1.026)">
                     
                     {(show)&&<path id="Path_13" data-name="Path 13" d="M67.5,159.513v9.968s.166,9.506,11.612,9.506H121.3" transform="translate(-67.499 -140.039)" fill="none" stroke="#547dbf" strokeLinecap="round" strokeLinejoin="round" strokeWidth="3.383"/>}
                     {(show)&&<path id="Path_14" data-name="Path 14" d="M215.155,159.513v9.968s-.166,9.506-11.606,9.506H161.363" transform="translate(-108.076 -140.039)" fill="none" stroke="#f9bc15" strokeLinecap="round" strokeLinejoin="round" strokeWidth="3.383"/>}
                     {(show)&&<path id="Path_15" data-name="Path 15" d="M67.5,140.039v-9.974s.166-9.5,11.606-9.5h42.187" transform="translate(-67.499 -120.565)" fill="none" stroke="#ea4435" strokeLinecap="round" strokeLinejoin="round" strokeWidth="3.383"/>}
                     {(show)&&<path id="Path_16" data-name="Path 16" d="M215.155,140.039v-9.974s-.166-9.5-11.606-9.5H161.363" transform="translate(-108.076 -120.565)" fill="none" stroke="#33a852" strokeLinecap="round" strokeLinejoin="round" strokeWidth="3.383"/>}
                     
                     
            {(!show)&&<Content2 native >
            {props => ( 
                       <animated.path id="Path_13" data-name="Path 13" d="M67.5,159.513v9.968s.166,9.506,11.612,9.506H121.3" transform="translate(-67.499 -140.039)" fill="none" stroke={props.stroke} strokeLinecap="round" strokeLinejoin="round" strokeWidth="3.383"/>
            )}      
               </Content2>}

            {(!show)&&<Content1 native >
            {props => ( 
                       <animated.path id="Path_14" data-name="Path 14" d="M215.155,159.513v9.968s-.166,9.506-11.606,9.506H161.363" transform="translate(-108.076 -140.039)" fill="none" stroke={props.stroke} strokeLinecap="round" strokeLinejoin="round" strokeWidth="3.383"/>
                       )}      
                       </Content1>}         
            {(!show)&&<Content3 native >
            {props => (            
                       <animated.path id="Path_15" data-name="Path 15" d="M67.5,140.039v-9.974s.166-9.5,11.606-9.5h42.187" transform="translate(-67.499 -120.565)" fill="none" stroke={props.stroke} strokeLinecap="round" strokeLinejoin="round" strokeWidth="3.383"/>
                       )}      
                       </Content3>}             
            {(!show)&&<Content4 native >
            {props => (                      
                       <animated.path id="Path_16" data-name="Path 16" d="M215.155,140.039v-9.974s-.166-9.5-11.606-9.5H161.363" transform="translate(-108.076 -120.565)" fill="none" stroke={props.stroke} strokeLinecap="round" strokeLinejoin="round" strokeWidth="3.383"/>
                       )}      
                       </Content4>}
                        
                     </g>
                   </g>
                 </g>
                   <text id="Gtag" transform="translate(55 29)" fill="rbg(0,0,0,.8)"  fontFamily="Roboto-Regular, Roboto"><tspan x="-38%" y="0">{tag}</tspan></text>
               </svg>

          
              
            
        
      </GContainer>
    )
}


