import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import DefaultImg from '../images/sidemenu.jpg'
import Card from '@material-ui/core/Card';
import CardMedia from '@material-ui/core/CardMedia';
import CardActionArea from '@material-ui/core/CardActionArea'
import CardContent from '@material-ui/core/CardContent'
import Typography from '@material-ui/core/Typography'


const useStyles = makeStyles(() => ({
  root: {
    maxWidth: 300,
   
    borderBottom: 'var(--primary) 10px solid',
    borderRadius:"15px",
    
  },
  media: {
    height: 0,
    paddingTop: '56.25%', // 16:9
  },
  
 
 
}));

export default function MediaCard({url=DefaultImg,title}) {
  const classes = useStyles();
  

  

  return (
    <Card className={classes.root} >
    <CardActionArea >
     <CardContent>
      <Typography align='center' gutterBottom variant="h5" component="h2">
      {title}
      </Typography>
      </CardContent>
        <CardMedia
        className={classes.media}
        
        image={url}
        
        />
     
      
      
      </CardActionArea>
      
    </Card>
  );
}