import React from 'react'
import {useStyles} from './AddProductStyles'
import TextField from '@material-ui/core/TextField'
const AddCND = ({handleChange,conade}) => {
    const classes=useStyles()     
    return (
        <>
            <div className={classes.root} >
              <div className="Conade" >
          
          <TextField
            id="code_field_update"
            label="Required"
            required
            helperText="code"
            name="code"
            style={{ margin: 8,width: 180 }}
            margin="normal"
            InputLabelProps={{
              shrink: true,
            }}
            
            value={conade.code}
            onChange={handleChange}
          />
          <TextField
            id="add_family"
            label="Family"       
            name="family"
            style={{ margin: 8,width: 250 }}
            margin="normal"
            InputLabelProps={{
              shrink: true,
            }}
            
            value={conade.family}
            onChange={handleChange}
          />
          <TextField
            id="name_field"
            label="Required"
            required
            helperText="Product name"
            name="product_name"
            style={{width:"67.5%"}}
            
            
            margin="normal"
            InputLabelProps={{
              shrink: true,
            }}
            value={conade.product_name}
            
            onChange={handleChange}
          />   
   
      </div >

        <TextField
            
            id="standard_description"
            label="product description"
            name="description"
            style={{ margin: 8,flex:1,width: '98%' }}
            
            fullWidth
            
            multiline
            rowsMax={4}
            margin="normal"
            InputLabelProps={{
              shrink: true,
            }}
            value={conade.description}
            
            onChange={handleChange}
          />  
            </div>
        </>
    )
}

export default AddCND
