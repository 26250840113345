import React,{useState} from 'react'
import {Container} from './ExecuteDisplay.elements'
import {useExecutableItems} from '../hooks'
import {useAdminStateValue} from '../Admin'
import ProgressBar from './ProgressBar'
import SetDatabase from './SetDatabase'

const ExecuteDisplay = () => {
const [{databasefiles,templatefiles,opencomponent,updateDash},dispatch]=useAdminStateValue()

const display=!Object.values(opencomponent).some(value=>value===true)
const [backFromProgressBar,setBackFromProgressBar]=useState(false)
const handleBackClick=()=>{
    
    dispatch({
       type:'INITIALIZE'})
   
    }
const executeTask=useExecutableItems()
const isfiles=(databasefiles.length>0||templatefiles.length>0)




return (
        <Container>
            {display&&executeTask}
            
            {isfiles&&!display&&opencomponent.dbcreate&&<ProgressBar
            backFromProgressBar={backFromProgressBar}
            setBackFromProgressBar={setBackFromProgressBar}/>}
            {!isfiles&&opencomponent.dbcreate&&!display&&<div>Database Stored Successfully!</div>}          
            {backFromProgressBar&&opencomponent.dbcreate&&<SetDatabase/>}          
            
       { updateDash&&<button
        onClick={handleBackClick}
        style={{
            position:"fixed",
            bottom:"40px",
            left:"50vw",
            fontSize:"50px",
            border:"1px solid var(--primary)",
            borderRadius:"5px",
            boxShadow:"5px 3px 3px rgba(0,0,0,.3) ,10px 5px 5px var(--secondary)"}}>
            <span role="button" aria-label="back-button">🔙</span>
        </button>}
        </Container>
    )
}

export default ExecuteDisplay
