import React,{useState} from 'react'
import {useHistory} from 'react-router-dom'
import { Container, EmailLogin } from './EmailAuthProvider.elements'
import {auth} from '../firebase'
import {FormControl,Input,InputLabel,FormHelperText} from '@material-ui/core'

const EmailAuthProvider = (props) => {
    const history=useHistory()
    const handleClick = function() {
        history.goBack()
    }  
    const [email,setEmail]=useState("")
    const [password,setPassword]=useState("")

const login=event=>{
  event.preventDefault()
  props.setLoading(true)
  auth.signInWithEmailAndPassword(email,password)
  .then((result)=>{result.user&&props.setLoading(false)
      
  })
  .catch(e=>{
    props.setLoading(false)  
    alert(e.message)
    })
}
const register=event=>{
  event.preventDefault()
  props.setLoading(true)
  auth.createUserWithEmailAndPassword(email,password)
  .then(result=>{result.user&&props.setLoading(false)
      
      })
  .catch((e)=>{
    props.setLoading(false)  
    alert(e.message)
    })
}

    return (
        
        <EmailLogin className="login">
           <Container device={props.device}>
           <h1>Sign in</h1>
           <form>
               <FormControl>
                <InputLabel>📧 e-mail</InputLabel>
                <Input type="email" value={email} onChange={event=>setEmail(event.target.value)} />
               </FormControl>
               <FormControl>
                <InputLabel>🛂  Password</InputLabel>
                <Input type="password" value={password}  onChange={event=>setPassword(event.target.value)} />
               </FormControl>
               <button disabled={!email&&!password} className="login__signin" type="submit" onClick={login}>Sign in</button>
               <FormHelperText>
               By continuing, you agree to Texas Worldwide Oil Services LLC (TxWOS) Conditions of Use and Privacy Notice. 
               </FormHelperText>
               <button disabled={!email&&!password}  className="login__createaccount" type="submit" onClick={register}>Create your TxWOS account</button>
           </form>
           </Container>
           <br/>
            <br/>
            <br/>
            <button className="login__back" onClick={handleClick} area-label="back">🔙</button>
        </EmailLogin>
    )
}

export default EmailAuthProvider
