import React from 'react'
import Checkbox from '@material-ui/core/Checkbox'
import DeleteIcon from '@material-ui/icons/Delete'
import {ThumbContainer} from './ViewThumb.elements'

const ViewThumbFiles = (props) => {
const {bin,deletef,productFiles,setProductFiles,clickable,selected,setSelected}=props
const images=bin==="images"
const pds=bin==="pds"
const sds=bin==="sds"


const handleDelete=(e)=>{
   
    if (images) {
        const deleteFile=e.target.name
        const selectImages=productFiles.images.filter(file=>{return file.name!==deleteFile})
        setProductFiles({...productFiles,images:selectImages})
        return
    }
    setProductFiles({...productFiles,[bin]:""}) 
}   
const handleClick=(e,imageIndex)=>{
    if (!clickable) return
    setSelected(imageIndex)
}
let files
if (images) {files=productFiles.images}
if (pds) {files=productFiles.pds}
if (sds) {files=productFiles.sds}
const filesJSX=files.map((file,index)=>{
const isSelected=clickable?(selected===index):false
const url=URL.createObjectURL(file)
return (
    <li key={file.name+100+index} onClick={images?(e)=>handleClick(e,index):()=>{return}}>
<ThumbContainer clickable={clickable} isSelected={isSelected}>
{images&&<img key={file.name+index} src={url} alt={file.name}/>}
{!images&&<iframe key={file.name+index} src={url} style={{width:"120px"}}/>}
    {deletef&&<div className="checkdiv">
     <Checkbox
                color="secondary"
                icon={<DeleteIcon/>}
                key={"check"+index}
                onChange={handleDelete}
                name={file.name}
                checked={false}
    />
    {file.name}
    </div>}  
       
</ThumbContainer>
    </li>
)
})
    


    return (
        <ul>
            {filesJSX}
        </ul>
    )
}

export default ViewThumbFiles
