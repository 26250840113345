import React from 'react'
import {useDevice} from "../hooks"
import Header from '../components/Header'
import Navbar from '../components/Navbar'
import ContactCard from '../components/ContactCard'


function   Contact(props) {

    const device=useDevice()
    return (
        <div>
            <Header device={device} />
            <Navbar  device={device}/>
           <ContactCard device={device} />
        </div>
    )
}

export default Contact
