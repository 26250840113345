
import {productImages,serviceImages} from '../images'


export const products=[
    {name:'Base Oils',
short:'BO',
url:productImages.tanker,
alt:'Base Oils pic',
goTo:"/products",
collection:'Base_Oils'},
    {name:'Additives',
short:'Add',
url:productImages.additives,
alt:'Additives pic',
goTo:"/products",
collection:'Additives'},
    {name:'Lubricants',
short:'LU',
url:productImages.lubricants,
alt:'Lubricants pic',
goTo:"/products",
collection:'Lubricants'},
    {name:'Greases',
short:'Gr',
url:productImages.grease,
alt:'Greases pic',
goTo:"/products",
collection:'Greases'},
    {name:`Diesel Exhaust Fluid`,
short:'DEF',
url:productImages.defArtboard,
alt:'DEF pic',
goTo:"/products",
collection:'DEF'},
    {name:'Refrigerants',
short:'REF',
url:productImages.refrigerantsArtboard,
alt:'Refrigerant pic',
goTo:"/products",
collection:'Refrigerants'},
    {name:'Hardware',
short:'Har',
url:productImages.Hardware,
alt:'Hardware pic',
goTo:"/products",
collection:'Hardware'}
]
export const productsDB=[
    {name:"Base Oils",
collection:'Base_Oils',
order:1,
orderBy:[{family:"GROUP1",name:"Group 1"},{family:"GROUP2",name:"Group 2"},{family:"GROUP3",name:"Group 3"},{family:"SPECIALTY",name:"Specialty"}]},
{name:"Additives",
collection:"Additives",
order:2,
orderBy:[{family:"ADDITIVES_HDMO",name:"Additives HDMO"},{family:"ADDITIVES_PCMO",name:"Additives PCMO"},{family:"ADDITIVES_GEAROIL",name:"Additives Gear-oil"},{family:"ADDITIVES_HYDRAULICS",name:"Additives Hydraulics"}]},
{name:"Lubricants",
collection:"Lubricants",
order:3,
orderBy:[{family:"Heavy_Duty_Motor_Oils",name:"HDMO"},{family:"Motor_Oils",name:"PCMO"},{family:"Gear_Transmission",name:"Gear/Transmission"},{family:"Hydraulics",name:"Hydraulics"},
{family:"Tractor_Off-Road",name:"Tractor/Off-Road"},{family:"Industrial_Gear_Oils",name:"Industrial Gear Oils"},{family:"Gas_Engine_Oils",name:"Gas Engine Oils (GEO)"},{family:"Specialty",name:"Miscellaneous"}]},
{name:"Greases",
collection:"Greases",
order:4,orderBy:[{family:"na",name:"Greases"}]},
{name:"Diesel Exhaust Fluid",
collection:"DEF",
order:5,orderBy:[{family:"na",name:"DEF"}]},
{name:"Refrigerants",
collection:"Refrigerants",
order:6,orderBy:[{family:"na",name:"Refrigerants"}]},
{name:"Hardware",
collection:"Hardware",
order:7,orderBy:[{family:"na",name:"Hardware"}]},
{name:"Add a Product family",
collection:"",
orderBy:[],
order:8}
]
export const services=[
    {name:'3rd Party label',
short:'OEM',
url:serviceImages.privatelabel,
alt:'3rd party label pic',
goTo:"/services",
collection:'thirdPartyServices'},
    {name:'Logistics',
short:'Log',
url:serviceImages.logistics2,
alt:'Logistics pic',
goTo:"/services",
collection:'logistics'},
    {name:'Lubricant analysis',
short:'Lua',
url:serviceImages.oilanalysis,
alt:'Analysis pic',
goTo:"/services",
collection:'lubricantAnalisys'},
    {name:'Fleet',
short:'Flt',
url:serviceImages.fleet,
alt:'Fleet pic',
goTo:"/services",
collection:'fleet'},
]


export const colors={
    primary:'#f4b400',
    secondary:'#A6A04A',
    error:'#ff4a4a'}

export const mainMenu=["My Profile","My account","Log-out"]
export const myProfile=["e-mail","password"]
export const myAccount=["Browsing history","My Requests","Delete account"]

export const masterDBMenu=[
    {
        mItem:"Create (*Will modify the entire DB)",
        hasSMenu:true,
        sMenu:[
            {mItem:"Upload Master DB File",
            id:"DATABASE_CREATE",
            index:0,

            hasSMenu:false},
        ]
    },
    {
        mItem:"View Database Dashboard",
        id:"DATABASE_VIEW",
        index:1,
        hasSMenu:false,
        
    },
    {
        mItem:"Update",
        hasSMenu:true,
        sMenu:[
            {mItem:"Modify Products",
            id:"DATABASE_UPDATE",
            index:2,
            hasSMenu:false                
            },
           
        ]
    },
    {
        mItem:"Delete/Restore",
        hasSMenu:true,
        sMenu:[
            {mItem:"Delete Products",
            id:"DATABASE_DELETE",
            index:3,
            hasSMenu:false                
            },
            {mItem:"Restore Products",
            id:"DATABASE_RESTORE",
            index:4,
            hasSMenu:false                
            }    

        ]
    },
    {
        mItem:"Add",
        hasSMenu:true,
        sMenu:[
            {mItem:"Add a product",
            id:"DATABASE_ADD",
            index:5,
            hasSMenu:false                
            },
        ]
    },

]
export const usersMenuItems=[
    {mItem:"Get User(s)",
    hasSMenu:true,
    sMenu:[
        {
            mItem:"Set Roles",
            id:"USERS_ROLES",
            index:6,
            hasSMenu:false
        },
        {
            mItem:"View Users",
            id:"USERS_VIEW",
            index:7,
            hasSMenu:false,
            
        }
    ]
    }
]
export const templateMenuItems=[
    {mItem:"Get Template(s)",
    hasSMenu:true,
    sMenu:[
        {
            mItem:"Add Templates",
            id:"TEMPLATES_ADD",
            index:8,
            hasSMenu:false
        },
        {
            mItem:"Update Templates",
            id:"TEMPLATES_UPDATE",
            index:9,
            hasSMenu:false
        },
        {
            mItem:"Delete Templates",
            id:"TEMPLATES_DELETE",
            index:10,
            hasSMenu:false
        },
        {
            mItem:"View Templates",
            id:"TEMPLATES_VIEW",
            index:11,
            hasSMenu:false,
            
        },
        
    ]
    },
    {
        mItem:"Images",
        id:"TEMPLATES_Images",
        hasSMenu:true,
        sMenu:[
            {
                mItem:"Family Images",
                id:"TEMPLATES_Images_Add",
                index:12,
                hasSMenu:false
            },
            {
                mItem:"View Images",
                id:"Template_Images_View",
                index:13,
                hasSMenu:false,
                
            }
        ]
        
    }
]

export const storeInit={
    products:{
        Base_Oils:[],
        Additives:[],
        Lubricants:[],
        Greases:[],
        DEF:[],
        Refrigerants:[]},
    }





