import React from 'react'
import {ReactComponent as DotMatrixsvg} from '../images/svg/dot_matrix.svg'
import CustomsizeIcon from './DotMatrix.elements.js'





export default function DotMatrix(props) {



    return (
        

        <CustomsizeIcon {...props} width="60px" height="42px" viewBox="0 0 46 46">
            <DotMatrixsvg/>
        </CustomsizeIcon>
        
        
    )
}
