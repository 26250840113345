import React from 'react'
import {useAdminStateValue} from '../Admin'
import { useGetAllUsers } from '../hooks'
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import {useStyles} from './SetRowStyles'
import Badge from '@material-ui/core/Badge'
import SupervisedUserCircleIcon from '@material-ui/icons/SupervisedUserCircle';

import SetUserRolesDisplay from './SetUserRolesDisplay';


const SetUserRole = ({tag}) => {



    console.time("timer")
    
      useGetAllUsers()  
   
    
console.timeEnd("timer")
const [{users},]=useAdminStateValue()



const usersArray=users.filter(user=>user.role!=="owner")
 
const classes=useStyles() 





 
return (
        <div className={classes.root}>
             <AppBar position="sticky" >
            <Toolbar>
        
        <span className="addProduct_tag">{tag}</span>
        <div className="icon_user_container">
        <Badge badgeContent={usersArray.length} color="secondary" showZero>
               <SupervisedUserCircleIcon  className="icon_user_button"/> 
        </Badge>   
        </div>
                    
    
            </Toolbar>
        </AppBar>
        <SetUserRolesDisplay 
        users={usersArray}
        
        />
        </div>
    )
}

export default SetUserRole
