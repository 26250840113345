import React from 'react'
import {ReactComponent as WhatsApp} from '../images/svg/whatsApp.svg'
import {BottomCardContainer,CustomsizeIcon1,CustomsizeIcon2} from './ContactCard.elements'
import PhoneIcon from '@material-ui/icons/Phone';
import {ADDRESS1,ADDRESS2,PHONE,INFO_EMAIL} from  './useVariables'
import EmailIcon from '@material-ui/icons/Email';
const ContactCard = (props) => {
  return (
    <BottomCardContainer {...props}>
    
     <div className="bottomcard__right">
          <div className="bottomcard__address">
            <span>{ADDRESS1}<br/>{ADDRESS2}</span>
        </div>
        <div className="bottomcard__phone">
           
                <PhoneIcon  className="bottomcard__phoneicon"/>
            
            <span className="bottomcard__phnumber">{PHONE}</span>
            <CustomsizeIcon2 {...props} className="bottomcard__waicon">
                <WhatsApp/>
            </CustomsizeIcon2> 
        </div>
        <div className="bottomcard__email">
            <EmailIcon className="bottomcard__emailicon"/>
            <span>{INFO_EMAIL}</span>
        </div>
        
     </div>  
       
            
            
</BottomCardContainer>
  )
}

export default ContactCard