import React from 'react'
import { useStorageUpdate } from '../hooks'
import {storage} from '../firebase'


const FirebaseFamilyImage = (props) => {
    const {setLockHandle,images,cardSelected,newImgFile,styleDiv}=props
    
    const oldImgFile=images[cardSelected.family]
    React.useEffect(()=>{
        if (oldImgFile!=="na") {
        storage.refFromURL(oldImgFile).delete().then(()=>{
            console.log(`files ${oldImgFile} deleted from storage`)
        }).catch(e=>console.error(e))
    }
    setLockHandle(true)
    },[])
    
    const collection="Family_images"
    const docId=cardSelected.collection
    const updateRecord=cardSelected.family
    const file=newImgFile
    const folder=`Products/${docId}/families`
    const {error,progress,completed}=useStorageUpdate({file,collection,folder,docId,updateRecord})
    error&&console.log(error)
    if (completed) {
        
        
        
        
        
       
    }

    return (<>{!completed?
        <div style={styleDiv}>
            
            {progress<100?progress:"done"}
        </div>:null}
    </>)
}

export default FirebaseFamilyImage
