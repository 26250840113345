import React from 'react'
import {MiddleContainer} from './NavbarMiddle.elements'
import {Link} from 'react-router-dom'
import Agb from './Agb'
import {useStateValue} from '../StateProvider'


const NavbarMiddle=(props)=>{
const [{role},]=useStateValue()

const authorizeView=role==="Owner"


    return (
       
<MiddleContainer {...props}>
    
                    
        {authorizeView&& <Link to="/adminByOwner" className="navbarmiddle_text">
                <li >
                Manage
                </li>
        </Link>}               
        
        <Link to="/about" className="navbarmiddle_text">
            <li >
            About
            </li>
        </Link>
        <Link to='/products' className="navbarmiddle_button">
            <li>
                <Agb {...props} tag="Products"/>
            </li>
        </Link>
        <Link to="/services" className="navbarmiddle_text">
            <li>
                Services
            </li>
        </Link>
        <Link to='/contact'className="navbarmiddle_text">
            <li>
                Contact
            </li>
        </Link>
        
  
</MiddleContainer> 


    )
    
} 
export default NavbarMiddle
