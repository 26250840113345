import styled,{css} from 'styled-components'

const SocialsContainer=styled.div`
color:white;
display:flex;
flex-direction:row;
align-items:center;
${props=>props.device.mobile?css`

.socials__Inst{
    cursor:pointer;
    border-left:1px solid #ccc;
    .instagram {
    width:18px;
    align-self:center;}
}
.socials__Link{
    cursor:pointer;
    border-right:1px solid #ccc;
    border-left:1px solid #ccc;
    .linkedIn {
    width:18px;
    align-self:center;}
    
    
}
.socials__Face{
    cursor:pointer;
    border-right:1px solid #ccc;
    .faceBook {
    width:18px;
    align-self:center;}
    
}
`:props.device.tablet?css`

.socials__Inst{
    cursor: pointer;
    border-left:1px solid #ccc;
    .instagram {
    height:30px;
    width:30px;
    align-self:center;}
}
.socials__Link {
    cursor:pointer;
    border-right:1px solid #ccc;
    border-left:1px solid #ccc;
    .linkedIn {
    height:30px;
    width:30px;
    align-self:center;
    }
    
}
.socials__Face{
    cursor:pointer;
    border-right:1px solid #ccc;
    .faceBook {
    height:30px;
    width:30px;
    }
    
}
`:css`

.socials__Inst{
    cursor:pointer;
    border-left:1px solid #ccc;
    .instagram{
    height:30px;
    width:30px;
    align-self:center;}
}
.socials__Link{
    cursor:pointer;
    .linkedIn {
    height:30px;
    width:30px;
    }
    border-right:1px solid #ccc;
    border-left:1px solid #ccc;
}
.socials__Face{
    cursor:pointer;
    border-right:1px solid #ccc;
    .faceBook {
        height:30px;
        width:30px;
    }
    
}
`}

`

export default SocialsContainer