import produce from 'immer'

export const initialRecord={
    pds:"",
    sds:"",
    images:[],
    packing:{
        flexi:false,
        tote:false,
        drum:false,
        keg:false,
        pail:false,
        jug5qt:false,
        jug1gal:false,
        qtbottle:false,
        cartridge:false
    },
    code:"",
    product_name:"",
    description:""
}
export const initialState ={
    databasefiles:[],
    templatefiles:[],
    update:initialRecord,       
    opencomponent:{
    dbcreate:false,
    dbview:false,
    dbupdate:false,
    dbdelete:false,
    dbrestore:false,
    dbadd:false,
    usersetrole:false,
    userview:false,
    templatesadd:false,
    templatesupdate:false,
    templatesdelete:false,
    templatesview:false},
    task:null,
    updateDash:true,
    updateCoordinate:{collection:"",docId:""},
    updateCollectDeleteImg:[],
    updateCollectDeletePDS:"",
    updateCollectDeleteSDS:"",
    oldPDS:"na",
    oldSDS:"na",
    updateRecord:{},
    trash:[],
    users:[]
        }


const reducer = (state=initialState,action) =>
produce ( state,draft=>{
        switch (action.type) {
        case 'DB_FILES':
        draft.databasefiles=action.databasefiles  
        break;
        case 'TEMPLATE_FILES':
        draft.templatefiles=action.templatefiles 
        break;
        case 'INITIAL_UPDATE':
        draft.update=initialRecord
        break;
        case 'UPDATE_PDS_FILE':
        draft.update.pds=action.updatePDSfile
        break;
        case 'UPDATE_SDS_FILE':
        draft.update.sds=action.updateSDSfile 
        break;
        case 'UPDATE_IMAGES_FILES':
        if (action.updateImagesfiles==="na") {draft.update.images=[]} 
        else {draft.update.images=[...draft.update.images,...action.updateImagesfiles]} 
        break;
        case 'UPDATE_DELETE_PDS_FILE':
        draft.update.pds="na" 
        break;
        case 'UPDATE_DELETE_SDS_FILE':
        draft.update.sds="na" 
        break;
        case 'UPDATE_DELETE_IMAGES_FILES':
        draft.update.images=action.selectedFiles 
        break;
        
        case 'UPDATE_PACKING':
        draft.update.packing=action.packing 
        break;
        case 'UPDATE_CODE_NAME_DES':
        draft.update.code=action.code
        draft.update.product_name=action.name
        draft.update.description=action.description 
        break;
        
        case 'UPDATE_INITIALIZE_FILES':
        draft.update.images=[] 
        draft.update.sds="" 
        draft.update.pds=""
        break
        case 'UPDATE_SHOW_DASH':
        draft.updateDash=action.switchOff
        break
        case 'UPDATE_COORDINATES':
        draft.updateCoordinate=action.coordinate
        break
        case 'UPDATE_COLLECT_DELETE_PDS_INDB':
        draft.updateCollectDeletePDS=action.deleteFile
        break
        case 'UPDATE_COLLECT_DELETE_SDS_INDB':
        draft.updateCollectDeleteSDS=action.deleteFile
        break
        case 'UPDATE_COLLECT_DELETE_IMAGES_INDB':
        draft.updateCollectDeleteImg.push(action.deleteFile)
        break
        case 'RETRIEVE_TRASH':
        draft.trash=action.trash
        break
        case 'REMOVE_FROM_TRASH':
        draft.trash=action.trashBin
        break
        case 'UPDATE_COLLECT_DELETE_INDB_INIT':
        draft.updateCollectDeleteImg=[]
        draft.updateCollectDeletePDS=""
        draft.updateCollectDeleteSDS=""
        break
        
        case 'UPDATE_OLD_PDS':
        draft.oldPDS=action.oldPDS 
        break;
        case 'UPDATE_OLD_SDS':
        draft.oldSDS=action.oldSDS 
        break;
        case 'SET_TASK':
        draft.task=action.task 
        break;
        case 'SET_USERS':
        draft.users=action.users 
        break;
        case 'INITIALIZE':        
        return initialState   
        
        case 'OPEN_DB_CREATE':
        draft.opencomponent.dbcreate=action.open
        break;
        case 'OPEN_TEMPLATE_ADD':
        draft.opencomponent.templatesadd=action.open
        break;
       
        default:
        return
                            }})

export default reducer