import React,{useState,useEffect} from 'react'
import TextField from '@material-ui/core/TextField'
import { useAdminStateValue } from '../Admin'
import {useStyles} from './UpdateStyles'

        
    

const CodeNameDes = (props) => {
    const [,dispatch]=useAdminStateValue()
    const {code,product_name,description}=props.update
    const [conade,setConade]=useState({code,product_name,description})
    const classes=useStyles()    
    
   useEffect(()=>{
     setConade({code,product_name,description})
   },[code,product_name,description])
   
    const handleChange=(event)=>{
        
    const codebool="code"===event.target.name
    const namebool="product_name"===event.target.name
    const descbool="description"===event.target.name
    if (codebool) {        
        dispatch({
        type:'UPDATE_CODE_NAME_DES',
        code:event.target.value,name:product_name,description
        })
        props.setUpdate({...props.update,code:event.target.value})
    } else if (namebool){
        
        dispatch({type:'UPDATE_CODE_NAME_DES',
        name:event.target.value,code,description})
        props.setUpdate({...props.update,product_name:event.target.value})
    } else if (descbool) {
        
        dispatch({type:'UPDATE_CODE_NAME_DES',
        description:event.target.value,name:product_name,code})
        props.setUpdate({...props.update,description:event.target.value})
    }
    setConade({...conade,[event.target.name]:event.target.value})
       
  }
   
   return (
        < >
            <div className={classes.root}>
              <div styles={{display:"flex"}} >
          
          <TextField
            id="code_field_update"
            label="code"
            name="code"
            style={{ margin: 8,width: 120 }}
            
            helperText={conade.code}
            
            margin="normal"
            InputLabelProps={{
              shrink: true,
            }}
            
            value={conade.code}
            onChange={handleChange}
          />
          <TextField
            id="name_field"
            label="Product name"
            name="product_name"
            style={{ margin: 8,flex:1,width: '87%' }}
            
            
            margin="normal"
            InputLabelProps={{
              shrink: true,
            }}
            value={conade.product_name}
            
            onChange={handleChange}
          />   
   
      </div >

        <TextField
            
            id="standard_description"
            label="product description"
            name="description"
            style={{ margin: 8,flex:1,width: '98%' }}
            
            fullWidth
            
            multiline
            rowsMax={4}
            margin="normal"
            InputLabelProps={{
              shrink: true,
            }}
            value={conade.description}
            
            onChange={handleChange}
          />  
            </div>
            

      

        </>
    )
}

export default CodeNameDes
