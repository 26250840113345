import React from 'react';



import DefaultImg from '../images/sidemenu.jpg'
import Grid from '@material-ui/core/Grid';

import DisplayFixedMediaCard from './DisplayFixedMediaCard';

import GetInputFiles from './GetInputFiles';
import FirebaseFamilyImage from './FirebaseFamilyImage';




const DisplayFixedMedia = (props) => {
    const {collection,images,selected,name}=props
    console.log(selected)
    const cardRef=React.useRef([])
    cardRef.current=[]
    const [cardPosition,setCardPosition]=React.useState({top:0,left:0,width:0,height:0})
    const [cardSelected,setCardSelected]=React.useState(null)
    const [getNewImage,setGetNewImage]=React.useState(false)
    const [inputFiles,setInputFiles]=React.useState(null)
    const [success,setSuccess]=React.useState(false)
   const [lockHandle,setLockHandle]=React.useState(false)


    


const handleEdit=(e,family)=>{
        


console.log(family)
const refIndex=selected.map(n=>n.family).indexOf(family)
console.log(cardRef.current)
console.log(refIndex)
setCardSelected({family,familyName:selected[refIndex].name,collection,name})
setCardPosition(cardRef.current.length>0?{
top:cardRef.current[refIndex].lastChild.children[1].offsetTop,
left:cardRef.current[refIndex].lastChild.children[1].offsetLeft,
width:cardRef.current[refIndex].lastChild.children[1].offsetWidth,
height:cardRef.current[refIndex].lastChild.children[1].offsetHeight,
}:{
top:e.clientY+50,
left:e.clientX-260,
width:"290px",
height:"160px",

})
setGetNewImage(true)
    }

    const addToRef=(el)=>{
        if (el&&!cardRef.current.includes(el)){
            cardRef.current.push(el)
            
        }
        
        
    }
    React.useEffect(()=>{
        
        setCardPosition({top:0,left:0,width:0,height:0})
        setCardSelected(null)
        setGetNewImage(false)
        setInputFiles(null)
        setSuccess(false)
        
    },[collection])
    return (<>
        {images&&<Grid  container spacing={2} direction="row" justify="center">
            
            {selected.map((card,index)=>{
            return (
            <Grid item  xs={12} sm={6} md={4} lg={3} key={"grid_item"+100+index}>
            <div  ref={addToRef}>
                <DisplayFixedMediaCard 
                title={name}
                family={card.family}
                subheader={card.name}
                url={images[card.family]!=="na"?images[card.family]:DefaultImg}
                alt={"family pic theme"}
                key={"card"+1000+index}
                onEditClick={handleEdit}
                
                
                />
            </div> 
                

            </Grid>
        )
            }
            )}
            {getNewImage&&(success?<FirebaseFamilyImage 
            styleDiv={  {position:"absolute",
            top:cardPosition.top,
            left:cardPosition.left,
            width:cardPosition.width,
            height:cardPosition.height,
            backgroundColor: "rgba(255,10,150,0.6)",
            textAlign:"center",
            color:"#fff"}
            }
            newImgFile={inputFiles[0]}
            images={images}
            cardSelected={cardSelected}
            setGetNewImage={setGetNewImage}
            setInputFiles={setInputFiles}
            setCardSelected={setCardSelected}
            setSuccess={setSuccess}
            setLockHandle={setLockHandle}
            
            
            />
            :<GetInputFiles 
            tag={cardSelected.familyName}
            cardPosition={cardPosition}
            inputFiles={inputFiles}
            setInputFiles={setInputFiles}
            validTypes={["heic","HEIC","jpg","JPG","png","PNG","gif","GIF","jpeg","JPEG"]}
            setSuccess={setSuccess}
            />)}
        </Grid>}
  </> )
}

export default DisplayFixedMedia
