import React,{useState} from 'react'
import MenuContainer,{SmenuContainer,MitemContainer,MenuLinkP,ItemContainer} from './Admin_Menu.elements'
import ReactDom from 'react-dom'
import Button from '@material-ui/core/Button'
import {useAdminStateValue} from '../Admin'
const AdminMenu = (props) => {
    const [menuItem,setMenuItem]=useState(props.menu[0])
    const [sMenuItem,setSMenuItem]=useState(props.menu[0].sMenu[0])
    const [expand,setExpand]=useState(false)
    const [,dispatch]=useAdminStateValue()

    const expandCollapseM=(i)=>{
        if (expand===i){
            return setExpand(null)
        } else {setExpand(i)}
    }
    
    
    const handleSelect=(selected,i)=>{
       setMenuItem(selected)
       dispatch({
           type:'SET_TASK',
           task:selected
       })
       
        
           }
    const handleSelectSLink=(selected,i)=>{
        setSMenuItem(selected)
        dispatch({
            type:'SET_TASK',
            task:selected
        })
    }

    
    const menuJSX=
        props.menu.map((link,index)=>{
            const selected=menuItem===link
           
        return(
<MitemContainer className="Mitemcontainer" key={index+50}>
    
            <ItemContainer  className='mItem' onClick={()=>expandCollapseM(index)}
              key={index}>
             <MenuLinkP 
             selected={selected} 
             key={index+1000}     
             >
                 {link.mItem}   
            </MenuLinkP>
            {link.hasSMenu?<span className="arrow" key={`icon${index}`}aria-label="arrow">{expand===index ?"⬆":"⬇"}</span>:
            <Button size="small" key={`menuButton${index}`} onClick={()=>handleSelect(link,index)}>Select</Button>}        
            </ItemContainer>
{ expand===index ?<SmenuContainer className={`submenu`}>
    
    {link.hasSMenu&&link.sMenu.map((sLink,i)=>{
           const selectedS=sMenuItem===sLink
           return(
           <ItemContainer className="smItem"
           key={`sMenu${i}`} >    
           <MenuLinkP
           selectedS={selectedS}
           key={i+100}
           className={`sLink`}
           
           >
                {sLink.mItem}
           </MenuLinkP>
{sLink.hasSMenu?<span className="arrow" key={`smenuIcon${i}`} aria-label="arrow">{expand===index ?"⬆":"⬇"}</span>:
<Button size="small" key={`smenuButton${i}`} onClick={()=>handleSelectSLink(sLink,i)}>Select</Button>}
</ItemContainer>

       )}) }
</SmenuContainer>:null}
            
            
</MitemContainer>
        )})
    
    
    return ReactDom.createPortal (
        
      
            <MenuContainer {...props}>
                
            {menuJSX}
            
            </MenuContainer>,
     document.getElementById('portal')
    )
}

export default AdminMenu
