import React from 'react'
import {BannerWrap} from './Banner.elements'
import BannerImage from '../images/oilflowbanner.jpg'


const Banner = () => {
    
    
    return (
        <div>
            <BannerWrap>
                 <img
            className="productgrid_banner"
            src={BannerImage}
            alt="banner"

            />
            </BannerWrap>
           
               
          
           
        </div>
    )
}

export default Banner
