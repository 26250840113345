import React from 'react'
import {ReactComponent as Logosvg} from '../images/svg/logo.svg'

import {Container,CustomsizeIcon} from './LogoTxwos.elements'




export default function LogoTxwos(props) {



    return (
        
        <Container {...props}>
       
            <CustomsizeIcon {...props}  viewBox="0 0 164 81" className="logo__icon" >
            <Logosvg/>
        </CustomsizeIcon>
        
        
        </Container>
        
    )
}