import React,{useState,useRef} from 'react'
import {FileContainer} from './DropFile.elements'
import Button from '@material-ui/core/Button'

const AddGetFiles = (props) => {
    const inputRef=useRef()    
    const {productFiles,setInputFiles,bin,validTypes}=props
    const [highlighted,setHighlighted]=useState(false)
    return (
        <FileContainer {...props}
        onDragEnter={(e)=>{
          e.preventDefault()
          setHighlighted(true)}}
        onDragLeave={(e)=>{
          e.preventDefault()
          setHighlighted(false)}}
        onDragOver={e=>{e.preventDefault()
          setHighlighted(true)}}
        onDrop={e=>{
            e.preventDefault()
            setHighlighted(false) 
            const files=Array.from(e.dataTransfer.files).filter(file=>validTypes.some(validType=>file.name.endsWith(validType)))
            if (files.length===0) {alert("Please use a valid file format")
            return}
            if (bin==="images"){
              const images=[...productFiles.images,...files]
                setInputFiles({...productFiles,[bin]:images})
             } else {setInputFiles({...productFiles,[bin]:files})}
           
            }}
        highlighted={highlighted}
        >
                <h3><strong> Drop files here </strong></h3>
        <Button variant="contained" color="inherit"
        component="span"
        onClick={e=>{
          e.preventDefault()
          inputRef.current.click()
        }}
        >
          {props.tag}
        </Button>
        
          <input
          ref={inputRef}
         style={{display:"none"}}
        className='input_myfiles'
        
        multiple
        type="file"
        onChange={e=>{
        const files=Array.from(e.target.files).filter(file=>validTypes.some(validType=>file.name.endsWith(validType)))
        if (files.length===0) {alert("Please use a valid file format")
        return}  
        if (bin==="images"){
          const images=[...productFiles.images,...files]
            setInputFiles({...productFiles,[bin]:images})
         } else {setInputFiles({...productFiles,[bin]:files})}
            
        
      }}
      />
     
        
    </FileContainer>  
    )
}

export default AddGetFiles

