import React from 'react'
import {productsDB} from '../utilities'
import Autocomplete from '@material-ui/lab/Autocomplete';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import {useStyles} from './AddProductStyles'
import TextField from '@material-ui/core/TextField'
import AddToQueueIcon from '@material-ui/icons/AddToQueue';
import IconButton from '@material-ui/core/IconButton';

const GetCategory = ({tag,value,handleChange,handleAddView}) => {
    
    const classes=useStyles() 
    const defaultProps = {
        options: productsDB,
        getOptionLabel: (option) => option.name,  
      }
    
    
    return (
       
           <div className={classes.root} >
    <AppBar position="static" >
    <Toolbar>
        <span className="addProduct_tag">{tag}</span>
       {value&& <IconButton aria-label="add product" onClick={handleAddView}>
           <AddToQueueIcon color="primary"/> 
        </IconButton>}
                    
    <Autocomplete
        {...defaultProps}  
        value={value}
        onChange={handleChange}
        clearOnEscape
        autoHighlight
        autoSelect
        id="Categories"
        
        renderInput={(params) => <TextField {...params} label="Categories" color="primary" variant="outlined" />}       
        
    />
</Toolbar>
</AppBar>
            
        </div>
    )
}

export default GetCategory