import React from 'react'
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import {productsDB} from '../utilities'
import {sorting} from '../hooks'
const useStyles=makeStyles({
    root:{
        paddingLeft:'300px',
        width:'100%'
    },
    table:{
minWidth:650,
    }

})


const DataDisplay = (props) => {
    
    const classes=useStyles()
    
    let [collection,dataArray]=props.collection
    
    dataArray=sorting(dataArray,productsDB,collection,"code")
    
    
    const displayData=dataArray.length>0
    
    function getName (family) {
       const searchIn= productsDB[productsDB.map(n=>n.collection).indexOf(collection)].orderBy
        return searchIn[searchIn.map(n=>n.family).indexOf(family)].name
    }

   
    return (
        
<div className={classes.root}>
        {displayData?<TableContainer component={Paper}>
        <Table className={classes.table} size="small"  aria-label="a dense, sticky table">
        <TableHead>
        <TableRow >
        <TableCell align="justify">
        Family
        </TableCell>
        <TableCell align="left">
        Code
        </TableCell>
        <TableCell align="center">
        Name
        </TableCell>
        <TableCell align="justify">
        Internal id
        </TableCell>
        </TableRow>
        </TableHead>    
            <TableBody>
                {dataArray.map((data)=>(
                    <TableRow hover role="checkbox" tabIndex={-1} key={data.id}>
                        
                            <TableCell component="th" scope="row">
                            {getName(data.family)}
                        </TableCell>
                            <TableCell align="left">
                        {data.code}
                        </TableCell>
                            <TableCell align="left">
                        {data.product_name}
                        </TableCell>
                        <TableCell align="left">
                        {data.id}
                        </TableCell>

                        
                    </TableRow>
                ))}
            </TableBody>
        </Table>
        </TableContainer>:
        <h1 style={{textAlign:"center",fontSize:"22px",fontWeight:"800",color:"var(--error)",paddingTop:"25%"}}>no data to display</h1> 
}
          
           
</div>
    
    )
}

export default DataDisplay
