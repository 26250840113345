import styled from "styled-components"
export const EmailLogin=styled.div`
    
    width:100vw;
    display:flex;
    flex-direction:column;
    align-items:center;
   
`

export const Container=styled.div`
    margin:auto;
    width:${p=>p.device.mobile?"360px":
p.device.tablet?"420px":
p.device.desktop?"480px":
"520px"};
    height:fit-content;
    display:flex;
    flex-direction:column;
    padding:20px;
    border:1px solid lightgrey;
    border-radius:15px;
    background:white;
    h1 {
    font-weight:500;
    font-size:30px;
    margin-bottom: 20px;
    align-self:flex-start;
    }
    form{
    
    //flex-direction:column; 
    }
    //form>h5 {
    //font-size:16px;
    //font-weight:400;
    //margin-bottom: 5px;
    //align-self:flex-start;
    //}
    //form>input {
    //height:30px;
    //margin-bottom:30px;
    //background-color:white;
    //width:100%;
    //cursor:text;
 
    //}
    .login__signin{
    width:100%;
    height:30px;
    margin-top:10px;
    margin-right:5px;
    border-radius: 2px;
    background-color: #f0c14b;
    border: 1px solid;
    border-color: #a88734 #9c7e31 #846a29; 
    }
    form {
   display:flex;
   flex-direction:column;
    }
.login__createaccount{
    width:100%;
    height:30px;
    font-size:16px;
    margin-top:10px;
    margin-right:5px;
    border-radius:2px;
    border: 1px solid;
    border-color:lightgrey; 
}
`