import React,{useState} from 'react'
import LogoTxwos  from './LogoTxwos';
import Mmenuopen from './Mmenuopen'
import Mmenuclose from './Mmenuclose'
import Langswitch from './Langswitch'
import {Link} from 'react-router-dom'
import {NavContainer} from './Navbar.elements'
import NavbarMiddle from './NavbarMiddle'
import Sidenav from './Sidenav'

function Navbar(props) {

const[menu,setMenu]=useState(true)


    return (
            
           <NavContainer  {...props}>
                
                <Link to="/"> 
                    <div className="navbar__logo">
                    <LogoTxwos
                     className="Logo__Mobile"
                     {...props}                
                />  
                
                    </div>
                </Link>
                
                {!props.device.desktop&&!props.device.laptop&&
                <div className="navbar__menuicon">
                    {menu&&<Mmenuopen {...props}
                        onClick={()=>setMenu(false)}
                    />}
                    {!menu&&<Mmenuclose {...props}
                        onClick={()=>setMenu(true)}
                     />}                   
                    {!menu&&<Sidenav/>}
                </div>
                
                }
                {(props.device.desktop||props.device.laptop)&&
                <div className="navbar__desktop_laptop">
                    <NavbarMiddle {...props} />
                </div>
                    
                }
                <div className="navbar__langicon">
                <Langswitch {...props}  />
                </div>
                
                
            
                

            </NavContainer>
    )
}
export default Navbar