const pbannerImg = {
  Additives: require('./Banners/products/Additives.jpg'),
  BaseOil: require('./Banners/products/BaseOil.jpg'),
  DEF: require('./Banners/products/DEF.JPG'),
  Grease: require('./Banners/products/Grease.JPG'),
  Hardware: require('./Banners/products/hardware.jpg'),
  Lubricants: require('./Banners/products/Lubricants.jpg'),
  Refrigerant: require('./Banners/products/Refrigerant.jpg')
}
export default pbannerImg
