import styled,{css} from 'styled-components';

export const BottomCardContainer=styled.div`
background-color:white;
${props=>props.device.mobile&&css`
width:100%;
height:80px;
display:grid;
grid-template-columns:1fr 2fr;
margin-bottom: 70px;
.bottomcard__flag{
    
    display:flex;
    align-items:center;
    justify-content:center;
    
}

.bottomcard__right{
    
    grid-column-start: 2;
   
    display:flex;
    flex-direction:column;
    text-align:center;
    align-items:center;
    justify-content:center;
    font-size:10px;
    font-weight:500;
    color:grey;
    margin-right:35%;
}
.bottomcard__phone{
    
    display:flex;
    align-items:center;
    .bottomcard__phoneicon{
        color:black;
        height:18px;
       
    }
    .bottomcard__waicon{
        margin-left:8px;
        
    }
}
.bottomcard__emailicon{
    color:black;
    height:16px;
}

`}
${props=>props.device.tablet&&css`
width:100%;
height:140px;
display:grid;
grid-template-column:1fr 2fr;

.bottomcard__flag{
    
    display:flex;
    align-items:center;
    justify-content:center;
    
}

.bottomcard__right{
    
    grid-column-start: 2;
   
    display:flex;
    flex-direction:column;
    text-align:center;
    align-items:center;
    justify-content:center;
    font-size:18px;
    font-weight:500;
    color:grey;
    margin-right:35%;
}
.bottomcard__phone{
    
    display:flex;
    align-items:center;
    .bottomcard__phoneicon{
        color:black;
        height:22px;
       
    }
    .bottomcard__waicon{
        margin-left:8px;
        
    }
}
.bottomcard__emailicon{
    color:black;
    height:22px;
}

`}
${props=>props.device.desktop&&css`
width:100%;
height:120px;
display:grid;
grid-template-column:1fr 2fr;
border-top: 1px solid;
border-top-color: rgb(0, 0, 0, .2);
.bottomcard__flag{
    
    display:flex;
    align-items:center;
    justify-content:center;
    
}

.bottomcard__right{
    
    grid-column-start: 2;
   
    display:flex;
    flex-direction:column;
    text-align:center;
    align-items:center;
    justify-content:center;
    font-size:18px;
    font-weight:500;
    color:grey;
    margin-right:50%;
}
.bottomcard__phone{
    
    display:flex;
    align-items:center;
    .bottomcard__phoneicon{
        color:black;
        height:22px;
       
    }
    .bottomcard__waicon{
        margin-left:8px;
        
    }
}
.bottomcard__emailicon{
    color:black;
    height:22px;
}

`}
${props=>props.device.laptop&&css`
width:100%;
height:130px;
display:grid;
grid-template-column:1fr 2fr;
border-top: 1px solid;
border-top-color: rgb(0, 0, 0, .2);
.bottomcard__flag{
    
    display:flex;
    align-items:center;
    justify-content:center;
    
}

.bottomcard__right{
    
    grid-column-start: 2;
   
    display:flex;
    flex-direction:column;
    text-align:center;
    align-items:center;
    justify-content:center;
    font-size:18px;
    font-weight:500;
    color:grey;
    margin-right:50%;
}
.bottomcard__phone{
    
    display:flex;
    align-items:center;
    .bottomcard__phoneicon{
        color:black;
        height:22px;
       
    }
    .bottomcard__waicon{
        margin-left:8px;
        
    }
}
.bottomcard__emailicon{
    color:black;
    height:22px;
}

`}
`
export const CustomsizeIcon1=styled.svg`
${props=>props.device.mobile&&css`
height:45px;
width:60px;
`}
${props=>props.device.tablet&&css`
height:65px;
width:80px;
`}
${props=>props.device.desktop&&css`
height:85px;
width:100px;
`}
${props=>props.device.laptop&&css`
height:85px;
width:100px;
`}
`
export const CustomsizeIcon2=styled.svg`
${props=>props.device.mobile&&css`
height:18px;
width:18px;
`}
${props=>props.device.tablet&&css`
height:22px;
width:46px;
`}
${props=>props.device.desktop&&css`
height:24px;
width:24px;
`}
${props=>props.device.laptop&&css`
height:24px;
width:24px;
`}
`