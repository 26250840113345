import React,{useState} from 'react'
import {CardContainer} from './ProductCard.elements'
import DefaultMainPic from '../images/sidemenu.jpg'
import ViewThumbFiles from './ViewThumbFiles'
import PackingInfo from './PackingInfo'
import TextInfo from './TextInfo'
import ViewPDS_SDS from './ViewPDS_SDS'
const ViewAddProductCard = (props) => {
    
    const {productFiles,code,product_name,description,packing}=props
    const {pds,sds}=productFiles
    
    let pdsF=""
    let sdsF=""
    if (pds!=="") {pdsF=pds[0]}
    if (sds!=="") {sdsF=sds[0]}
    
    const [selected,setSelected]=useState(0)
    const {images}=productFiles
    const imagesbool=images.length>0
    let urlArray=[]
    if (imagesbool) {
        images.forEach(image=>{
            urlArray.push(URL.createObjectURL(image))
        })
    }
    return (
        <CardContainer>
          
           <div className="ThumbContainer">
                    {imagesbool?<ViewThumbFiles bin="images"  deletef={false} productFiles={productFiles} selected={selected} setSelected={setSelected} clickable={true}/>:
                    <p>No images</p>}
                </div>
                <div className="MainPicture">
    <img src={imagesbool?urlArray[selected]:DefaultMainPic} alt="main picture"/>
                </div>
            <div className="CodeInfo">
                <span >code:</span><br/><span >{code}</span>
                </div>
                <div className="PDFInfo">
                    <ViewPDS_SDS sds={sdsF} pds={pdsF} />
                </div>
            
            <div className="TextInfo"><TextInfo product_name={product_name} description={description}/></div>
            <div className="PackInfo" style={{alignSelf:"center"}}>
            <PackingInfo packing={packing}/>
            </div>
            
            
        </CardContainer>
    )
}

export default ViewAddProductCard
