import React,{useState,useEffect} from 'react'
import firebase from 'firebase/app'
import 'firebase/firestore'
import {useAdminStateValue} from '../Admin'
import {storage,db} from '../firebase'


const FirebaseUpdate = (props) => {
  const [{updateCollectDeletePDS,updateCollectDeleteSDS,updateCollectDeleteImg,updateCoordinate:{collection,docId}},]=useAdminStateValue()
  const {update}=props
  
const [outputJSX,setOutputJSX]=useState(<p></p>)
  console.log(collection,docId,update)
  
  
  let info=[]
  
  const packingToDB=(pack)=>{
    if (pack) {
      return pack="yes"
    } else {
      return pack="no"
    }
  }
  const hasContent =(verify)=>{
    return !(verify===""||verify==="na"||(Array.isArray(verify)&&verify.length===0))
        }
  const isInDB=(verify)=>{
    return (typeof(verify)==='string'&&verify.startsWith('https://firebasestorage'))
  }
  const storeFiles=(files,section)=>{
        
    
    const folder=`Products/${collection}/${update.code}/${section}`
    const recordRef=db.collection(collection).doc(docId)
    if (section==="PDS"||section==="SDS") {
      if (hasContent(files)) {
        if (!isInDB(files)) {
          console.log("uploading file:",files.name)
          info.push(`Uploading ${section} file ${files.name}`) 
          const uploadTask= storage.ref(folder).child(files.name).put(files)
           uploadTask.on('state_changed',null,error=>{console.log(error)

          },()=>{
             storage.ref(folder).child(files.name).getDownloadURL()
                  .then((downloadURL)=>{
                  info.push(`${section} file stored in ${downloadURL}`)  
                  console.log("file stored in:",downloadURL)
                  if (section==="SDS") {recordRef.update({sds:downloadURL}).then(()=>{
                    info.push(`SDS file ${downloadURL} stored in DB`)
                    console.log(`SDS file ${downloadURL} saved in DB`)
                  }).catch(e=>{console.error(e)})}
                  if (section==="PDS") {recordRef.update({pds:downloadURL}).then(()=>{
                    info.push(`PDS file ${downloadURL} saved in DB`)
                    console.log(`PDS file ${downloadURL} saved in DB`)
                  }).catch(e=>{console.error(e)})}

                  })
          })
        } }
    } else { 
    if (hasContent(files)) {      
      files.forEach((file)=>{  
            
              if (!isInDB(file)) {
                info.push(`Uploading file ${file.name} to storage bucket`)
                console.log("uploading file:",file.name) 
                const uploadTask= storage.ref(folder).child(file.name).put(file)
                 uploadTask.on('state_changed',null,error=>{console.log(error)
      
                },()=>{
                   storage.ref(folder).child(file.name).getDownloadURL()
                        .then((downloadURL)=>{
                          info.push(`Image file stored in ${downloadURL}`)  
                        console.log("file stored in:",downloadURL)
                        recordRef.update({images:firebase.firestore.FieldValue.arrayUnion(downloadURL)}).then(()=>{
                        info.push(`Image file ${downloadURL} saved in DB`)
                          console.log(`Image file ${downloadURL} saved in DB`)
                        }).catch(e=>{console.error(e)})
                        })
                })
              } 
            }
              
    )}

    if (updateCollectDeleteImg.length>0) {
      updateCollectDeleteImg.forEach(file=>{
        recordRef.update({images:firebase.firestore.FieldValue.arrayRemove(file)}).then(()=>{
          info.push(`Image ${file} removed from DB`)   
          console.log(`Image ${file} removed successfully from DB`)
            }).catch(e=>{console.error(e)})
      })
      if (files==="na"){
      recordRef.update({images:"na"}).then(()=>{
        info.push('All files deleted')
        console.log("all files deleted")})
    }       
    }
    
     
    }
      
    
  }

useEffect(()=>{
  if (!(updateCollectDeletePDS==="")) {
    const deletePDS=storage.refFromURL(updateCollectDeletePDS)
    deletePDS.delete().then(()=>{
      info.push(`PDS file ${updateCollectDeletePDS} deleted from storage`)
      console.log("pds file",updateCollectDeletePDS," deleted")}).catch((e)=>console.log(e))
  } 
  if (!(updateCollectDeleteSDS==="")) {
    info.push(`SDS file ${updateCollectDeleteSDS} deleted from storage`)
    const deleteSDS=storage.refFromURL(updateCollectDeleteSDS)
    deleteSDS.delete().then(()=>{console.log("sds file",updateCollectDeleteSDS," deleted")}).catch((e)=>console.log(e))
  } 
},[updateCollectDeletePDS,updateCollectDeleteSDS])





useEffect(() => {


  
if (updateCollectDeleteImg.length>0) {
    updateCollectDeleteImg.forEach(img=>{
      const deleteTask=storage.refFromURL(img)
      deleteTask.delete().then(()=>{
        info.push(`Image file ${img} deleted from storage`)
        console.log("img file:",img," deleted")}).catch((e)=>console.log(e))
    })
  }
  storeFiles(update.images,'Images')
  storeFiles(update.pds,"PDS")
  storeFiles(update.sds,"SDS")
  
  const record={
    collection,
    
    code:update.code,
    product_name:update.product_name,
    description:update.description,
    
    flexi:packingToDB(update.packing.flexi),
    tote:packingToDB(update.packing.tote),
    drum:packingToDB(update.packing.drum),
    pail:packingToDB(update.packing.pail),
    jug5qt:packingToDB(update.packing.jug5qt),
    jug1gal:packingToDB(update.packing.jug1gal),
    qtbottle:packingToDB(update.packing.qtbottle),
    keg:packingToDB(update.packing.keg),
    cartridge:packingToDB(update.packing.cartridge),
  }
  console.log("record: ",record)
    db.collection(collection).doc(docId).update(record).then(()=>{
      info.push("record saved to DB successfully")
    console.log(record,' saved to DB successfully')
  })
}
, [])
  

useEffect(()=>{
if (info.length>0) {
  setOutputJSX(info.map((inf,index)=>{
return <p key={inf+index}>{inf}</p>
  }))
}
console.log("info: ",info)
},[info])





    return (
        <div>
            Updating database please wait
            {outputJSX}
            
                     
        </div>
    )
}

export default FirebaseUpdate