import styled from 'styled-components'

const Container=styled.div`
h1{
    font-size:2rem;
    color:var(--error);
}
.notFound{
    position:fixed;
    bottom:0;
    left:0;
}
`
export default Container