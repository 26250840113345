import React,{useState,useEffect} from 'react'
import {db,storage,timestamp} from '../firebase'
import firebase from 'firebase/app'
import 'firebase/firestore'
const FirebaseAdd = ({collection,conade,productFiles,packing}) => {
    const [info,setInfo]=useState(["adding product"])
    const packingToDB=(pack)=>{
        if (pack) return "yes"
        return "no"
    }
    const hasContent=(files)=>{

return !(files===""||(Array.isArray(files)&&files.length===0))
    }
    
    const storeFiles=(files,section,docId)=>{
        
    
        const folder=`Products/${collection}/${conade.code}/${section}`
        const recordRef=db.collection(collection).doc(docId)
        
        if (hasContent(files)) {
        if (section!=="Images") {
          
            
              console.log("uploading file:",files[0].name)
              setInfo(info.push(`Uploading ${section} file ${files[0].name}`)) 
              const uploadTask= storage.ref(folder).child(files[0].name).put(files[0])
               uploadTask.on('state_changed',null,error=>{console.log(error)
    
              },()=>{
                 storage.ref(folder).child(files[0].name).getDownloadURL()
                      .then((downloadURL)=>{
                      setInfo(info.push(`${section} file stored in ${downloadURL}`))  
                      console.log("file stored in:",downloadURL)
                      recordRef.update({[section.toLowerCase()]:downloadURL}).then(()=>{
                        setInfo(info.push(`${section} file ${downloadURL} stored in DB`))
                        console.log(`${section} file ${downloadURL} saved in DB`)
                      }).catch(e=>{console.error(e)})  
                      })
              })
             } else {
          
            
          files.forEach((file)=>{  
                
                  
                    setInfo(info.push(`Uploading file ${file.name} to storage bucket`))
                    console.log("uploading file:",file.name) 
                    const uploadTask= storage.ref(folder).child(file.name).put(file)
                     uploadTask.on('state_changed',null,error=>{console.log(error)
          
                    },()=>{
                       storage.ref(folder).child(file.name).getDownloadURL()
                            .then((downloadURL)=>{
                              setInfo(info.push(`Image file stored in ${downloadURL}`))  
                            console.log("file stored in:",downloadURL)
                            recordRef.update({images:firebase.firestore.FieldValue.arrayUnion(downloadURL)}).then(()=>{
                            setInfo(info.push(`Image file ${downloadURL} saved in DB`))
                              console.log(`Image file ${downloadURL} saved in DB`)
                            }).catch(e=>{console.error(e)})
                            })
                    })
                   
                }
                  
        )
    }

 
     }else {
        recordRef.update({[section.toLowerCase()]:"na"})
       setInfo(info.push(`No ${section} file`))
       }
    }
    
    
    useEffect(()=>{
    const compactRecord={collection,created:timestamp,...conade,...Object.fromEntries(Object.entries(packing).map(pack=>{return [pack[0],packingToDB(pack[1])]}))}
    db.collection(collection).add(compactRecord).then((docRef)=>{
        storeFiles(productFiles.pds,"PDS",docRef.id)
        storeFiles(productFiles.sds,"SDS",docRef.id)
        storeFiles(productFiles.images,"Images",docRef.id)
    })
console.log("info:",info)

    },[])
    
    

    
    
    
    return (
        <div>
            adding product...
            <ul>
                {/*info.map(inf=>{
                  return  (<li>{inf}</li>)
                })*/}
                
            </ul>
            
        </div>
    )
}

export default FirebaseAdd
