import React from 'react'
import Intro from '../components/Intro'
import BottomCard from '../components/Bottomcard'
import {useDevice} from '../hooks'
import Header from '../components/Header'
import Navbar from '../components/Navbar'
import Footer from '../components/Footer'


function Homeview(props){
   const device=useDevice()
        return (
        <div >
        <Header  device={device} />         
        <Navbar {...props} device={device}/>
        <Intro {...props} device={device}/>
        <BottomCard {...props} device={device}/>
        <Footer {...props} device={device} />
        </div>
    )
}

export default Homeview