import styled from 'styled-components'

export const Sidebar=styled.div`
display:flex;
flex-direction:column;
background-color:#547dbf;
position: absolute;

top:150px;
left:0;
width:300px;
height:77vh;
z-index:10;
.dashboard_sidenav_header{
    display:flex;
    align-items:center;
    justify-content:space-around;
    
}

h1{
    margin-left:5px;
    color:white;
    font-size:18px;
    font-weight:800;
    text-align:center;
    border-bottom:5px double #f0f0f0;
}
ul{
    flex:1;
    display:flex;
    flex-direction:column;
    justify-content:space-evenly;
    margin-left:15%;
    margin-right:5%;
}


`
export const ListContainer=styled.div`

    display:flex;
    align-items:center;
    justify-content:space-between;
    background-color:${p=>p.isSelected?'var(--primary)':'transparent'};
    border-radius:10px;
li{
    cursor:pointer;
    color:#ffffff;
    font-weight:750;
    
    border:2px solid #ffffff;
    width:fit-content;
    padding:3px;
    border-radius:10px;
}

    .li_badge{
        flex:1;
        justify-self:flex-end;
    }
    :hover{
        background-color:rgba(230,230,200,0.4);
        border-radius:10px;
        li{
            transform:scale(1.05);
        }
        transition: all 0.3s ease-out;
    }

`
export const PageContainer=styled.div`

margin-bottom:6.5%;

`