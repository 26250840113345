import React,{useState} from 'react'
import GetRecord from './GetRecord'
import CodeNameDes from './CodeNameDes'
import ShowFiles from './ShowFiles'
import CheckPackage from './CheckPackage'
import {useStyles} from './UpdateStyles'
import Button from '@material-ui/core/Button'
import CloudUploadIcon from '@material-ui/icons/CloudUpload'
import Upload from './Upload'
const Update =(props) => {
  const [update,setUpdate]=useState(null)
  const [showRecord,setShowRecord]=useState(false)
  const [uploading,setUploading]=useState(false)
  const classes=useStyles() 
  const handleSubmit=(event)=>{
    event.preventDefault()
    setUploading(true)
    
    }
    return (
      <>
      {!uploading&&<GetRecord {...props} setUpdate={setUpdate} setShowRecord={setShowRecord}/>}
      {showRecord&&update&&<form>
      <CodeNameDes update={update} setUpdate={setUpdate} />
      <ShowFiles update={update} setUpdate={setUpdate}/>
      <div className={classes.root}  style={{marginLeft:"3%",marginTop:"2%",display:"flex"}} >
        <CheckPackage  update={update} setUpdate={setUpdate}/>
        </div>
       

          {!uploading&&<Button
          style={{position:'fixed',top:'156px',left:'60%',fontSize:'22px',height:'60px'}}
          color="default"
            type="submit"
            variant="contained"
            size="small"
            onClick={handleSubmit}
            startIcon={<CloudUploadIcon />}
          >
            Upload
          </Button>}  
      </form>}
        {uploading&&<div>
        <Upload uploading={uploading} setUploading={setUploading} update={update}/>
        </div>}   
      </>
    )
}
export default Update
