import React,{useState,useEffect} from 'react'
import {mobile,tablet,desktop,laptop,breakPoint} from './hookVars'
import {useStateValue} from '../StateProvider'
import {useAdminStateValue} from '../Admin'
import {auth,storage,timestamp,db,myFunctions} from '../firebase'
import {pbannerImg,sbannerImg} from '../images'
import OilFlow from '../images/oilflowbanner.jpg'
import {storeInit,templateMenuItems,usersMenuItems,masterDBMenu} from '../utilities'
import DropFile from '../components/DropFile'
import Dashboard from '../components/Dashboard'
import RestoreProduct from '../components/RestoreProduct'
import AddProduct from '../components/AddProduct'
import SetUserRole from '../components/SetUserRole'
import FamilyImages from '../components/FamilyImages'
import {QueryClient} from 'react-query'

export const queryClient= new QueryClient()

export const useDevice = () => {
    
    
    
    const [device, setDevice ] = useState(
        window.innerWidth<=breakPoint.mobile?{mobile}:
        window.innerWidth<=breakPoint.tablet?{tablet}:
        window.innerWidth<=breakPoint.desktop?{desktop}:{laptop})
        

    

    useEffect(() => {
        const handleWindowResize = () => {
            const wSize=window.innerWidth;
            (wSize<=breakPoint.mobile)?setDevice({mobile}):
            (wSize<=breakPoint.tablet)?setDevice({tablet}):
            (wSize<=breakPoint.desktop)?setDevice({desktop}):
            setDevice({laptop})
             };

        window.addEventListener('resize', handleWindowResize);
        return () => window.removeEventListener('resize', handleWindowResize);
    },[]);

   return device;
};
export const useInterval = ({time}) => {
  
  const [show, setShow] = useState(true);

  useEffect(() => {
    const interval = setInterval(()=>setShow(!show), time);
    return () => clearInterval(interval);
  }, []);
  return show
}
export const useIntervalCount = ({time}) => {
  
  const [count, setCount] = useState(0);

  useEffect(() => {
    const interval = setInterval(()=>setCount(count+1), time);
    return () => clearInterval(interval);
  }, [time,count]);
  return count
}


export const useDelay= ({time}) => {
    
    const [delay,setDelay]=useState(false)
    useEffect(() => {
        const timer = setTimeout(() => {
          setDelay(true);
        }, time);
        return () => clearTimeout(timer);
      }, [time]);return delay}

      

export const useAuth=()=>{
const [{user},dispatch]=useStateValue()

useEffect(()=>{
  const unsuscribe= auth.onAuthStateChanged((authUser)=>{
  console.log("useAuth called")
    if (authUser) {
      dispatch({
        type:'SET_USER',
        user:authUser
      })
    

     } else {
       dispatch({
         type:'SET_USER',
         user:null
      }
      
      )
     }
     
  })
  
  return ()=>{
    unsuscribe();
    
  }

},[user])

}
export const useGetAllUsers=()=>{
  const [,dispatch]=useAdminStateValue()
  
  
  console.log("hook useGetAllUSers")
 const fetchUsers=async ()=>{
   let users=[]
   await db.collection('users').get().then(snapShot=>{
    snapShot.forEach(snap=>{
      users.push({id:snap.id,...snap.data()})
    })
    
    console.log("store the users in the state")
    dispatch({type:"SET_USERS",
users})
   }).catch(e=>console.log(e))
   
   
 }
 useEffect(()=>{
  console.log("read users from firebase") 
  fetchUsers()
  
 },[])

};





export const useSetRole=()=>{
  const [{user},dispatch]=useStateValue()
  
  useEffect(()=>{
    if (user) {
      const ownerAccess= myFunctions.httpsCallable('ownerAccess')
    
    user&&ownerAccess().then(result=>{
      
        if (result.data) {
          console.log("useSetRole called")
          dispatch({
            type:"SET_USER_ROLE",
            role:"Owner"
          })
        }
      
    }).catch((error) => {
      // Getting the Error details.
      console.log(error.code," ",error.message)
      // ...
    }) 
      
    
    }
       
    if (!user){
      dispatch({
        type:"SET_USER_ROLE",
        role:""
      })
    }
    
  
  },[user])
}
export const useSetDB=()=>{
  
const [{user},]=useStateValue()
const [doc,setDoc]=useState(null)
const createDB= myFunctions.httpsCallable('createDB')
useEffect(()=>{
let isSubscribed=true
user&&createDB().then(result=>{
  if (isSubscribed) {
    if (result.data){
      setDoc(result.data)
      console.log("Create DB called:",result.data)
    }
  }
}).catch((error) => {
  // Getting the Error details.
  console.log(error.code," ",error.message)
  // ...
}) 

return ()=>isSubscribed=false
},[doc]) 
return doc   
}
export function sorting (input,base,filter1,filter2="") {

  if (input.length<2) return input    
  const sortBase=base[base.map(n=>n.collection).indexOf(filter1)].orderBy.map(n=>n.family)
  if (sortBase.lenght<2) {return input}
  let sorted=input.slice().sort((a,b)=>{
      const valueA=sortBase.indexOf(a.family)
      const valueB=sortBase.indexOf(b.family)
      
      return valueA-valueB
  })
  filter2!==""&&sorted.sort((a,b)=>{
      if (a.family===b.family) {
          if (a[filter2]<b[filter2]) {return -1}
          if (a[filter2]>b[filter2]) {return 1}
      }
      return 0
  })
  return sorted
  }


export const useStore=()=>{
const [,dispatch]=useStateValue()
const [docs,setDocs]=useState(storeInit)
const fetchData=async ()=>{
let  getDataPromises=[]
const products=Object.keys(storeInit.products)
products.forEach(product=>{
  getDataPromises.push(db.collection(product).get())
})

const [Base_OilsSS,AdditivesSS,LubricantsSS,GreasesSS,DEFSS,RefrigerantsSS]= await Promise.all(getDataPromises)

const Base_Oils=[]
Base_OilsSS.forEach(doc=>{
  const id=doc.id
  const data=doc.data()
  Base_Oils.push({id,...data})})

  const Additives=[]
AdditivesSS.forEach(doc=>{
  const id=doc.id
  const data=doc.data()
  Additives.push({id,...data})})

  const Lubricants=[]
LubricantsSS.forEach(doc=>{
  const id=doc.id
  const data=doc.data()
  Lubricants.push({id,...data})})

  const Greases=[]
GreasesSS.forEach(doc=>{
  const id=doc.id
  const data=doc.data()
  Greases.push({id,...data})})

  const DEF=[]
DEFSS.forEach(doc=>{
  const id=doc.id
  const data=doc.data()
  DEF.push({id,...data})})

  const Refrigerants=[]
  RefrigerantsSS.forEach(doc=>{
  const id=doc.id
  const data=doc.data()
  Refrigerants.push({id,...data})})

  const Hardware=[]

  

const data={
products:{
  Base_Oils,
  Additives,
  Lubricants,
  Greases,
  DEF,
  Refrigerants,
  Hardware},
}
setDocs(data)
}
useEffect(()=>{
  console.log("fetch the store")
  fetchData()
},[])
useEffect(()=>{
  console.log("store the data",docs)
  dispatch(
    { type:'SET_STORE',
      store:docs})
},[dispatch,docs])

}
export const useGetTrash=()=>{
  const [,dispatch]=useAdminStateValue()

  
  const fetchTrash=async ()=>{
    const trashS=await db.collection('trash').orderBy('deleted','desc').get()
    
    const trash=[]
    trashS.forEach(doc=>trash.push({id:doc.id,...doc.data()}))
   console.log('trash before dispatch:',trash) 
   dispatch(
      { type:'RETRIEVE_TRASH',
        trash})
  }
  useEffect(()=>{
console.log('fetch the trash')
fetchTrash()
  },[])
  
}

export const useUpdateDB=(props)=>{
  const [{update,updateCoordinate:{collection,docId}},]=useAdminStateValue()
  console.log(collection,docId,update)
  const packingToDB=(pack)=>{
    if (pack) {
      return pack="yes"
    } else {
      return pack="no"
    }
  }
  const record={
    collection,
    docId,
    code:update.code,
    product_name:update.product_name,
    descritption:update.description,
    images:update.images,
    pds:update.pds,
    sds:update.sds,
    flexi:packingToDB(update.packing.flexi),
    tote:packingToDB(update.packing.tote),
    drum:packingToDB(update.packing.drum),
    pail:packingToDB(update.packing.pail),
    jug5qt:packingToDB(update.packing.jug5qt),
    jug1gal:packingToDB(update.packing.jug1gal),
    qtbottle:packingToDB(update.packing.qtbottle),
    keg:packingToDB(update.packing.keg),
    cartridge:packingToDB(update.packing.cartridge),
  }
console.log("record",record)

}

export const useStorage=(props)=>{
const [progress,setProgress]=useState(0);
const [error,setError] = useState(null);
const [url,setUrl]= useState(null);

const {file,collection,docId,folder}=props
useEffect(()=>{

const storageRef=storage.ref(folder).child(file.name)
const collectionRef=db.collection(collection).doc(docId)
storageRef.put(file).on('state_changed',(snap)=>{
 
  let percent=(snap.bytesTransferred/snap.totalBytes)*100;
  setProgress(percent);
},(err)=>{
  setError(err);
},async ()=>{
  const url=await storageRef.getDownloadURL();
  const createdAt=timestamp
  collectionRef.add({url,createdAt})
  setUrl(url)
}
)
},[file,collection,folder])
return {progress,url,error}
}
export const useStorageUpdate=(props)=>{
const [progress,setProgress]=useState(0);
const [error,setError] = useState(null);
const [url,setUrl]= useState(null);
const [completed,setCompleted]=useState(false)
const {file,collection,docId,folder,updateRecord}=props
useEffect(()=>{

const storageRef=storage.ref(folder).child(file.name)
console.log("firebase collection:",collection,"docId",docId)
  
const collectionRef=db.collection(collection).doc(docId)
storageRef.put(file).on('state_changed',(snap)=>{
 
  let percent=(snap.bytesTransferred/snap.totalBytes)*100;
  setProgress(percent);
},(err)=>{
  setError(err);
},async ()=>{
  const url=await storageRef.getDownloadURL();
  
  console.log("update record: ",updateRecord)
  collectionRef.update({[`${updateRecord}`]:url})
  .then(()=>{
    setUrl(url)
    setCompleted(true)
  }).catch(e=>console.log(e))
  
}
)
},[file,collection,folder])
return {progress,url,error,completed}
}


export const useDb =(collection,type)=>{
  const [,dispatch]=useStateValue()
  const [docs,setDocs]=useState([])
  useEffect (()=>{
    
    const unsub=db.collection(collection)
    .onSnapshot((snap)=>{
      let documents =[]
      snap.forEach(doc=>{
        documents.push([doc.id,doc.data()])
      })
      setDocs(Object.fromEntries(documents))
    })
    return ()=>unsub()
  },[collection])
  dispatch({type,
docs})
}


export const bannerSelector=(id)=>{
let match=id  
 match=match.slice(0,2)

let banner={
  BO:false,
  AD:false,
  DE:false,
  GR:false,
  HA:false,
  LU:false,
  RE:false,
  lu:false,
  fl:false,
  lo:false,
  oe:false}
switch (match){
  case 'Ba':
    banner.BO=true
    return banner
  case 'Ad':
    banner.AD=true
    
    return banner
  case 'DE':
    banner.DE=true
   
    return banner
  case 'Gr':
    banner.GR=true
   
    return banner
  case 'Ha':
    banner.HA=true
    
    return banner
  case 'Lu':
    banner.LU=true
    
    return banner
  case 'Re':
    banner.RE=true
    return banner
  case 'lu':
    banner.lu=true
    return banner
  case 'fl':
    banner.fl=true
    return banner
  case 'lo':
    banner.lo=true
    return banner
  case 'th':
    banner.oe=true
    return banner
    default:
       return banner
}

}
export const useMouseMove=()=>{
  const [state,setState]=useState({x:0,y:0})
  const handleMouseMove=(e)=>{
    e.persist()
    setState(state=>({...state,x:e.clientX,y:e.clientY}))
      }
     
     return {
      x:state.x,
      y:state.y,
      handleMouseMove,
    }
}
export const useMousePos=(windowAxis=true)=>{
  const [pos,setPos]=useState({x:0,y:0})
  
  console.log("windowAxis",windowAxis)
const logMousePosition=e=>{
  windowAxis&&setPos({x:e.clientX,y:e.clientY,xAxis:(window.innerWidth/2-e.clientX)/10,yAxis:(window.innerHeight/2-e.clientY)/10})
  !windowAxis&&setPos({x:e.clientX,y:e.clientY})
}
  useEffect(()=>{
  window.addEventListener('mousemove',logMousePosition)
    return ()=> window.removeEventListener('mousemove',logMousePosition)
},[])

  return pos

}
export const useBanner=(id)=>{
  
const img=bannerSelector(id)
const {Additives,BaseOil,DEF,Grease,Hardware,Lubricants,Refrigerant}=pbannerImg
 const {lua2012,flt2012,log2012,oem2012}=sbannerImg
 const showBanner=
 img.AD?Additives:
 img.BO?BaseOil:
 img.DE?DEF:
 img.GR?Grease:
 img.HA?Hardware:
 img.LU?Lubricants:
 img.RE?Refrigerant:
 img.lu?lua2012:
 img.fl?flt2012:
 img.lo?log2012:
 img.oe?oem2012:
 OilFlow

return  {showBanner,img}    
}

export const useExecutableItems=()=>{
  const [{task},]=useAdminStateValue()  
  const executables=[]
  const extract=[masterDBMenu,usersMenuItems,templateMenuItems]
  extract.forEach(menu=>{
      menu.forEach(element=>{
          if (element.hasSMenu) {
              element.sMenu.forEach(selement=>{
                  if (!selement.hasSMenu){
                      executables.push(selement)
                  }
              })
          } else {
              executables.push(element)
          }
      })
  })
  const validDBTypes=['ods','ODS','xlsx','xlsm','XLSX','XLSM','csv','CSV']
  const validTemplateTypes=['pdf','PDF']  
      switch (task.id) {
          case executables[0].id:
              
              return (
                  <div style={{display:"flex"}}>
                      <h3 style={{justifySelf:"flex-start"}}>{task.mItem}</h3>
                      <DropFile tag="Get Master Database" validTypes={validDBTypes}/>
                  </div>
                  
              )
          case executables[1].id:
              return (
                  <div>
                      
                      <Dashboard task={task.mItem} view/>
                  </div>
                  
              )
          case executables[2].id:
              return (
                  <div>
                       <Dashboard task={task.mItem} update />
                  </div>
                  
              )
          case executables[3].id:
              return (
                  <div>
                       <Dashboard task={task.mItem} delete />
                     
                  </div>
                  
              )
          case executables[4].id:
              return (
                  <div>
                      <RestoreProduct task={task.mItem}/>
                     
                  </div>
                  
              )
          case executables[5].id:
              return (
                  <div>
                      
                      <AddProduct tag={task.mItem}/>
                     
                  </div>
                  
              )
          case executables[6].id:
               
          return (
                  <div>                    
                     <SetUserRole tag={task.mItem}/>
                  </div>
                  
              )
          case executables[7].id:
            
          return (
                  <div>
                      <h3>{task.mItem}</h3>
                    
                  </div>
                  
              )
          case executables[8].id:
              return (
                  <div>
                      <h3>{task.mItem}</h3>
                      
                  </div>
                  
              )
          case executables[9].id:
              return (
                  <div>
                      <h3>{task.mItem}</h3>
                     
                  </div>
                  
              )
          case executables[10].id:
              return (
                  <div>
                      <h3>{task.mItem}</h3>
                     
                  </div>
                  
              )               
          case executables[11].id:
              return (
                  <div>
                      <h3>{task.mItem}</h3>
                     
                  </div>
                  
              )               
          case executables[12].id:
              return (
                  <div>
                      
                      <FamilyImages task={task.mItem}/>
                  </div>
                  
              )               
          case executables[13].id:
              return (
                  <div>
                      <h3>{task.mItem}</h3>
                     
                  </div>
                  
              )               
      
          default:
              return (
                  <div>
                      <h3>Please Go Back</h3>
                      <h3>Something went wrong</h3>
                     
                  </div>
                  
              )
     
  
  }
}