import styled,{css} from 'styled-components'

const IntroCard=styled.div`
${props=>props.device.mobile&&css`
height:150px;
width:75%;
box-shadow: 2px 2px rgba(0,0,0,.2);
background-color:rgba(255,255,255, 0.7);
display:flex;
flex-direction:column;
justify-content:center;
align-items:center;
z-index:5;
.intro__1strow{
    display:flex;
    flex-direction:column;
    align-self:center;
h1{
    width:252px;    
    background:white;
    box-shadow: 2px 2px rgba(0,0,0,.3);
    font-size:16px;
    font-style: italic;
    font-weight: 500;
    text-align:center;
    margin:-16px 0 0 16px;
    z-index:10;
   }
}
p{
    font-size:12px;
    font-weight:450;
    margin:3% 5% ;
    text-align:center;  
}
`}
${props=>props.device.tablet&&css`
height:150px;
width:75%;
box-shadow: 2px 2px rgba(0,0,0,.2);
background-color:rgba(255,255,255, 0.7);
display:flex;
flex-direction:column;
justify-content:center;
align-items:center;
.intro__1strow{
    display:flex;
    flex-direction:column;
    align-self:center;
h1{
    width:320px;
    height:2em;    
    background:white;
    box-shadow: 2px 2px rgba(0,0,0,.3);
    font-size:20px;
    font-style: italic;
    font-weight: 500;
    text-align:center;
    margin:-28px 0 0 36px;
    z-index:10;
   }
}
p{
    font-size:16px;
    font-weight:450;
    margin:3% 5% ;
    text-align:center;  
}
`}
${props=>props.device.desktop&&css`
height:180px;
width:75%;
box-shadow: 2px 2px rgba(0,0,0,.2);
background-color:rgba(255,255,255, 0.7);
display:flex;
flex-direction:column;
justify-content:center;
align-items:center;
.intro__1strow{
    display:flex;
    flex-direction:column;
    align-self:center;
h1{
    width:420px;
    height:2em;    
    background:white;
    box-shadow: 2px 2px rgba(0,0,0,.3);
    font-size:26px;
    font-style: italic;
    font-weight: 500;
    text-align:center;
    margin:-28px 0 0 36px;
    z-index:10;
   }
}
p{
    font-size:12px;
    font-weight:450;
    margin:3% 5% ;
    text-align:center;  
}
`}
${props=>props.device.laptop&&css`
height:180px;
width:75%;
box-shadow: 2px 2px rgba(0,0,0,.2);
background-color:rgba(255,255,255, 0.7);
display:flex;
flex-direction:column;
justify-content:center;
align-items:center;
.intro__1strow{
    display:flex;
    flex-direction:column;
    align-self:center;
h1{
    width:420px;
    height:2em;    
    background:white;
    box-shadow: 2px 2px rgba(0,0,0,.3);
    font-size:26px;
    font-style: italic;
    font-weight: 500;
    text-align:center;
    margin:-28px 0 0 36px;
    z-index:10;
   }
}
p{
    font-size:12px;
    font-weight:450;
    margin:3% 5% ;
    text-align:center;  
}
`}
`
export default IntroCard