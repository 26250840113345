import React,{useEffect,useState} from 'react'
import { useAdminStateValue } from '../Admin'
import {db,timestamp} from '../firebase'

const SendToTrash = (props) => {
    const    [{updateCoordinate:{collection,docId}},]=useAdminStateValue()
    const collectionFrom=collection
    const [deleteMess,setDeleteMess]=useState("")
    const {product_name,code}=props.update
    useEffect(()=>{
        db.collection(collectionFrom).doc(docId).get().then(
            (doc)=>{
                const deleteRecord={...doc.data(),deleted:timestamp}
            db.collection("trash").doc(docId).set(deleteRecord).then(()=>{
                db.collection(collectionFrom).doc(docId).delete().then(()=>{
                    console.log("product name:",product_name,"code:",code, "successfully deleted")
                    setDeleteMess(`Product name:${product_name}, code:${code} was successfully deleted`)
                })
            })
            }
        )
    },[])    
    return (
        <div style={{paddingLeft:"300px"}}>
            Sending to trash, wait...
            {deleteMess&&<p>{deleteMess}</p>}

        </div>
    )
}

export default SendToTrash
