import React,{useState,useEffect} from 'react';
import {Link} from 'react-router-dom';
import {Container,Banner} from './ProductSelection.elements';
import {sorting,useBanner} from '../hooks'
import {productsDB} from "../utilities"
import {useStateValue} from '../StateProvider'
import Grid from '@material-ui/core/Grid';
import MediaCard from './MediaCard';
import { makeStyles } from '@material-ui/core/styles'
import {useHistory} from 'react-router-dom'

const useStyles = makeStyles(() => ({
    root: {
      flexGrow: 1,
      padding:"1%"
    },
    container: {
        marginRight:"2%",
        marginLeft:"2%"
    }
   
  }));



const ProductSelection = ({collection,path,url,...props}) => {

const history=useHistory()
const classes=useStyles()
const {showBanner,img}=useBanner(collection)
const [{store:{products},media:{products:{family_images}}},]=useStateValue()
const [selected,setSelected]=useState(null)
const [displayTemplate,setDisplayTemplate]=useState(null)
console.log("family images:",family_images)
function getProducts (collection) {
const productsArray=Object.entries(products)
const indexSelected=Object.keys(products).indexOf(collection)
const selected={collection:productsArray[indexSelected][0],
data:productsArray[indexSelected][1]}
return selected
}

useEffect(()=>{
setSelected(sorting(getProducts(collection).data,productsDB,collection,"code"))
console.log("getting the products to display from:",collection)
const indexTemplate=productsDB.map(n=>n.collection).indexOf(collection)
setDisplayTemplate(productsDB[indexTemplate].orderBy)
console.log("template:",productsDB[indexTemplate].orderBy)
},[collection])

useEffect(()=>{
if (selected) {console.log("products selected:",collection,selected)
console.log("got the products from the selection and ordered them")

const families=displayTemplate.length>1
!families&&(collection!=='Hardware')&&history.push(`${url}/na`)

console.log("this selection has families?:",families)
families&&console.log(`${collection} images`,family_images[collection])
families&&console.log('display template:',displayTemplate)
}    
},[selected])


    return (
        <Container>
           
<Banner banner={img} img={showBanner} device={props.device}/>
    <Grid className={classes.root} container justify='center' spacing={2}>
{selected&&displayTemplate.length>1&&displayTemplate.map((card,index)=>{
    return(
   
<Grid  item xs={12} sm={6} md={4} lg={3} key={"grid_item_products"+100+index}>
 <Link to={`${url}/${card.family}`} key={"products_link"+10+index} >
<MediaCard
key={"mediaCard_products_"+index}
title={card.name}
url={family_images[collection][card.family]}

/>
 </Link>
    </Grid>
   
    )

})}
    </Grid >
    
    
           
            
        </Container>
    )
}

export default ProductSelection
