import React,{useState} from 'react'
import Typography from '@material-ui/core/Typography'
import {MyProfileContainer,Backdrop} from './ViewPDF'
import DescriptionIcon from '@material-ui/icons/Description'


const ViewPDS_SDS = React.memo((props) => {
    console.log("ViewPDS_SDS")
const [openSDS,setOpenSDS]=useState(false)
const [openPDS,setOpenPDS]=useState(false)
 
    const {sds,pds}=props
    
    const hasContent =(verify)=>{
        return !(verify===""||verify==="na"||(Array.isArray(verify)&&verify.length===0))
            }
    let urlSDS,urlPDS=""
if (hasContent(sds)) {
    if (typeof(sds)==='string'&&sds.startsWith('https://firebasestorage')){
urlSDS=sds
    } else {urlSDS=URL.createObjectURL(sds)}
}
if (hasContent(pds)) {
    if (typeof(pds)==='string'&&pds.startsWith('https://firebasestorage')){
urlPDS=pds
    } else {urlPDS=URL.createObjectURL(pds)}
}
const handleClose=(e)=>{
    if( e.target.classList.contains('backdrop')){      
        setOpenPDS(false)
        setOpenSDS(false)}
}
const handleOpen=(e)=>{
    if( e.target.classList.contains('pds')){      
        setOpenPDS(true)
        setOpenSDS(false)} else {
            if( e.target.classList.contains('sds')){      
                setOpenSDS(true)
                setOpenPDS(false)
        }
        }
}


    return (
        <>
             {hasContent(pds)||hasContent(sds)?<Typography align="center" variant="body1" gutterBottom >
                 Download:
             </Typography>:<Typography align='center' variant="h6" gutterBottom >
                 no content
             </Typography> }
             <div >
      <div type="button" onClick={handleOpen}  className="pds" style={{color:hasContent(pds)?"green":"red"}}>
       <span><DescriptionIcon className="pds"/></span> PDS
      </div> <br/>
      {openPDS&&<Backdrop
      onClick={handleClose}
      className="backdrop">
        <MyProfileContainer >
           
            {hasContent(pds)?<iframe  src ={urlPDS} 
            style={{width:"100%",height:"100%"}}
            referrerPolicy="origin"
            title="pds document"
            />:
            <p style={{justifySelf:"center",textAlign:"center",color:"red",fontSize:"22px",fontWeight:"500"}}>No File Avalilable</p>}
             
        </MyProfileContainer>
    </Backdrop>}   
      <div type="button" onClick={handleOpen}  className="sds" style={{color:hasContent(sds)?"green":"red"}}>
      <span><DescriptionIcon className="pds"/></span>  SDS
      </div>
      {openSDS&&<Backdrop
      onClick={handleClose}
      className="backdrop">
        <MyProfileContainer >
        {hasContent(sds)?<iframe  src ={urlSDS} style={{width:"100%",height:"100%"}}referrerPolicy="origin" title="sds document"/>:
        <p style={{justifySelf:"center",textAlign:"center",color:"red",fontSize:"22px",fontWeight:"500"}}>No File Avalilable</p>}
        </MyProfileContainer>
    </Backdrop>}   
   
    </div>
                
            </>
    )
})

export default ViewPDS_SDS
