import { makeStyles } from '@material-ui/core/styles'
export const useStyles = makeStyles((theme) => ({
        
    root: {
        '& .MuiAppBar-root': {
            backgroundColor:"#fff",
            padding:"0px",
            
            },
        '& .MuiAutocomplete-root': {
          width: '350px'
            },
        '& .MuiTextField-root': {
              margin: theme.spacing(1),
              width: '25ch',
              backgroundColor:"#fff"
            },
        '& .addProduct_tag': {
          color:'var(--secondary)',
            fontWeight:"900",
            marginRight:"20px",
        },
        '& .Conade': {
          
        },
        '& .icon_user':{
          
          color:"var(--primary)",
        },      
        '& .icon_user_container':{
          marginRight:"3%",
          backgroundColor:"green",
        },      
      
      width:'100%'
    },
    
  }));