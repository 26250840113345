import { makeStyles } from '@material-ui/core/styles'
export const useStyles = makeStyles((theme) => ({
        
    root: {
        '& .MuiAppBar-root': {
            backgroundColor:"#fff",
            padding:"0px"
            },
        '& .MuiAutocomplete-root': {
          width: '350px'
            },
        '& .MuiTextField-root': {
              margin: theme.spacing(1),
              width: '25ch',
              backgroundColor:"#fff"
            },
              
      paddingLeft:'300px',
      width:'100%'
    },
  }));