import styled from 'styled-components';



export const Container=styled.button` 
background-color:transparent;

&:focus {
    border: none;
    outline:none;
  }
 
    
.logo__icon{
  &:hover {
    transition: all ease 0.3s;
    transform:scale(1.05);
  
}
   
}  
 
  
`
export const CustomsizeIcon=styled.svg`
height:100%;
width:${props => props.device.mobile?"120px":props.device.tablet?"160px":"180px"};

`
