import styled from 'styled-components';

export const MenuOpenContainer=styled.button`

&:focus {
    border: none;
    outline:none;
  }

&:hover{
  transform: scale(1.05);
}


`

export const CustomsizeIcon=styled.svg`
height:100%;
width:${props => props.device.mobile?"40px":props.device.tablet?"70px":"160px"};


`