import React,{useState,useEffect} from 'react'
import {FileContainer,FileDrop} from './ShowFiles.elements'
import FileInfo from './FileInfo'
import DropFile from './DropFile'
import ViewThumb from './ViewThumb'
const ShowFiles = (props) => {
       
    const {pds,sds,images}=props.update
    const [productFiles,setProductFiles]=useState({pds,sds,images})
    useEffect(()=>{
        setProductFiles({pds,sds,images})
      },[pds,sds,images])
    const validTypes=['pdf','PDF']
    const validImageTypes=['jpg','png','jpeg','webp','jfif','JPG','PNG','JPEG'] 
    const [gotUpdateFile,setUpdateFile]=useState({gotFile:false,pds:false,sds:false,images:false})
    
      return (
        <FileContainer>
            <FileDrop>
                <DropFile {...props} gotUpdateFile={gotUpdateFile} setUpdateFile={setUpdateFile} tag="Get PDS" small validTypes={validTypes}/>
                <FileInfo {...props} gotUpdateFile={gotUpdateFile}  source="PDS" />
                {gotUpdateFile.pds&&<div className="update_preview_images"><ViewThumb source="pds"  gotUpdateFile={gotUpdateFile} setUpdateFile={setUpdateFile} {...props}/></div>}
            </FileDrop>
            <FileDrop>
                <DropFile {...props} gotUpdateFile={gotUpdateFile} setUpdateFile={setUpdateFile} tag="Get SDS" small validTypes={validTypes}/>
                <FileInfo {...props} gotUpdateFile={gotUpdateFile}  source="SDS" />
                {gotUpdateFile.sds&&<div className="update_preview_images"><ViewThumb source="sds"  gotUpdateFile={gotUpdateFile} setUpdateFile={setUpdateFile} {...props}/></div>}
            </FileDrop>
            <FileDrop>
                <DropFile {...props} gotUpdateFile={gotUpdateFile} setUpdateFile={setUpdateFile} tag="Get Image(s)" small validTypes={validImageTypes}/>
                <FileInfo {...props} gotUpdateFile={gotUpdateFile}  source="Images" />
                {gotUpdateFile.images&&<div className="update_preview_images"><ViewThumb source="images"  gotUpdateFile={gotUpdateFile} setUpdateFile={setUpdateFile} {...props} clickable={false}/></div>}
            </FileDrop>
        </FileContainer>
    )
}

export default ShowFiles
