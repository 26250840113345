import React,{useEffect,useState} from 'react'
import { useAdminStateValue } from '../Admin'
import TextField from '@material-ui/core/TextField'
import {useStyles} from './UpdateStyles'
import Autocomplete from '@material-ui/lab/Autocomplete';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import {sorting} from '../hooks'
import {productsDB} from "../utilities"
import SearchIcon from '@material-ui/icons/Search';




const GetRecord =(props) => {
    const    [,dispatch]=useAdminStateValue() 
    const classes=useStyles()  
    let [collection,dataArray]=props.collection
    const [value, setValue] = useState(null)
    
    
const handleChange=(event, newValue) => {
    
    setValue(newValue);
        
      }        
    
    
const packStatus=(status)=>{
       
      if (status==="yes") {return true} 
      else {return false}
  }

useEffect(()=>{
const  packingInit={flexi:value?packStatus(value.flexi):false,
      tote:value?packStatus(value.tote):false,
      drum:value?packStatus(value.drum):false,
      keg:value?packStatus(value.keg):false,
      pail:value?packStatus(value.pail):false,
      jug5qt:value?packStatus(value.jug5qt):false,
      jug1gal:value?packStatus(value.jug1gal):false,
      qtbottle:value?packStatus(value.qtbottle):false,
      cartridge:value?packStatus(value.cartridge):false}
      
  props.setUpdate(
    {code:value?value.code:"",
    product_name:value?value.product_name:"",
    description:value?value.description:"",
    pds:value?value.pds:"",
    sds:value?value.sds:"",
    images:value?value.images:[],
    packing:packingInit       
    })
    if (value) {
      
      dispatch({
      type:'UPDATE_CODE_NAME_DES',
      code:value.code,
      name:value.product_name,
      description:value.description
    })
      dispatch({
      type:'UPDATE_PDS_FILE',
      updatePDSfile:value.pds
    })
      dispatch({
      type:'UPDATE_SDS_FILE',
      updateSDSfile:value.sds
    })
      dispatch({
      type:'UPDATE_IMAGES_FILES',
      updateImagesfiles:value.images
    })
      dispatch({
      type:'UPDATE_PACKING',
      packing:packingInit
    })
      dispatch({
      type:'UPDATE_COORDINATES',
      coordinate:{collection:value.collection,docId:value.id}
    })
   
    if (value.pds.startsWith('https://firebasestorage')){
      dispatch({type:'UPDATE_OLD_PDS',
    oldPDS:value.pds})
    }
    if (value.sds.startsWith('https://firebasestorage')){
      dispatch({type:'UPDATE_OLD_SDS',
    oldSDS:value.sds})
    }
  props.setShowRecord(true)
  } else {
    dispatch({
      type:'INITIAL_UPDATE',
    })
    dispatch({
      type:'UPDATE_COORDINATES',
      coordinate:{collection:"",docId:""}
    })
    dispatch({
      type:'UPDATE_COLLECT_DELETE_INDB_INIT',
     
    })
    dispatch({type:'UPDATE_OLD_PDS',
    oldPDS:"na"})
    dispatch({type:'UPDATE_OLD_SDS',
    oldSDS:"na"})
    props.setShowRecord(false)
  }
    
      
    },[value])     
      
    
    
    

    dataArray=sorting(dataArray,productsDB,collection,"code")
    

    
    const defaultProps = {
        options: dataArray,
        getOptionLabel: (option) => option.code,
        
      }
    
    
    
return (
<div className={classes.root} >
    <AppBar position="static" >
    <Toolbar>
    <SearchIcon color="primary"/>                
    <Autocomplete
        {...defaultProps}  
        value={value}
        onChange={handleChange}
        clearOnEscape
        autoHighlight
        autoSelect
        id="code_field"
        
        renderInput={(params) => <TextField {...params} label="code" color="primary" variant="outlined" />}       
        
    />
</Toolbar>
</AppBar>
          
</div>
  )  
        
   
}

export default GetRecord