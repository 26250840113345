import React from 'react'
import DotMatrix from './DotMatrix'
import IntroCard from './IntroCard.elements'
import {INTRO_HEADER,INTRO_P} from './useVariables'




function IntroCardHome(props) {
    return(
    <IntroCard {...props}>
          <div className="intro__1strow">
               
            
            <DotMatrix {...props} className="intro__matrix"/>
           
            <h1>{INTRO_HEADER}</h1>   
                  
                
          </div>
         
          <p>{INTRO_P}</p>
    </IntroCard>    
    )
}
export default IntroCardHome