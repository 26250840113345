import React from 'react'
import { Redirect } from 'react-router-dom'
import {useStateValue} from '../StateProvider'



const VerifyManage = ({children}) => {
    const [{role},]=useStateValue()
    
    const isOwner=role==="Owner"

    return  isOwner?children:<Redirect to="/"/>
    
} 
export default VerifyManage
