import styled from 'styled-components'

export const FileContainer=styled.div`
display:flex;
flex-direction:column;
align-items:center;
justify-content:space-evenly;
color:#fff;
text-align:center;
background-color:${p=>p.highlighted?"rgba(0,255,105,0.8)":"rgba(200,180,45,.8)"};
border:${p=>p.highlighted?"2px solid green":"none"};
height:100%;

`