import { Button } from '@material-ui/core'
import React,{useState} from 'react'
import GetRecord from './GetRecord'
import Product_Card from './Product_Card'
import CloudUploadIcon from '@material-ui/icons/CloudUpload'
import SendToTrash from './SendToTrash'
import {useAdminStateValue} from '../Admin'
const DeleteRecord = (props) => {
    const [,dispatch]=useAdminStateValue()
    const [update,setUpdate]=useState(null)
    const [showRecord,setShowRecord]=useState(false)
    const [deleting,setDeleting]=useState(false)
    
    const handleSubmit=(event)=>{
        
        setDeleting(true)
        dispatch({type:'UPDATE_SHOW_DASH',
    switchOff:false})
        
        }

    return (
        <>
            {!deleting&&<GetRecord {...props} setUpdate={setUpdate} setShowRecord={setShowRecord}/>} 
            <div style={{paddingLeft:"300px"}}>
                {!deleting&&showRecord&&<Product_Card 
            uploading={true}
            
            images={update.images}
            product_name={update.product_name}
            description={update.description}
            packing={update.packing}
            code={update.code}
            pds={update.pds}
            sds={update.sds}/>}   
            </div>
            {showRecord&&!deleting&&<Button
          style={{position:'absolute',top:'156px',left:'60%',fontSize:'22px',height:'60px'}}
          color="default"
            type="submit"
            variant="contained"
            size="small"
            onClick={handleSubmit}
            startIcon={<CloudUploadIcon />}
          >
            Delete record
          </Button>}  
          {deleting&&<div>
        <SendToTrash deleting={deleting} setDeleting={setDeleting} update={update}/>
        </div>}   
        </>
    )
}

export default DeleteRecord
