import React,{useState,useMemo} from 'react'
import {CardContainer,MagnifyLens} from './ProductCard.elements'
import DefaultMainPic from '../images/sidemenu.jpg'
import ViewThumbFilesProducts from './ViewThumbFilesProducts'
import PackingInfo from './PackingInfo'
import TextInfo from './TextInfo'
import ViewPDS_SDS from './ViewPDS_SDS'
import {useMouseMove} from '../hooks'

const ViewProductCard = (props) => {
    
    const {id,productFiles,code,product_name,description,packing}=props
    const {pds,sds,images}=productFiles
    console.log(productFiles)
    let imgSrcRef=React.useRef()
    let pdsF=""
    let sdsF=""
    let imagesF=[]
    if (pds!=="na") {pdsF=pds}
    if (sds!=="na") {sdsF=sds}
    if (images!=="na") {imagesF=images}
    const [selected,setSelected]=useState(0)
    
    const {x=0,y=0,handleMouseMove}=useMouseMove()
    const lensPosition=useMemo(()=>{return{x,y}},[x,y])
    console.log("lensPosition",lensPosition)
    const[imgSource,setImgSource]=useState({top:0,left:0,height:0,width:0})
    const [magnify,setMagnify]=useState(false)
    const imagesbool=imagesF.length>0
    
    const handleMagnifyOn=(e)=>{
        e.preventDefault()
        if (!imagesbool) return
        const {bottom,right,top,left,width,height}=imgSrcRef.current.getBoundingClientRect()    
        setImgSource({top,left,bottom,right,width,height})
       setMagnify(true)
        }
    
    
    const handleMagnifyOff=(e)=>{
    e.preventDefault()
    if (!imagesbool) return
        setMagnify(false)
    
}
    
    const handleClick=(e)=>{
        
    }
    return (
        <CardContainer >
          
           <div className="ThumbContainer">
                    {imagesbool?<ViewThumbFilesProducts   id={id} images={imagesF} selected={selected} setSelected={setSelected} clickable={true}/>:
                    <p>No images</p>}
                </div>
                <div className="MainPicture">
<div className="imgWrap"  ref={imgSrcRef} onMouseLeave={handleMagnifyOff} onMouseEnter={handleMagnifyOn} onMouseMove={imagesbool?handleMouseMove:()=>{return}} onClick={handleClick}>
    
        <img  src={imagesbool?imagesF[selected]:DefaultMainPic} alt="main" />
        
</div>
    <MagnifyLens  url={imagesF[selected]} magnify={magnify} imgSource={imgSource} lensPosition={lensPosition}></MagnifyLens>
                </div>
            <div className="CodeInfo">
                <span >code:</span><br/><span >{code}</span>
                </div>
                <div className="PDFInfo">
                    <ViewPDS_SDS sds={sdsF} pds={pdsF} />
                </div>
            
            <div className="TextInfo">
                <TextInfo 
                product_name={product_name} 
                description={description}/>
                </div>
            <div className="PackInfo" style={{alignSelf:"center"}}>
            <PackingInfo packing={packing}/>
            </div>
            
            
        </CardContainer>
    )
}

export default ViewProductCard
