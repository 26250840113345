import styled,{css} from 'styled-components'

 const MenuContainer=styled.div`
 display:flex;
 flex-direction:column;
 justify-content:space-evenly;
 padding: 2%;
:hover div>h3{
    background-color:transparent;
    transition:0.3s ease-in all;
}
:hover div>span{
    background-color:transparent;
    transition:0.3s ease-in all;
}
div>h3:hover{
    background-color:var(--primary);
    transition:0.3s ease-in all;
}
div>span:hover{
    background-color:var(--primary);
    transition:0.3s ease-in all;
}
${props => props.device.mobile&&css`
    background-color:white;
    background-image:-webkit-linear-gradient(135deg,var(--primary)0,white 74%);
    font-size:10px;
    border: 1px solid #d3d4d5;
    position:fixed;
    top:${p=>p.top+"px"};
    left:${p=>p.left+"px"};
    line-height:12px;
    min-width:110px;
    max-width:350px;
    max-height:450px;
    z-index:12;
    border-radius:10px;
    
    transition:all 0.3s ease-out;
`}
${props=>props.device.tablet&&css`
    background-color:white;
    background-image:-webkit-linear-gradient(135deg,var(--primary)0,white 74%);
    border: 1px solid #d3d4d5;
    font-size:12px;
    width:200px;
    position:fixed;
    top:${p=>p.top+"px"};
    left:${p=>p.left+"px"};
    line-height:14px;
    min-width:180px;
    max-width:550px;
    max-height:450px;
    z-index:12;
    border-radius:10px;
    
    transition:all 0.3s ease-out;
`}
${props=>props.device.desktop&&css`
    background-color:white;
    background-image:-webkit-linear-gradient(135deg,var(--primary)0,white 64%);
    border: 1px solid #d3d4d5;
    font-size:14px;
    position:fixed;
    top:${p=>p.top+"px"};
    left:${p=>p.left+"px"};
    line-height:16px;
    min-width:230px;
    max-width:550px;
    max-height:450px;
    z-index:12;
    border-radius:10px;
   
    transition:all 0.3s ease-in-out;
`}
${props=>props.device.laptop&&css`
    background-color:white;
    background-image:-webkit-linear-gradient(135deg,var(--primary)0,white 74%);
    border: 1px solid #d3d4d5;
    font-size:16px;
    line-height:18px;
    min-width:230px;
    max-width:25vw;
    max-height:65vh;
    position:fixed;
    top:${p=>p.top+"px"};
    left:${p=>p.left+"px"};
    z-index:12;
    border-radius:10px;
    

    transition:all 0.3s ease-in-out;
`}

 `
 export const  MenuLinkP=styled.h3`

 background-color:${props=>props.selectedS?'var(--primary)':'transparent'};
 margin-bottom:5%;
 margin-left:3%;
 font-weight:900;
 ::after{
     content:'';
     border: 1px solid ${props=>props.selectedS?'white':'var(--secondary)'};
     display:block;
     margin:8px 0px 8px;
     }
 
 :hover {
     
     transition:all 0.2s ease-in;
      ::after{
     content:'';
     border: 1px solid rgba(255,255,255,0.5);
     display:block;
     
     }
  
     
    }

 `
 export const ItemContainer=styled.div`
 
 display:flex;
 
 align-items:flex-start;
 white-space:nowrap;
 justify-content:space-between;
 
 h3{
    flex:1;
    max-width:90%;
    :hover{transition:all 0.2s ease-in;
      ::after{
     content:'';
     border: 1px solid rgba(255,255,255,0.5);
     display:block;}
     +span{color:white;
    transform:scale(1.15);}
    }
 }
 span{
     margin-left:12px;
     margin-right:6px;
 }
 button{
     align-self:flex-start;
     margin-bottom:15%;
     background-color:lightaquamarine;
 }
 `
 export const MitemContainer=styled.div`
 display:flex;
 flex-direction:column;
 justify-content:space-evenly;

 `
 export const SmenuContainer=styled.div`
 .sLink{
    font-weight:600;
     ::before{
         content:".-";
     }
 }
 `
 export default MenuContainer