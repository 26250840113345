import React from 'react'
import {useDevice} from "../hooks"
import Header from '../components/Header'
import Navbar from '../components/Navbar'


function Selection(props) {
    const device=useDevice()
    return (
        <div>
            <Header device={device} />
            <Navbar  device={device}/>
            
        </div>
    )
}

export default Selection
