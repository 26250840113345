import React from 'react'

import {ThumbContainer} from './ViewThumb.elements'

const ViewThumbFilesProducts = (props) => {
const {id,images,clickable,selected,setSelected}=props





const handleClick=(e,imageIndex)=>{
    if (!clickable) return
    setSelected(imageIndex)
}



const filesJSX=images.map((file,index)=>{
const isSelected=clickable?(selected===index):false

return (
    <li key={"thumb"+id+index} onMouseEnter={(e)=>handleClick(e,index)} onClick={(e)=>handleClick(e,index)}>
<ThumbContainer clickable={clickable} isSelected={isSelected}>
{images&&<img key={id} src={file} alt={"thumb image"}/>}

    
       
</ThumbContainer>
    </li>
)
})
    


    return (
        <ul>
            {filesJSX}
        </ul>
    )
}

export default ViewThumbFilesProducts