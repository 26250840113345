import React from 'react'
import Typography from '@material-ui/core/Typography'

const TextInfo = React.memo((props) => {
    console.log("TextInfo")
    return (
        <>
        <Typography style={{position:"sticky",top:"-1px"}} align="left" variant="h5"> {props.product_name}</Typography>
        <Typography align="justify" variant="body2"> {props.description}</Typography> 
        </>
    )
})

export default TextInfo
