const pngPacking = {
  cartridge: require('./packing/cartridge.png'),
  keg: require('./packing/keg.png'),
  qtbottle: require('./packing/bottleqt.png'),
  jug1gal: require('./packing/jug1gal.png'),
  jug5qt: require('./packing/jug5qt.png'),
  pail: require('./packing/pail.png'),
  drum: require('./packing/drum.png'),
  tote: require('./packing/tote.png'),
  flexi: require('./packing/flexitank.png'),
}
export default pngPacking
