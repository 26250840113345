import styled,{css} from 'styled-components'



 const NavContainer=styled.nav`
${props => props.device.mobile&&css`
background-color:var(--primary);
    height:40px;
    width:100%;
    display:flex;
    align-items:center;
    justify-content:space-between;
    
    .header__socials{
        display:none;
    }
    .header__searchbar{
        margin:0 10px 0;
        flex-grow:1;
        max-width:700px;
    }
    
`}
${props=>props.device.tablet&&css`
background-color:var(--primary);
    height:70px;
    width:100%;
    display:flex;
    align-items:center;
    justify-content:space-between;
    
    .header__socials{
        margin-left:15px;
        color:white;
        
    }
    .header__searchbar{
        margin:0 35px 0;
        flex-grow:1;
        
    }
    .header__leftside   {
            display:flex;
    align-items:center;
    justify-content:space-around;
    .header__user{
        cursor:pointer;
        margin-right:10px;
        font-size:16px;
        font-weight:500;
        .header_accounticon{
            color:white;
            position:relative;
        }
    }
    .header__selection{
        display:flex;
    align-items:center;
    justify-content:space-around;
        margin-right:15px;
        font-size:16px;
        font-weight:500;
        .header__basketicon{
            color:white;
            margin-right:10px;
        }
        .header__selectiontext{
            margin-right:15px;
        }
    }}


`}
${props=>props.device.desktop&&css`
background-color:var(--primary);
    height:50px;
    width:100%;
    display:flex;
    align-items:center;
    justify-content:space-between;

    .header__socials{
        margin-left:40px;
        color:white;
        
    }
    .header__searchbar{   
        margin:0 25px 0;          
        flex-grow:1;
         }
         .header__leftside   {
            display:flex;
    align-items:center;
    justify-content:space-around;
    .header__user{
        cursor:pointer;
        margin-right:20px;
        font-size:18px;
        font-weight:500;
        .header_accounticon{
            color:white;
            position:relative
        }
    }
    .header__selection{
        display:flex;
    align-items:center;
    justify-content:space-between;
        margin-right:25px;
        font-size:16px;
        font-weight:500;
        .header__basketicon{
            color:white;
            margin-right:10px;
        }
        .header__selectiontext{
            margin-right:15px;
        }
    }}
`}
${props=>props.device.laptop&&css`
background-color:var(--primary);
    height:50px;
    width:100%;
    display:flex;
    align-items:center;
    justify-content:space-between;

    .header__socials{
        margin-left:40px;
        color:white;
        
    }
    .header__searchbar{   
        margin:0 25px 0;          
        flex-grow:1;
         }
    .header__leftside{
        display:flex;
    align-items:center;
    justify-content:space-around;
         .header__user{
        cursor:pointer;  
        margin-right:25px;
        font-size:18px;
        font-weight:bold;
        .header_accounticon{
            color:white;
            position:relative;
        }
    }
    .header__selection{
        display:flex;
    align-items:center;
    justify-content:space-between;
        margin-right:30px;
        font-size:18px;
        font-weight:500;
        .header__basketicon{
            color:white;
            margin-right:10px;
        }
        .header__selectiontext{
            margin-right:25px;
        }
    }
    }
   
`}

 `
 export default NavContainer