import styled from 'styled-components';



export const Container=styled.button` 
&:focus {
    border: none;
    outline:none;
  }
.langSwitch__icon{
  &:hover{
      transform:scale(1.02);
    }
  
  
}
`
export const CustomsizeIcon=styled.svg`

height:100%;
width:${props => props.device.mobile?"42px":props.device.tablet?"54px":"60px"};
`