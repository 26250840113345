import styled,{css} from 'styled-components'

export const MyProfileContainer=styled.div`

${props=>props.device.mobile&&css`
position:block;
scroll-behavior: smooth;
max-width:80%;
max-height:50%;
margin: 110px auto;
font-size:12px;
`}
${props=>props.device.tablet&&css`
max-width:80%;
max-height:50%;
margin: 180px auto;
font-size:18px;
`}
${props=>props.device.desktop&&css`

max-width:40%;
max-height:80%;
margin: 18% auto;
`}
${props=>props.device.laptop&&css`
widht:250px;
height:300px;
max-width:40%;
max-height:80%;
margin: 18% auto;
`}
display:flex;
flex-direction:column;
justify-content:space-evenly;
background:white;
background:linear-gradient(to right bottom,rgba(255,255,255,.7),rgba(255,255,255,.3));
backdrop-filter:blur(.8rem);
box-shadow:3px 5px 7px rgba(0,0,0,0.1);
border:solid 3px white;
border-radius:15px;
z-index:25;
position:relative;
.myprofile__button{
    background-color:var(--secondary);
    margin-left:10%;
    border-radius:50%;
    height:20px;
    width:20px;
    position:absolute;
    left:23%;
    bottom:37%;
}
`
export const Backdrop=styled.div`
position:fixed;
top:0;
left:0;
height:100vh;
width:100vw;
z-index:22;
background-color:rgba(0,0,0,0.1);
`